import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import {
  DeleteItemAttentionIcon,
  DeleteItemIcon,
  EditItemIcon,
} from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseIcons/CurrentKnowledgeBaseIcons';
import { Button } from 'src/components/ui/button';

export const DeleteKnowledgeBaseItemModal = ({
  disabled = false,
  handleSingleItemDelete,
  item,
}) => {
  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setOpen(false);
  };

  const onClick = () => {
    handleSingleItemDelete(item);
    closePopup();
  };

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        <button
          className={clsx(
            disabled && 'pointer-events-none opacity-50',
            'flex gap-2 opacity-30 hover:opacity-100 transition [&_svg>path]:hover:fill-delete',
          )}
        >
          <DeleteItemIcon />
        </button>
      </DialogTrigger>
      <DialogContent closeButtonHidden>
        <DialogHeader>
          <DialogTitle>Delete content</DialogTitle>
        </DialogHeader>
        <div className="flex flex-row gap-4 pt-4">
          <div className={'flex justify-center items-center'}>
            <DeleteItemAttentionIcon />
          </div>
          <div>
            <p className={'text-delete font-medium text-base'}>
              Are you sure you want to delete this source irretrievably?
            </p>
          </div>
        </div>
        <div className="flex justify-center mt-5">
          <div className="flex gap-2.5">
            <Button
              variant="outline"
              type="button"
              className="min-w-24 shadow-md"
              onClick={closePopup}
            >
              Cancel
            </Button>
            <Button className="min-w-24 shadow-md" onClick={onClick}>
              Delete
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
