import React, { useEffect } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { getErrorMessage } from 'src/utils/get-error-message';
import { Button } from 'src/components/ui/button';
import { useDeleteKnowledgeBaseMutation } from 'src/services/knowledgeBaseService';
import { ROUTE_PATHS } from 'src/utils/route-paths';
import { useGetAllProjectsQuery } from 'src/services';
import { clsx } from 'clsx';
import { useNavigate } from 'react-router-dom';

export const DeleteAiKnowledgeBaseForm = ({ close = () => {}, knowledgeBase }) => {
  const notification = useNotification();
  const navigate = useNavigate();

  const [
    deleteKnowledgeBase,
    { isLoading, isError: deleteKnowledgeBaseIsError, error: deleteKnowledgeBaseError, isSuccess },
  ] = useDeleteKnowledgeBaseMutation();

  const { data: projects } = useGetAllProjectsQuery();

  useEffect(() => {
    if (deleteKnowledgeBaseIsError) {
      notification.error(getErrorMessage(deleteKnowledgeBaseError));
    }
  }, [deleteKnowledgeBaseIsError]);

  const deleteHandler = () => {
    deleteKnowledgeBase({ knowledge_base_id: knowledgeBase.id }).then((res) => {
      if (res?.data?.status === 'ok') {
        close();
        notification.success('Knowledge Base was successfully deleted!');
      }
    });
  };

  const goToProjects = () => {
    const id = knowledgeBase.projects[0].id;
    const project = projects.find((project) => project.id === id);

    if (project) {
      navigate(
        `${ROUTE_PATHS.DASHBOARD_PROJECT.replace(':id', project.id)}?type=${project.project_type}`,
      );
    } else {
      navigate(`${ROUTE_PATHS.DASHBOARD_PROJECT.replace(':id', id)}`);
    }
  };

  return (
    <>
      <div>
        <div className="flex items-center gap-3.5">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
              stroke="#FF5151"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 14H10.01V14.01H10V14Z"
              stroke="#FF5151"
              strokeWidth="2.25"
              strokeLinejoin="round"
            />
            <path
              d="M10 10V6"
              stroke="#FF5151"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {Boolean(knowledgeBase.projects.length) ? (
            <div className={'flex flex-col'}>
              <p className="text-delete text-base font-medium">
                This knowledge base cannot be deleted because it is used by active projects.
              </p>
            </div>
          ) : (
            <p className="text-delete text-base font-medium">
              Are you sure you want to delete the knowledge base? Deletion is irreversible.
            </p>
          )}
        </div>
        {Boolean(knowledgeBase.projects.length) && (
          <div className={'flex items-center gap-3.5 mt-2.5'}>
            <span className={'w-5'}></span>
            <div>
              <p className={'text-base font-medium text-ebony_opacity_50'}>
                Remove this knowledge base from the following projects:
              </p>
              <ul className={'list-disc pl-4 mt-2.5'}>
                {knowledgeBase.projects.map((project) => {
                  const projectData = projects.find((item) => item.id === project.id);

                  return (
                    <li
                      className={clsx(
                        'text-base/[170%] font-medium text-ebony_opacity_50 ',
                        projectData && 'hover:text-ebony',
                      )}
                      key={project.id}
                    >
                      {projectData ? (
                        <a
                          className={' underline '}
                          href={`${ROUTE_PATHS.DASHBOARD_PROJECT.replace(':id', projectData.id)}?type=${projectData.project_type}`}
                        >
                          {project.name || 'no name'} →
                        </a>
                      ) : (
                        <p>{project.name || 'no name'}</p>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center mt-5">
        <div className="flex gap-2.5">
          <Button variant="outline" type="button" className="min-w-24 shadow-md" onClick={close}>
            Cancel
          </Button>
          {Boolean(knowledgeBase.projects.length) ? (
            <Button autoFocus onClick={goToProjects} className="min-w-24 shadow-md">
              {isLoading ? 'Loading...' : 'Go to projects'}
            </Button>
          ) : (
            <Button autoFocus onClick={deleteHandler} className="min-w-24 shadow-md">
              {isLoading ? 'Loading...' : 'Delete'}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
