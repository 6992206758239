import { AccountIcon } from 'src/assets/icons/accountIcon';
import { PATH_DASHBOARD, ROOTS_AI_KNOWLEDGE_BASE } from 'src/constants/spa-routes';
import { PaymentsIcon } from 'src/assets/icons/paymentIcon';
import React from 'react';
import { AgentCoSupportIcon } from 'src/assets/icons/agentCoSupportIcon';
import { CustomerCoSupportIcon } from 'src/assets/icons/customerCoSupportIcon';
import { BiCoSupportIcon } from 'src/assets/icons/biCoSupportIcon';
import { ProductsType } from 'src/constants/products';
import { SUBSCRIPTION_ACCESS_ROLES } from 'src/constants/roles';
import { HomeIcon } from 'src/assets/icons/homeIcon';
import { HelpIcon } from 'src/assets/icons/helpIcon';
import { KnowledgeBaseIcon } from 'src/assets/icons/knowladgeBaseIcon';

export const firstMenuItemsGeneral = [
  {
    id: 0,
    title: 'Home',
    icon: HomeIcon,
    navigateTo: '/#',
    disabled: true,
  },
];
export const menuItemsGeneral = [
  {
    id: 0,
    title: 'AI Knowledge Bases',
    icon: KnowledgeBaseIcon,
    navigateTo: PATH_DASHBOARD.AI_KNOWLEDGE_BASE,
  },
  {
    id: 1,
    title: 'Account & Workspace',
    icon: AccountIcon,
    navigateTo: PATH_DASHBOARD.ACCOUNT,
  },
  {
    id: 2,
    title: 'Subscription',
    icon: PaymentsIcon,
    navigateTo: PATH_DASHBOARD.SUBSCRIPTION,
    access: SUBSCRIPTION_ACCESS_ROLES,
  },
  {
    id: 3,
    title: 'Help',
    icon: HelpIcon,
    navigateTo: '/#',
    access: SUBSCRIPTION_ACCESS_ROLES,
    disabled: true,
  },
];
export const menuItemsProducts = [
  {
    id: 0,
    title: 'CoSupport Agent',
    type: ProductsType.agent,
    color: 'text-malibu',
    icon: <AgentCoSupportIcon width="30" height="30" />,
  },
  {
    id: 1,
    title: 'CoSupport Customer',
    type: ProductsType.customer,
    color: 'text-purple',
    icon: <CustomerCoSupportIcon width="30" height="30" />,
  },
  {
    id: 2,
    title: 'CoSupport BI',
    type: ProductsType.bi,
    color: 'text-chartreuse_yellow',
    icon: <BiCoSupportIcon width="30" height="30" />,
  },
];
