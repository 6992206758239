import './TeamList.css';
import React from 'react';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { EditTeamMemberPopup } from '../EditTeamMemberPopup/EditTeamMemberPopup';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { useParams } from 'react-router-dom';
import { useGetProjectByIdQuery } from 'src/services';
import { clsx } from 'clsx';
import { ROLES_PRETTY } from 'src/constants/roles';

export const TeamList = ({ team = [] }) => {
  const isMdDown = useMediaQuery('(max-width: 767px)');

  const { id } = useParams();

  const { data: currentProject } = useGetProjectByIdQuery({ id: id });

  const isEmpty = !Boolean(team && team.length);

  return isEmpty ? (
    <div className="empty-message">
      You are now the only user. Start with adding your support team.
    </div>
  ) : (
    <section className="team-list">
      {isMdDown ? (
        <div className="gap-[6px] flex flex-col mt-[10px]">
          {team &&
            team.map((team) => {
              const usedRequests =
                Number(currentProject?.requests_left) -
                Number(team.requests_left) +
                Number(team.credit_count);

              return (
                <div
                  key={team.id}
                  className="flex flex-col border-[rgba(184,193,198,0.4)] rounded-[5px] border overflow-hidden"
                >
                  <div className="text-sm border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px]">
                    <div className="col name md:text-base text-sm flex justify-between">
                      <div className="flex items-center gap-[8px]">
                        <UserAvatar user={team.user} />
                        <span
                          className="truncate"
                          title={`${team?.user?.first_name} ${team?.user?.last_name}`}
                        >{`${team?.user?.first_name} ${team?.user?.last_name}`}</span>
                      </div>
                      <EditTeamMemberPopup teamMember={team} />
                    </div>
                  </div>
                  <div className="flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px] gap-[10px]">
                    <span className="text-[rgba(184,193,198,1)] font-medium	">Email:</span>
                    <p title={team?.user?.email} className="truncate">
                      {team?.user?.email}
                    </p>
                  </div>
                  <div
                    className={clsx(
                      'flex items-center text-xs p-[15px] gap-[10px]',
                      'border-[rgba(184,193,198,0.4)] border-b-[1px]',
                    )}
                  >
                    <span className="text-[rgba(184,193,198,1)] font-medium	">Role:</span>
                    <p>{ROLES_PRETTY[team?.user?.role]}</p>
                  </div>
                  <div
                    className={clsx(
                      'flex items-start flex-col text-xs p-[15px] gap-[10px] bg-[#F0FDFD]',
                    )}
                  >
                    <span className="text-ebony font-semibold">AI Suggestion</span>
                    <div className="flex items-center gap-[10px]">
                      <span className="text-[rgba(184,193,198,1)] font-medium	">Used:</span>
                      <p>0</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <>
          <Table wrapperClassName="pt-14 -mt-14">
            <TableHeader>
              <TableRow>
                <TableHead></TableHead>
                <TableHead>Role:</TableHead>
                <TableHead>Email address:</TableHead>
                <TableHead className="text-right"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {team &&
                team.map((team) => {
                  const usedRequests =
                    Number(currentProject?.requests_left) -
                    Number(team.requests_left) +
                    Number(team.credit_count);
                  return (
                    <TableRow key={team.id}>
                      <TableCell className="font-medium table-fixed max-w-80">
                        <div className="col name md:text-base text-base">
                          <UserAvatar user={team.user} />
                          <span
                            title={`${team?.user?.first_name} ${team?.user?.last_name}`}
                          >{`${team?.user?.first_name} ${team?.user?.last_name}`}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="col role flex-5 text-base">
                          {ROLES_PRETTY[team?.user?.role]}
                        </div>
                      </TableCell>
                      <TableCell title={team?.user?.email} className="max-w-80">
                        <div className="col col-email text-base" title={team?.user?.email}>
                          {team?.user?.email}
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="col edit flex-5">
                          <EditTeamMemberPopup teamMember={team} />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </>
      )}
    </section>
  );
};
