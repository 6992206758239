import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { EditKnowledgeBaseItemForm } from './EditKnowledgeBaseItemForm';
import { EditItemIcon } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseIcons/CurrentKnowledgeBaseIcons';

export const EditKnowledgeBaseItemModal = ({ disabled = false, handleSingleItemEdit, item }) => {
  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        <button
          className={clsx(
            disabled && 'pointer-events-none opacity-50',
            'flex gap-2 opacity-30 hover:opacity-100 transition',
          )}
        >
          <EditItemIcon />
        </button>
      </DialogTrigger>
      <DialogContent closeButtonHidden>
        <DialogHeader>
          <DialogTitle>{'Edit'}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <EditKnowledgeBaseItemForm
            handleSingleItemEdit={handleSingleItemEdit}
            close={closePopup}
            item={item}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
