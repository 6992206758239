import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { useParams } from 'react-router-dom';
import { useNotification } from 'src/hooks/useNotification';
import {
  BackArrowIcon,
  getKnowledgeBaseIcon,
} from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseIcons/CurrentKnowledgeBaseIcons';
import {
  editEmptyFolder,
  getChildrenFiles,
  getRootFolder,
  removeEmptyFolders,
  setCheckedChildren,
  updateBranchInTree,
} from 'src/utils/fileUtils';
import { useLoader } from 'src/provider/LoaderProvider';
import { useUpdateKnowledgeBaseFilesMutation } from 'src/services/knowledgeBaseService';
import { getErrorMessage } from 'src/utils/get-error-message';
import { CurrentKnowledgeBaseTableRow } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseTableList/CurrentKnowledgeBaseTableRow';

export const CurrentKnowledgeBaseTableList = ({
  activeBranch,
  setSelectedItem,
  updateActiveBranch,
  onCheckedChange,
  isLoadingFiles,
  filteredValue,
  deleteContentMode,
  moveContentMode,
  handleItemsDelete,
  handleMoveItems,
}) => {
  const { id } = useParams();
  const notification = useNotification();
  const loader = useLoader();

  const [
    updateKnowledgeBaseFiles,
    {
      error: errorUpdateKnowledgeBaseFiles,
      isError: isErrorUpdateKnowledgeBaseFiles,
      isLoading: isLoadingUpdateKnowledgeBaseFiles,
      isSuccess: isSuccessUpdateKnowledgeBaseFiles,
    },
  ] = useUpdateKnowledgeBaseFilesMutation();

  useEffect(() => {
    if (isSuccessUpdateKnowledgeBaseFiles) {
      notification.success('Updated Successfully.');
    }
  }, [isSuccessUpdateKnowledgeBaseFiles]);

  useEffect(() => {
    if (isErrorUpdateKnowledgeBaseFiles) {
      notification.error(getErrorMessage(errorUpdateKnowledgeBaseFiles));
    }
  }, [isErrorUpdateKnowledgeBaseFiles]);

  useEffect(() => {
    if (isLoadingUpdateKnowledgeBaseFiles) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingUpdateKnowledgeBaseFiles]);

  const handleRowClick = (item) => {
    if (item.type === 'file') {
      setSelectedItem(item);
    }
  };

  const handleDoubleRowClick = (item) => {
    if (item.type === 'folder') {
      updateActiveBranch(item);
    }
  };

  const handleSingleItemEdit = async (name, item) => {
    let files = [item];

    if (item.type === 'folder') {
      files = getChildrenFiles(item);

      if (!files.length) {
        const newActiveBranch = {
          ...activeBranch,
          children: activeBranch.children.filter((folder) => folder.path !== item.path),
        };

        editEmptyFolder(name, item);
        updateActiveBranch(newActiveBranch);
        notification.success('Updated Successfully.');
        return;
      }
    }

    let request = files.map((file) => {
      if (item.type === 'file') {
        return {
          id: file.id,
          name: name,
          path: file.parent.path,
        };
      }
      if (item.type === 'folder') {
        const newPath = item.path.replace(`/${item.name}`, `/${name}`);

        return {
          id: file.id,
          name: file.name,
          path: file.path.replace(`${item.path}`, newPath).replace(`/${file.name}`, ''),
        };
      }
    });

    if (request.length) {
      await updateKnowledgeBaseFiles({
        id: id,
        body: request,
      });
    }
  };

  const handleSingleItemMove = async (moveToFolder, item) => {
    item.checked = true;
    if (item.type === 'folder') {
      setCheckedChildren(item);
    }

    await handleMoveItems(moveToFolder);
  };

  const handleSingleItemDelete = async (item) => {
    const tree = getRootFolder(item);

    if (item.type === 'folder') {
      const files = getChildrenFiles(item);

      if (files.length) {
        await handleItemsDelete(files);
      } else {
        const newActiveBranch = { ...activeBranch };

        newActiveBranch.children = newActiveBranch.children.filter((child) => child !== item);
        removeEmptyFolders([item]);
        updateBranchInTree(tree, activeBranch, newActiveBranch);
        updateActiveBranch(newActiveBranch);
      }
    } else {
      await handleItemsDelete([item]);
    }
  };

  return (
    <Table wrapperClassName="max-h-[71vh] py-0" className={'relative'}>
      <TableHeader className={'bg-white z-10'}>
        <TableRow className={'border-b-0'}>
          <TableHead className="border-[#E3E6E8] border-l border-t border-r-0 rounded-tl-[5px] text-center text-grey_text"></TableHead>
          <TableHead className="border-[#E3E6E8] border-t border-l-0 border-r-0 text-start text-grey_text">
            Name
          </TableHead>
          <TableHead className="border-[#E3E6E8] border-t border-l-0 border-r-0 text-center text-grey_text">
            Type
          </TableHead>
          <TableHead className="border-[#E3E6E8] border-t border-l-0 border-r-0 text-center text-grey_text">
            Updated
          </TableHead>
          <TableHead className="border-[#E3E6E8] border-t border-l-0 border-r-0 text-center text-grey_text">
            Auto-sync
          </TableHead>
          <TableHead className="border-[#E3E6E8] border-t border-l-0 border-r rounded-tr-[5px]  text-grey_text"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody className="">
        {Boolean(activeBranch?.parent) && (
          <TableRow
            className={
              'transition cursor-pointer hover:shadow-[0px_1px_2px_0px_rgba(0,_0,_0,_0.25)] bg-[#F4F8F7] hover:bg-[#E2EAE8]'
            }
            onDoubleClick={() => handleDoubleRowClick(activeBranch?.parent)}
          >
            <TableCell
              padding={'pl-5 pr-0'}
              className="table-fixedt text-center min-w-[50px] max-w-[100px] border-[#E3E6E8] border-l !rounded-tl-none"
            >
              <div className="whitespace-nowrap hyphens-auto truncate flex items-center justify-center gap-2.5">
                <div>{getKnowledgeBaseIcon(activeBranch?.parent)}</div>
                <div className={'min-w-6'}></div>
              </div>
            </TableCell>
            <TableCell
              colSpan={5}
              padding={'p-5'}
              className="table-fixedt text-start max-w-80 border-[#E3E6E8] !rounded-tr-none"
            >
              <div className="whitespace-nowrap hyphens-auto truncate flex items-center justify-start">
                {<BackArrowIcon />}
              </div>
            </TableCell>
          </TableRow>
        )}
        {!activeBranch?.children?.length && (
          <TableRow>
            <TableCell colSpan={6} className={'!rounded-t-none'}>
              <LoaderWrapper
                loader={<Skeleton className="w-full h-6" />}
                isLoading={isLoadingFiles}
              >
                <p className="opacity-70 font-semibold text-base text-ebony text-center">
                  {activeBranch.root
                    ? 'No data sources yet'
                    : `${activeBranch.name} folder is empty`}
                </p>
              </LoaderWrapper>
            </TableCell>
          </TableRow>
        )}
        {Boolean(activeBranch?.children?.length) &&
          activeBranch.children.map((item) => {
            return (
              <CurrentKnowledgeBaseTableRow
                activeBranch={activeBranch}
                key={`item-name-${item.name}`}
                item={item}
                handleRowClick={handleRowClick}
                handleDoubleRowClick={handleDoubleRowClick}
                handleSingleItemEdit={handleSingleItemEdit}
                handleSingleItemMove={handleSingleItemMove}
                handleSingleItemDelete={handleSingleItemDelete}
                onCheckedChange={onCheckedChange}
                deleteContentMode={deleteContentMode}
                moveContentMode={moveContentMode}
                filteredValue={filteredValue}
              />
            );
          })}
      </TableBody>
    </Table>
  );
};
