import { clsx } from 'clsx';
import { TableCell, TableRow } from 'src/components/ui/table';
import { getKnowledgeBaseIcon } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseIcons/CurrentKnowledgeBaseIcons';
import { AiKnowledgeBaseToolTip } from 'src/pages/AIKnowledgeBasePage/AiKnowledgeBaseToolTip';
import { KnowledgeBaseAutosyncIcon } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/KnowledgeBaseAutosyncIcon/KnowledgeBaseAutosyncIcon';
import { EditKnowledgeBaseItemModal } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/EditKnowledgeBaseItemModal/EditKnowledgeBaseItemModal';
import { MoveKnowledgeBaseItemModal } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/MoveKnowledgeBaseItemModal/MoveKnowledgeBaseItemModal';
import { DeleteKnowledgeBaseItemModal } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/DeleteKnowledgeBaseItemModal/DeleteKnowledgeBaseItemModal';
import { CheckboxKnowledgeBasePage } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseTableList/CheckboxKnowledgeBase';
import React, { useEffect } from 'react';
import { getCheckedFiles, setCheckedChildren } from 'src/utils/fileUtils';

const highlightMatch = (text, query) => {
  if (!query) return text;

  const regex = new RegExp(`(${query})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <mark key={index} className="bg-[#CCF4D8] ">
        {part}
      </mark>
    ) : (
      part
    ),
  );
};

export const CurrentKnowledgeBaseTableRow = ({
  item,
  activeBranch,
  handleRowClick,
  handleDoubleRowClick,
  handleSingleItemEdit,
  handleSingleItemMove,
  handleSingleItemDelete,
  onCheckedChange,
  deleteContentMode,
  moveContentMode,
  filteredValue,
}) => {
  const is = {
    file: item.type === 'file',
    folder: item.type === 'folder',
    delete: deleteContentMode,
    move: moveContentMode,
    deleting: item.state === 'deleting',
    uploading: item.state === 'uploading',
  };
  const [checked, setChecked] = React.useState(item.checked);
  const [checkedCount, setCheckedCount] = React.useState(0);

  const onChange = (checked, item) => {
    item.checked = checked;
    setChecked(checked);

    if (is.folder) {
      setCheckedChildren(item, checked);
    }
    onCheckedChange();
  };

  useEffect(() => {
    setChecked(item.checked);
  }, [activeBranch]);

  useEffect(() => {
    if (checked && is.folder) {
      const checkedFiles = getCheckedFiles(item).filter((file) => file.type !== 'folder');
      setCheckedCount(checkedFiles.length);
    } else {
      setCheckedCount(0);
    }
  }, [checked]);

  const onClick = (item) => {
    if ((is.delete || is.move) && is.file) {
      item.checked = !checked;
      setChecked(!checked);
      onCheckedChange();
    }
    handleRowClick(item);
  };

  return (
    <TableRow
      onClick={() => onClick(item)}
      onDoubleClick={() => handleDoubleRowClick(item)}
      key={`${item.name}`}
      className={clsx(
        'transition cursor-pointer hover:shadow-[0px_1px_2px_0px_rgba(0,_0,_0,_0.25)]',
        (is.deleting || is.uploading) && 'opacity-50 pointer-events-none',
        !checked && is.folder && 'bg-[#F1F6F6] hover:bg-[#E2EAE8]',
        !checked && is.file && 'hover:bg-[#F8FBFA]',
        checked && is.delete && is.folder && 'bg-[#AA1E1E26] hover:bg-[#AA1E1E40]',
        checked && is.delete && is.file && 'bg-[#FF51511A] hover:bg-[#FF515133]',
        checked && is.move && is.folder && 'bg-[#DBF0E0] hover:bg-[#CAE7D1]',
        checked && is.move && is.file && 'bg-[#F4FFF7] hover:bg-[#E8FCED]',
      )}
    >
      <TableCell
        padding={'pl-5 pr-0'}
        className="table-fixedt text-center min-w-[50px] max-w-[100px] border-[#E3E6E8] border-l !rounded-tl-none"
      >
        <div className="whitespace-nowrap hyphens-auto truncate flex items-center justify-center gap-2.5">
          <div>{getKnowledgeBaseIcon(item)}</div>
          <div
            className={clsx(
              'min-w-6 rounded-[50px] bg-white text-tiny font-semibold py-1 px-1',
              is.folder && checked ? 'visible' : 'invisible',
            )}
          >
            {checkedCount}
          </div>
        </div>
      </TableCell>
      <TableCell padding={'p-5'} className="table-fixed text-start max-w-80">
        <AiKnowledgeBaseToolTip
          side={'top'}
          trigger={
            <p className="font-medium whitespace-nowrap text-ebony_opacity_80 hyphens-auto  text-base truncate">
              {highlightMatch(item.name, filteredValue)}
            </p>
          }
          content={<span className={'text-xs text-ebony_opacity_50'}>{item.name}</span>}
        />
      </TableCell>
      <TableCell padding={'p-5'} className="table-fixed text-center max-w-80">
        <p
          title={item.type}
          className="font-medium whitespace-nowrap text-ebony_opacity_50 hyphens-auto  text-sm truncate capitalize"
        >
          {item.type}
        </p>
      </TableCell>
      <TableCell padding={'p-5'} className="table-fixed text-center max-w-80">
        <p
          title={item.localDate}
          className="font-medium whitespace-nowrap text-ebony_opacity_50 hyphens-auto  text-sm truncate capitalize"
        >
          {item.localDate}
        </p>
      </TableCell>
      <TableCell padding={'p-5'} className="table-fixed text-center max-w-80">
        <AiKnowledgeBaseToolTip
          side={'top'}
          trigger={
            <div className={'flex items-center justify-center'}>
              <KnowledgeBaseAutosyncIcon color={item.autosync.color} />
            </div>
          }
          content={<span className={'text-xs text-ebony_opacity_50'}>{item.autosync.status}</span>}
        />
      </TableCell>
      <TableCell padding={'p-5'} className="max-w-80 !rounded-tr-none">
        <div
          className="flex items-center justify-center gap-7.5"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            e.stopPropagation();
          }}
        >
          <AiKnowledgeBaseToolTip
            side={'top'}
            trigger={
              <div>
                <EditKnowledgeBaseItemModal
                  handleSingleItemEdit={handleSingleItemEdit}
                  item={item}
                />
              </div>
            }
            content={<span className={'text-xs opacity-50 text-ebony'}>Edit</span>}
          />
          <AiKnowledgeBaseToolTip
            side={'top'}
            trigger={
              <div>
                <MoveKnowledgeBaseItemModal
                  activeBranch={activeBranch}
                  handleMoveFiles={handleSingleItemMove}
                  item={item}
                />
              </div>
            }
            content={<span className={'text-xs opacity-50 text-ebony'}>Move</span>}
          />
          <AiKnowledgeBaseToolTip
            side={'top'}
            trigger={
              <div>
                <DeleteKnowledgeBaseItemModal
                  handleSingleItemDelete={handleSingleItemDelete}
                  item={item}
                />
              </div>
            }
            content={<span className={'text-xs opacity-50 text-ebony'}>Delete</span>}
          />
          {(is.delete || is.move) && (
            <CheckboxKnowledgeBasePage checked={checked} item={item} onChange={onChange} />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};
