import React, { useEffect } from 'react';
import { getParentsFolders } from 'src/utils/fileUtils';
import {
  BreadcrumbArrowIcon,
  getKnowledgeBaseIcon,
} from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseIcons/CurrentKnowledgeBaseIcons';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';

export const CurrentKnowledgeBaseBreadcrumb = ({ file, updateActiveBranch, knowledgeBaseName }) => {
  const [items, setItems] = React.useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (knowledgeBaseName) {
      const fullPathItems = file ? getParentsFolders(file) : [];

      fullPathItems.forEach((item) => {
        if (item.root) {
          item.name = knowledgeBaseName;
        }
      });

      setItems(fullPathItems);
    }
  }, [knowledgeBaseName, file, updateActiveBranch]);

  const onClick = (item) => {
    item.type === 'folder' && updateActiveBranch(item);
  };

  return (
    <div className="file-path-container flex flex-row justify-start gap-[5px] items-center flex-wrap">
      <div
        className="flex flex-row items-center justify-center gap-2 opacity-50 hover:opacity-100 hover:cursor-pointer hover:underline decoration-ebony"
        onClick={() => navigate(PATH_DASHBOARD.AI_KNOWLEDGE_BASE)}
      >
        <div className="max-h-[14px]">{getKnowledgeBaseIcon({ type: 'folder' })}</div>
        <span className="font-jamjuree text-xs text-ebony select-none">AI Knowledge Bases</span>
        <BreadcrumbArrowIcon />
      </div>

      {items.map((item, index) => {
        return (
          <div
            className="flex flex-row items-center justify-center gap-2 opacity-50 hover:opacity-100 hover:cursor-pointer hover:underline decoration-ebony"
            key={`${item.path}-${index}`}
            onClick={() => onClick(item)}
          >
            <div className="max-h-[14px] [&_svg]:max-h-[14px]">{getKnowledgeBaseIcon(item)}</div>
            <span className="font-jamjuree text-xs text-ebony select-none max-w-52 overflow-ellipsis overflow-hidden whitespace-nowrap">
              {item.name}
            </span>
            {index !== items.length - 1 && <BreadcrumbArrowIcon />}
          </div>
        );
      })}
    </div>
  );
};
