import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  storageMenuOpen: true,
};
const menuSlice = createSlice({
  name: 'menuSlice',
  initialState,
  reducers: {
    setStorageMenuOpen: (state, action) => {
      state.storageMenuOpen = action.payload;
    },
  },
});

export const { setStorageMenuOpen } = menuSlice.actions;
export default menuSlice.reducer;
