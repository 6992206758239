import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { Button } from 'src/components/ui/button';
import { CreateEditAiKnowledgeBaseForm } from './CreateEditAiKnowledgeBaseForm';

export const CreateEditAiKnowledgeBaseModal = ({ disabled = false, knowledgeBase }) => {
  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        {knowledgeBase?.id ? (
          <button
            className={clsx(
              disabled && 'pointer-events-none opacity-50',
              'flex gap-2 opacity-30 hover:opacity-100 transition',
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M1.55556 12.4332H2.65533L9.89956 5.18897L8.79978 4.0892L1.55556 11.3334V12.4332ZM14 13.9888H0V10.6886L10.4494 0.239197C10.5953 0.0933865 10.7931 0.0114746 10.9993 0.0114746C11.2056 0.0114746 11.4034 0.0933865 11.5492 0.239197L13.7496 2.43953C13.8954 2.58539 13.9773 2.78318 13.9773 2.98942C13.9773 3.19566 13.8954 3.39345 13.7496 3.53931L4.85567 12.4332H14V13.9888ZM9.89956 2.98942L10.9993 4.0892L12.0991 2.98942L10.9993 1.88964L9.89956 2.98942Z"
                fill="#05050D"
              />
            </svg>
          </button>
        ) : (
          <Button
            variant="outline"
            className={clsx(disabled && 'pointer-events-none opacity-50', 'flex gap-2 shadow-md')}
          >
            <p className="font-medium text-base-xs">Create new</p>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.8"
                d="M6.2 6.2V1H7.8V6.2H13V7.8H7.8V13H6.2V7.8H1V6.2H6.2Z"
                fill="#05050D"
                stroke="#05050D"
                strokeWidth="0.5"
              />
            </svg>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent closeButtonHidden>
        <DialogHeader>
          <DialogTitle>{knowledgeBase?.id ? 'Rename' : 'New Knowledge Base'}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <CreateEditAiKnowledgeBaseForm close={closePopup} knowledgeBase={knowledgeBase} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
