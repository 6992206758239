import moment from 'moment/moment';
import { Separator } from 'src/components/ui/separator';

export const ConversationResolvedMessage = ({ date }) => {
  return (
    <li
      className={
        'list-none flex ext-sm font-medium flex-row w-full justify-center items-center relative mt-4'
      }
    >
      <div
        className={
          'py-2 px-4 rounded-3xl border border-[#389D61] text-xs text-[#389D61] bg-white z-10'
        }
      >
        Resolved – {moment(date).format('lll')}
      </div>
      <Separator className={'!bg-[#389D61] w-full absolute top-[50%]'} />
    </li>
  );
};
