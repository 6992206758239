import { clsx } from 'clsx';
import { MarkdownRenderer } from 'src/components/MarkdownRenderer/MarkdownRenderer';
import moment from 'moment/moment';

export const ConversationUserMessage = ({ message }) => {
  return (
    <li
      key={`message-${message._id}`}
      className={clsx(
        'list-none flex flex-col text-sm font-medium text-ebony_opacity_70 items-start',
      )}
    >
      <div className={clsx('lg:max-w-[80%] flex flex-col items-end')}>
        <div className={clsx('rounded-md-10 p-4 max-w-full bg-light')}>
          <MarkdownRenderer>{message.body}</MarkdownRenderer>
        </div>
        <div className={'text-[11px] text-ebony_opacity_30 font-medium'}>
          <p>{moment(message.created_at).format('lll')}</p>
        </div>
      </div>
    </li>
  );
};
