import React, { useState } from 'react';
import Button from '../../../../components/Button/Button';
import { PaymentHistoryFilterPopupForm } from './PaymentHistoryFilterPopupForm';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';

export const PaymentHistoryFilterPopup = ({
  disabled = false,
  submitDateFilter = () => {},
  dateDefault,
}) => {
  const [open, setOpen] = useState(false);

  const submit = (formData) => {
    submitDateFilter(formData);
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(disabled && 'pointer-events-none opacity-50', 'cursor-pointer')}
      >
        <Button className="transparent">
          <p>Filters</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="11"
            viewBox="0 0 12 11"
            fill="none"
          >
            <path
              d="M11.805 0H0.180011C0.130937 0.00385609 0.0851092 0.0264716 0.051724 0.0633088C0.0183387 0.100146 -0.000134235 0.148479 1.15866e-05 0.198611V0.855555C-0.000439034 0.92289 0.0122585 0.989644 0.0373659 1.05194C0.0624732 1.11423 0.0994893 1.17081 0.146261 1.2184L4.64626 5.80174V9.62118L7.365 11V5.7941L11.865 1.21076C11.9511 1.11618 11.9993 0.99217 12 0.863195V0.198611C12 0.145936 11.9795 0.0954187 11.9429 0.0581719C11.9063 0.0209251 11.8567 0 11.805 0Z"
              fill="#05050D"
            />
          </svg>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Filters</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <PaymentHistoryFilterPopupForm onSubmit={submit} dateDefault={dateDefault} />
        </div>
        <DialogFooter>
          <Button
            style={{ width: 'auto' }}
            type="submit"
            form="aply-date-filter-form"
            className="green"
          >
            Use Filters
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
