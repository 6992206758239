import moment from 'moment';
import { clsx } from 'clsx';
import { MarkdownRenderer } from 'src/components/MarkdownRenderer/MarkdownRenderer';

export const PlaygroundUserMessage = ({ className, message, ...props }) => {
  return (
    <li className={clsx('flex flex-col items-end gap-[5px]', className)} {...props}>
      <div
        className={
          'bg-light p-4 rounded-md-10 md:max-w-[90%] lg:max-w-[85%] xl:max-w-[70%] text-ebony_opacity_70 text-base font-normal'
        }
      >
        <MarkdownRenderer>{message.body}</MarkdownRenderer>
      </div>
      <div className={'flex'}>
        <span className={'text-ebony_opacity_30 text-[0.688rem] font-medium'}>
          {moment(moment.utc(message.created_at).toDate()).format('hh:mm A')}
        </span>
      </div>
    </li>
  );
};
