import { Checkbox } from 'src/components/ui/checkbox';
import React from 'react';

export const CheckboxKnowledgeBasePage = ({ item, checked, onChange = () => {} }) => {
  return (
    <Checkbox
      className={'w-5 h-5'}
      onDoubleClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      checked={checked}
      onCheckedChange={(checked) => {
        onChange(checked, item);
      }}
    />
  );
};
