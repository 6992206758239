import moment from 'moment/moment';
import { getKnowledgeBaseConfig, setKnowledgeBaseConfig } from 'src/utils/pageStorageConfig';

const DATE_FORMAT = 'll';
export const SORT_MAP = {
  type: 'By Type',
  name: 'By Name',
  date: 'By Date',
};

export const AUTOSYNC_STATUSES_MAP = {
  unavailable: {
    status: 'Unavailable',
    color: '#C1CACB',
  },
  active: {
    status: 'Active',
    color: '#33FF71',
  },
  paused: {
    status: 'Paused',
    color: '#FFBA33',
  },
  failed: {
    status: 'Failed',
    color: '#FF3F33',
  },
};

export const getFileExtension = (path) => {
  const basename = path.split(/[\\/]/).pop();
  const pos = basename.lastIndexOf('.');

  if (basename === '' || pos < 1) {
    return '';
  }

  return basename.slice(pos + 1);
};

function findWhere(array, key, value) {
  let t = 0;
  while (t < array.length && array[t][key] !== value) {
    t++;
  }

  return t < array.length ? array[t] : false;
}

export const getFoldersStructure = (files) => {
  const tree = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const isInRoot = !Boolean(file.path) || file.path === '/';
    const path = isInRoot ? [''] : file.path.split('/');
    let currentLevel = tree;
    let parent;

    path.push(file.name);

    for (let j = 0; j < path.length; j++) {
      const isLastItem = j === path.length - 1;
      const part = path[j];
      const ext = getFileExtension(part);
      const existingPath = findWhere(currentLevel, 'name', part);

      if (existingPath) {
        currentLevel = existingPath.children;
        parent = existingPath;
      } else {
        let newPart;

        if (isLastItem) {
          newPart = {
            ...file,
            parent,
            type: 'file',
            autosync: AUTOSYNC_STATUSES_MAP.unavailable,
            disabled: file.state !== 'uploaded',
            path: `${file.path || ''}/${file.name}`.replace(/\/\//, '/'),
            localDate: getLocalFileDate(file.updated_at),
          };
        } else {
          newPart = {
            name: part,
            parent,
            type: 'folder',
            autosync: AUTOSYNC_STATUSES_MAP.unavailable,
            path: parent ? `${parent.path}/${part}`.replace(/\/\//, '/') : '/',
            children: [],
          };
        }

        currentLevel.push(newPart);
        currentLevel = newPart.children;
        parent = newPart;
      }
    }
  }

  tree[0].root = true;
  mergeWithEmptyFolders(tree[0]);
  setUpdatedAtFolders(tree[0]);

  return tree[0];
};

export const sortByType = (array, type) => {
  return array
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => (a.type === type ? -1 : 1));
};

export const sortByName = (array) => {
  return array.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

export const sortByDate = (array) => {
  return array
    .sort((a, b) => {
      return moment(b.updated_at).format('YYYYMMDD') - moment(a.updated_at).format('YYYYMMDD');
    })
    .sort((a, b) => (a.type === 'folder' ? -1 : 1));
};

export const createFolder = (name, parent) => {
  const folder = {
    name,
    parent: parent,
    type: 'folder',
    autosync: AUTOSYNC_STATUSES_MAP.unavailable,
    path: `${parent ? parent.path : ''}/${name}`.replace(/\/\//, '/'),
    localDate: getLocalFileDate(),
    children: [],
  };
  const config = getKnowledgeBaseConfig();

  config.emptyFolders = config.emptyFolders || [];

  const isLocalExist = config.emptyFolders.some((emptyFolder) => emptyFolder.path === folder.path);

  if (!isLocalExist) {
    const localFolder = { ...folder, parent: parent.path };

    config.emptyFolders.push(localFolder);
    setKnowledgeBaseConfig(config);
  }

  return folder;
};

export const createFile = (file, parent) => {
  return {
    ...file,
    parent,
    type: 'file',
    autosync: AUTOSYNC_STATUSES_MAP.unavailable,
    path: `${file.path}/${file.name}`,
    localDate: getLocalFileDate(file.updated_at),
  };
};

export const updateBranchInTree = (tree, branch, newBranch) => {
  if (tree.path === branch.path) {
    Object.assign(tree, { ...newBranch });
    mergeWithEmptyFolders(tree);

    return tree;
  } else {
    for (let i = 0; i < tree.children.length; i++) {
      if (tree.children[i].type === 'folder') {
        updateBranchInTree(tree.children[i], branch, newBranch);
      }
    }
  }
};

export const findBranch = (tree, path) => {
  let result;

  if (!path) {
    return false;
  }

  if (tree.path === path) {
    return tree;
  }

  if (tree.children && tree.children.length) {
    for (let i = 0; i < tree.children.length; i++) {
      if (tree.children[i].type === 'folder') {
        result = findBranch(tree.children[i], path);

        if (result) {
          return result;
        }
      }
    }
  }
};

export const findActiveBranch = (tree) => {
  const { activeBranchPath } = getKnowledgeBaseConfig();

  if (!activeBranchPath) {
    return false;
  }

  return findBranch(tree, activeBranchPath);
};

export const getLocalFileDate = (date = new Date(), format = DATE_FORMAT) => {
  const utcDate = moment.utc(date).toDate();

  return moment(utcDate).format(format);
};

export const getFoldersArrayFromTree = (tree) => {
  let folders = [];

  if (tree.type === 'folder') {
    folders.push(tree);
  }

  for (let i = 0; i < tree.children.length; i++) {
    const children = tree.children[i];

    if (children.type === 'folder') {
      const childFolders = getFoldersArrayFromTree(children);

      folders = [...folders, ...childFolders];
    }
  }

  return folders;
};

export const getCheckedFiles = (branch) => {
  let folders = [];

  if (branch.checked) {
    folders.push(branch);
  }

  for (let i = 0; i < branch.children.length; i++) {
    const children = branch.children[i];

    if (children.type === 'folder') {
      const childFolders = getCheckedFiles(children);

      folders = folders.concat(childFolders);
    } else if (children.checked) {
      folders.push(children);
    }
  }

  return folders;
};

export const setCheckedChildren = (branch, checked) => {
  checked = checked === undefined ? branch.checked : checked;

  if (!branch.root) {
    branch.checked = checked;
  }

  for (let i = 0; i < branch.children.length; i++) {
    const children = branch.children[i];

    children.checked = checked;

    if (children.type === 'folder') {
      setCheckedChildren(children, checked);
    }
  }
};

export const updateCheckedPaths = (files, path) => {
  return files.map((file) => {
    if (file.parent && file.parent.checked) {
      file.newPath = `${file.parent.newPath}/${file.name}`;
    } else {
      file.newPath = `${path}/${file.name}`;
    }

    file.newPath = file.newPath.replace(/\/\//, '/');

    return file;
  });
};

export const getRootFolder = (item) => {
  let result;

  if (item.root) {
    result = item;
  }

  if (item.parent) {
    result = getRootFolder(item.parent);
  }

  return result;
};

export const getChildrenFiles = (item) => {
  let files = [];

  for (let i = 0; i < item.children.length; i++) {
    const children = item.children[i];

    if (children.type === 'folder') {
      const childFolders = getChildrenFiles(children);

      files = files.concat(childFolders);
    } else {
      files.push(children);
    }
  }

  return files;
};

export const getItemsByName = (name, branch) => {
  let items = [];

  for (let i = 0; i < branch.children.length; i++) {
    const children = branch.children[i];

    if (children.name.toLowerCase().includes(name.toLowerCase())) {
      items.push(children);
    }

    if (children.type === 'folder') {
      const childFolders = getItemsByName(name, children);

      items = items.concat(childFolders);
    }
  }

  return items;
};

export const filterBranchByName = (name, branch) => {
  const newBranch = { ...branch };
  newBranch.children = getItemsByName(name, newBranch);

  return newBranch;
};

export const getParentsFolders = (branch) => {
  let items = [];

  items.unshift(branch);

  if (branch.parent) {
    const parent = getParentsFolders(branch.parent);
    items = parent.concat(items);
  }

  return items;
};

export const setUpdatedAtFolders = (branch) => {
  if (branch.children) {
    for (let i = 0; i < branch.children.length; i++) {
      setUpdatedAtFolders(branch.children[i]);
    }
  }

  if (branch.parent) {
    if (branch.parent.updated_at) {
      const isAfter = moment(branch.updated_at).isSameOrAfter(branch.parent.updated_at);

      branch.parent.updated_at = isAfter ? branch.updated_at : branch.parent.updated_at;
    } else {
      branch.parent.updated_at = branch.updated_at;
    }

    branch.parent.localDate = getLocalFileDate(branch.parent.updated_at);
  }
};

export const mergeWithEmptyFolders = (branch) => {
  const config = getKnowledgeBaseConfig();
  config.emptyFolders = config.emptyFolders || [];

  config.emptyFolders.forEach((folder) => {
    const parent = findBranch(branch, folder.parent);

    if (parent) {
      const child = parent.children.find((child) => child.path === folder.path);

      if (child) {
        const childFiles = getChildrenFiles(child);
        if (childFiles.length) {
          config.emptyFolders = config.emptyFolders.filter(
            (emptyFolder) => emptyFolder.path !== folder.path,
          );

          setKnowledgeBaseConfig(config);
        }
      } else {
        parent.children.push({ ...folder, parent });
      }
    }
  });
};

export const removeEmptyFolders = (folders) => {
  const config = getKnowledgeBaseConfig();

  config.emptyFolders = config.emptyFolders || [];
  folders.forEach((folder) => {
    config.emptyFolders = config.emptyFolders.filter(
      (emptyFolder) => emptyFolder.path !== folder.path,
    );
  });

  setKnowledgeBaseConfig(config);

  return config.emptyFolders;
};

export const addFolderToEmptyFolders = (branch) => {
  if (branch.type === 'folder') {
    const newFolder = {
      ...branch,
      children: [],
      path: branch.newPath || branch.path,
      checked: false,
    };

    if (branch.parent) {
      const parent = newFolder.path.split('/');

      newFolder.parent = parent.slice(0, parent.length - 1).join('/') || '/';
    }

    const config = getKnowledgeBaseConfig();

    config.emptyFolders = config.emptyFolders || [];

    const isEmptyFolderExist = config.emptyFolders.some(
      (emptyFolder) => emptyFolder.path === newFolder.path,
    );

    if (!newFolder.root && !isEmptyFolderExist) {
      config.emptyFolders = removeEmptyFolders([branch]);
      config.emptyFolders.push(newFolder);
      setKnowledgeBaseConfig(config);
    }
  }

  if (branch.parent) {
    addFolderToEmptyFolders(branch.parent);
  }
};

export const editEmptyFolder = (name, item) => {
  const config = getKnowledgeBaseConfig();

  config.emptyFolders = config.emptyFolders || [];

  const emptyFolder = config.emptyFolders.find((folder) => folder.path === item.path);
  let newPath = item.path.split('/');

  newPath = newPath.slice(0, newPath.length - 1).join('/') || '/';
  emptyFolder.name = name;
  emptyFolder.path = `${newPath}/${name}`.replace(/\/\//, '/');
  setKnowledgeBaseConfig(config);
};
