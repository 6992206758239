import { clsx } from 'clsx';
import { useState } from 'react';
import { Root, Content, Trigger } from '@radix-ui/react-collapsible';
import { MarkdownRenderer } from 'src/components/MarkdownRenderer/MarkdownRenderer';
import moment from 'moment/moment';
import { ConversationSourceList } from 'src/pages/ConversationsPage/ConversationChat/ConversationSourceList/ConversationSourceList';

const ShowSourcesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      className={'group-hover:stroke-ebony'}
      d="M9.5 5.69737L12 7L7 9.60526L2 7L4.5 5.69737M9.5 8.59211L12 9.89474L7 12.5L2 9.89474L4.5 8.59211M7 1.5L12 4.10526L7 6.71053L2 4.10526L7 1.5Z"
      stroke="#05050d6e"
    />
  </svg>
);

const HideSourcesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
    <path
      className={'group-hover:fill-ebony'}
      d="M9.41875 2.10474C7.27375 -0.0349126 3.75375 -0.0349126 1.60875 2.10474C-0.53625 4.24439 -0.53625 7.75561 1.60875 9.89526C3.75375 12.0349 7.21875 12.0349 9.36375 9.89526C11.5087 7.75561 11.5638 4.24439 9.41875 2.10474ZM7.05375 8.30424L5.51375 6.76808L3.97375 8.30424L3.20375 7.53616L4.74375 6L3.20375 4.46384L3.97375 3.69576L5.51375 5.23192L7.05375 3.69576L7.82375 4.46384L6.28375 6L7.82375 7.53616L7.05375 8.30424Z"
      fill="#05050d6e"
    />
  </svg>
);

export const ConversationAIMessage = ({ message }) => {
  const [open, setOpen] = useState(false);
  const isSources = Boolean(message.sources && message.sources.length);

  return (
    <li
      key={`message-${message._id}`}
      className={clsx(
        'list-none flex flex-col text-sm font-medium text-ebony_opacity_70 items-end',
      )}
    >
      <div className={clsx('lg:max-w-[80%] flex flex-col items-end')}>
        <Root open={open} onOpenChange={setOpen}>
          <div className={clsx('rounded-md-10 p-4 max-w-full bg-[#EEECFD]')}>
            <MarkdownRenderer>{message.body}</MarkdownRenderer>
            <Content
              className={
                'transition-all data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down overflow-hidden'
              }
            >
              {isSources && <ConversationSourceList sources={message.sources} />}
            </Content>
          </div>
          <div
            className={clsx(
              'text-[11px] text-ebony_opacity_30 font-medium flex flex-row',
              isSources ? 'justify-between' : 'justify-end',
            )}
          >
            <Trigger>
              {isSources && (
                <div className={'group flex row items-center gap-[5px]'}>
                  {open ? <HideSourcesIcon /> : <ShowSourcesIcon />}
                  <span className={'group-hover:text-ebony'}>{open ? 'Hide' : 'Show'} Sources</span>
                </div>
              )}
            </Trigger>
            <p>{moment(message.created_at).format('lll')}</p>
          </div>
        </Root>
      </div>
    </li>
  );
};
