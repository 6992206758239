import React, { useState } from 'react';
import { Button } from 'src/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import Input from 'src/components/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { getFileExtension } from 'src/utils/fileUtils';
import { KNOWLEDGE_BASE_FILE_EXTENSIONS } from 'src/shared/constants';

export const CreateFAQPopup = ({
  trigger,
  addKnowledgeBaseSource,
  closeSourceModal = () => {},
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { dirtyFields, isDirty, isValid },
  } = useForm({
    defaultValues: {
      question: '',
      answer: '',
    },
    resolver: yupResolver(
      object({
        question: stringScheme({ required: true, minLength: 3 }).label('Question'),
        answer: stringScheme({ required: true, minLength: 3 }).label('Answer'),
      }),
    ),
  });

  const submit = (data) => {
    const ext = getFileExtension(data.question);

    if (ext) {
      const allowedExtension = KNOWLEDGE_BASE_FILE_EXTENSIONS.includes(`.${ext}`);

      data.question = allowedExtension ? data.question : data.question.replace(`.${ext}`, '.txt');
    } else {
      data.question = `${data.question}.txt`;
    }

    addKnowledgeBaseSource(data);
    closeSourceModal();
    close();
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger
          disabled={disabled}
          asChild
          className={clsx(disabled && 'pointer-events-none opacity-50', 'cursor-pointer')}
        >
          {trigger}
        </DialogTrigger>
        <DialogContent closeButtonHidden>
          <DialogHeader>
            <DialogTitle>Add FAQ</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="content">
              <form id="create-new-document" onSubmit={handleSubmit(submit)}>
                <div className={'input-container'}>
                  <Controller
                    control={control}
                    name="question"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        type={'text'}
                        label={'Question'}
                        placeholder={'What is CoSupport AI?'}
                        invalidMessage={error?.message}
                        value={value}
                        isValid={!error?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className={'input-container'}>
                  <Controller
                    control={control}
                    name="answer"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        type={'textarea'}
                        label={'Answer'}
                        placeholder={
                          'CoSupport AI is an all-in-one AI solution for customer support and sales.'
                        }
                        invalidMessage={error?.message}
                        value={value}
                        isValid={!error?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="flex justify-center mt-5">
                  <div className="flex gap-2.5">
                    <Button
                      variant="outline"
                      type="button"
                      className="min-w-24 shadow-md"
                      onClick={close}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!isDirty || !isValid}
                      type="submit"
                      className="min-w-24 shadow-md"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
