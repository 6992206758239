import React from 'react';

export const HelpIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 3.05176e-05C3.5816 3.05176e-05 0 3.58163 0 8.00003C0 12.4184 3.5816 16 8 16C12.4184 16 16 12.4184 16 8.00003C16 3.58163 12.4184 3.05176e-05 8 3.05176e-05ZM8.8 12.8H7.2V11.2H8.8V12.8ZM8.8 9.48723V10.4H7.2V8.80003C7.2 8.58786 7.28429 8.38437 7.43431 8.23435C7.58434 8.08432 7.78783 8.00003 8 8.00003C8.8824 8.00003 9.6 7.28243 9.6 6.40003C9.6 5.51763 8.8824 4.80003 8 4.80003C7.1176 4.80003 6.4 5.51763 6.4 6.40003H4.8C4.8 5.55134 5.13714 4.73741 5.73726 4.13729C6.33737 3.53717 7.15131 3.20003 8 3.20003C8.84869 3.20003 9.66263 3.53717 10.2627 4.13729C10.8629 4.73741 11.2 5.55134 11.2 6.40003C11.1989 7.10789 10.9625 7.7953 10.5281 8.35416C10.0936 8.91301 9.48573 9.3116 8.8 9.48723Z"
      fill="white"
    />
  </svg>
);
