import { SSE } from 'sse.js';
import { ERROR_MESSAGE_FRIENDLY } from 'src/shared/constants';
import { refreshToken } from 'src/services/FetchRequest';
import { PATH_AUTH_SPA } from 'src/constants/spa-routes';

const getAccessToken = () => {
  let user = localStorage.getItem('user');
  user = user && user !== 'undefined' && JSON.parse(user);

  return user && user.access;
};

const getRefreshToken = () => {
  let user = localStorage.getItem('user');
  user = user && user !== 'undefined' && JSON.parse(user);

  return user && user.refresh;
};

const getSSEHeaders = () => {
  const access = getAccessToken();
  return {
    Authorization: `Bearer ${access}`,
    Accept: 'application/json, application/xml, text/plain, text/html, *.*',
    'Content-Type': 'application/json; charset=utf-8',
  };
};

let isRefreshing = false;

export const sendSSEMessage = (method, url, message, callback = () => {}) => {
  const headers = getSSEHeaders();
  const payload = JSON.stringify([{ body: message }]);

  const source = new SSE(url, {
    method,
    headers,
    payload,
  });

  const timeout = setTimeout(() => {
    callback({
      error: ERROR_MESSAGE_FRIENDLY,
    });
  }, 62 * 1000);

  source.addEventListener('message', (e) => {
    clearTimeout(timeout);
    try {
      const message = JSON.parse(e.data);
      callback({ message });
    } catch (error) {
      callback({ error: 'There was an error while parsing message from AI.' });
    }
  });

  source.addEventListener('error', async (e) => {
    if (e.responseCode === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const refresh = getRefreshToken();
          const tokens = await refreshToken(refresh);
          if (tokens.error?.status === 401) {
            localStorage.removeItem('user');
            localStorage.removeItem('tokens');
            localStorage.removeItem('LATEST_VIEWED_PROJECT_ID');
            window.location.assign(PATH_AUTH_SPA.ROOT);
          }

          if (tokens.access && tokens.refresh) {
            isRefreshing = false;
            localStorage.setItem('user', JSON.stringify(tokens));

            const headers = getSSEHeaders();
            const payload = JSON.stringify([{ body: message }]);
            const source = new SSE(url, {
              method,
              headers,
              payload,
            });

            source.addEventListener('message', (e) => {
              try {
                const message = JSON.parse(e.data);
                callback({ message });
              } catch (error) {
                callback({ error: 'There was an error while parsing message from AI.' });
              }
            });
          } else {
            isRefreshing = false;
            localStorage.removeItem('user');
            window.location.assign(PATH_AUTH_SPA.ROOT);
            return tokens;
          }
        } catch (error) {
          isRefreshing = false;
          return console.log('Refresh token error', error);
        }
      }
    }
  });
};
