import '../ProjectPage.css';
import AIReplyGenerationIcon from '../../../assets/img/ai-reply-generation.svg';
import TextLenseIcon from '../../../assets/img/text-lense-ai.svg';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import { useNotification } from 'src/hooks/useNotification';
import Input from '../../../components/Input/Input';
import CardContainer from '../../../components/CardContainer/CardContainer';
import ActiveProductsCard from '../../../components/ActiveProductsCard/ActiveProductsCard';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { useCreateProjectMutation } from 'src/services';
import { useLoader } from 'src/provider/LoaderProvider';
import { getErrorMessage } from 'src/utils/get-error-message';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { useAppSelector } from 'src/store';
import { ProductsType as ProjectsType } from 'src/constants/products';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';

export const CreateProjectForm = ({ cancelFunc }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const notification = useNotification();
  const [aiReplyGeneration, setAiReplyGeneration] = useState(true);
  const [textLenseAI, setTextLenseAI] = useState(true);
  const loader = useLoader();

  const isMdDown = useMediaQuery('(max-width: 767px)');

  const searchProjectType = searchParams.get('type');

  const { projectType } = useAppSelector((s) => s.projectsSlices);

  const projectTypeVar = projectType || searchProjectType;

  const [
    createProject,
    {
      data: createProjectRes,
      isLoading,
      isError: isCreateProjectError,
      error: createProjectError,
      isSuccess,
    },
  ] = useCreateProjectMutation();

  const toggleAiReplyGeneration = async (checked) => {
    setAiReplyGeneration(checked);
  };
  const toggleTextLenseAI = async (checked) => {
    setTextLenseAI(checked);
  };

  const submit = (data) => {
    const request = {
      name: data.projectName,
      ai_reply_generation: aiReplyGeneration,
      ai_text_lense: textLenseAI,
    };

    createProject(request);
  };

  useEffect(() => {
    if (isLoading) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isCreateProjectError) {
      notification.error(getErrorMessage(createProjectError));
    }
  }, [isCreateProjectError]);

  useEffect(() => {
    if (isSuccess) {
      navigate(`/dashboard/projects/${createProjectRes?.id}?type=${projectTypeVar}`);
    }
  }, [isSuccess]);

  const cancelHandler = () => {
    if (cancelFunc) {
      cancelFunc();
    } else {
      navigate(PATH_DASHBOARD.ACCOUNT);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      projectName: '',
    },
    resolver: yupResolver(
      object({
        projectName: stringScheme({ required: true }).label('Product'),
      }),
    ),
  });

  const isAgentType = projectType === ProjectsType.agent;

  return (
    <React.Fragment>
      <div className={'project-name-container'}>
        <CardContainer>
          <div className="card-header">
            <div className="title">Create and run your new project</div>
          </div>
          <div className="card-body">
            <form id="create-project-form" className="w-full" onSubmit={handleSubmit(submit)}>
              <div className={'input-container'}>
                <Controller
                  control={control}
                  name="projectName"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type={'text'}
                      label={'Project Name'}
                      placeholder={'ABC Studio'}
                      invalidMessage={error?.message}
                      value={value}
                      isValid={!error?.message}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </form>
          </div>
          <div className="card-footer">
            <div className="action-buttons">
              <Button className="transparent black" onClick={cancelHandler}>
                Cancel
              </Button>
              <Button
                disabled={!isDirty}
                type="submit"
                form="create-project-form"
                className="green"
              >
                Save
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="11"
                  viewBox="0 0 15 11"
                  fill="none"
                >
                  <path
                    d="M12.8359 0L5.15844 7.79167L1.99715 4.58333L0.416504 6.1875L5.15844 11L14.4165 1.60417L12.8359 0Z"
                    fill="#33FF71"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </CardContainer>
      </div>
      {isAgentType && (
        <div className="project-main-container">
          {!isMdDown ? (
            <>
              <CardContainer>
                <ActiveProductsCard
                  icon={AIReplyGenerationIcon}
                  title={'AI Reply Generation'}
                  subTitle={'Generate complete text replies'}
                  checked={aiReplyGeneration}
                  onChangeToggle={toggleAiReplyGeneration}
                />
              </CardContainer>
              <CardContainer>
                <ActiveProductsCard
                  icon={TextLenseIcon}
                  title={'Text Lense AI'}
                  subTitle={'Contextual text formating'}
                  checked={textLenseAI}
                  onChangeToggle={toggleTextLenseAI}
                />
              </CardContainer>
            </>
          ) : (
            <CardContainer className="flex flex-col gap-[6px]">
              <div className="card-header">
                <div className="title">
                  <span>Active Products</span>
                </div>
              </div>
              <ActiveProductsCard
                icon={AIReplyGenerationIcon}
                title={'AI Reply Generation'}
                checked={aiReplyGeneration}
                onChangeToggle={toggleAiReplyGeneration}
                isMobile
              />
              <ActiveProductsCard
                icon={TextLenseIcon}
                title={'Text Lense AI'}
                checked={textLenseAI}
                onChangeToggle={toggleTextLenseAI}
                isMobile
              />
            </CardContainer>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
