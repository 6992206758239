import React from 'react';

export const KnowledgeBaseIcon = (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 3.05176e-05C14.5304 3.05176e-05 15.0391 0.189673 15.4142 0.527238C15.7893 0.864804 16 1.32264 16 1.80003V16.2C16 16.6774 15.7893 17.1353 15.4142 17.4728C15.0391 17.8104 14.5304 18 14 18H2C1.46957 18 0.960859 17.8104 0.585786 17.4728C0.210714 17.1353 0 16.6774 0 16.2V1.80003C0 1.32264 0.210714 0.864804 0.585786 0.527238C0.960859 0.189673 1.46957 3.05176e-05 2 3.05176e-05H14ZM8 9.90003H5C4.73478 9.90003 4.48043 9.99485 4.29289 10.1636C4.10536 10.3324 4 10.5613 4 10.8C4 11.0387 4.10536 11.2676 4.29289 11.4364C4.48043 11.6052 4.73478 11.7 5 11.7H8C8.26522 11.7 8.51957 11.6052 8.70711 11.4364C8.89464 11.2676 9 11.0387 9 10.8C9 10.5613 8.89464 10.3324 8.70711 10.1636C8.51957 9.99485 8.26522 9.90003 8 9.90003ZM11 5.40003H5C4.74512 5.40029 4.49997 5.48812 4.31463 5.64559C4.1293 5.80307 4.01777 6.01829 4.00283 6.24729C3.98789 6.47628 4.07067 6.70177 4.23426 6.87768C4.39785 7.05359 4.6299 7.16664 4.883 7.19373L5 7.20003H11C11.2549 7.19978 11.5 7.11194 11.6854 6.95447C11.8707 6.797 11.9822 6.58177 11.9972 6.35278C12.0121 6.12378 11.9293 5.89829 11.7657 5.72238C11.6021 5.54647 11.3701 5.43342 11.117 5.40633L11 5.40003Z"
      fill="white"
    />
  </svg>
);
