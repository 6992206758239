import React from 'react';
import { clsx } from 'clsx';
import { MenuBarComponent } from 'src/components/PageContentWrapper/MenuBarComponent';

const PageContentWrapper = ({ className = '', children }) => {
  return (
    <div
      className={clsx(
        className,
        'container relative z-1 flex flex-col gap-[10px] pb-[20px] px-5 md:pb-[40px] pt-5',
      )}
    >
      <MenuBarComponent />
      {children}
    </div>
  );
};

export default PageContentWrapper;
