import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { DeleteAiKnowledgeBaseForm } from './DeleteAiKnowledgeBaseForm';

export const DeleteAiKnowledgeBaseModal = ({ disabled = false, knowledgeBase }) => {
  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        <button
          className={clsx(
            disabled && 'pointer-events-none opacity-10',
            'flex gap-2 opacity-30 hover:opacity-100 transition-all [&_svg>path]:hover:fill-delete',
          )}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.88878 19V4.83333H2.94434V2.94444H7.66656V2H13.3332V2.94444H18.0554V4.83333H17.111V19H3.88878ZM5.77767 17.1111H15.2221V4.83333H5.77767V17.1111ZM7.66656 15.2222H9.55545V6.72222H7.66656V15.2222ZM11.4443 15.2222H13.3332V6.72222H11.4443V15.2222Z"
              fill="#05050D"
              stroke="white"
              strokeWidth="0.2"
            />
          </svg>
        </button>
      </DialogTrigger>
      <DialogContent closeButtonHidden>
        <DialogHeader>
          <DialogTitle>Delete</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <DeleteAiKnowledgeBaseForm close={closePopup} knowledgeBase={knowledgeBase} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
