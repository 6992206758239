import { ConversationUserMessage } from 'src/pages/ConversationsPage/ConversationChat/ConversationUserMessage/ConversationUserMessage';
import { ConversationAIMessage } from 'src/pages/ConversationsPage/ConversationChat/ConversationAIMessage/ConversationAIMessage';
import { ConversationResolvedMessage } from 'src/pages/ConversationsPage/ConversationChat/ConversationResolvedMessage/ConversationResolvedMessage';
import { ConversationEscalatedMessage } from 'src/pages/ConversationsPage/ConversationChat/ConversationEscalatedMessage/ConversationEscalatedMessage';

const LAST_MESSAGE = {
  RESOLVED: 'End of the chat.',
  ESCALATED: 'Connected to the operator. Please, wait a moment.',
};

const filterMessage = (message) => {
  if (message.body.includes(LAST_MESSAGE.RESOLVED)) {
    return false;
  }
  if (message.body.includes(LAST_MESSAGE.ESCALATED)) {
    return false;
  }

  return true;
};

export const ConversationChat = ({ chat }) => {
  const isResolved = ['closed', 'auto_closed'].includes(chat.status);
  const isEscalated = chat.status === 'escalated';

  return (
    <>
      <ul className={'flex flex-col gap-[5px] h-[100%] pr-[15px] overflow-auto'}>
        {chat.messages.filter(filterMessage).map((message) => {
          const isUser = message.owner === 'user';

          return isUser ? (
            <ConversationUserMessage key={`message-${message._id}`} message={message} />
          ) : (
            <ConversationAIMessage key={`message-${message._id}`} message={message} />
          );
        })}
        {isResolved && <ConversationResolvedMessage date={chat.updated_at} />}
        {isEscalated && <ConversationEscalatedMessage date={chat.updated_at} />}
      </ul>
    </>
  );
};
