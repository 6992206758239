import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import {
  AddSourceKnowledgeBaseIcon,
  FaqFileIcon,
  FreshdeskIntegrationFolderIcon,
  GoogleIntegrationFolderIcon,
  NewFileIcon,
  WebsiteFileIcon,
  ZendeskIntegrationFolderIcon,
} from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseIcons/CurrentKnowledgeBaseIcons';
import { AddKnowledgeBaseSourceItem } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/AddKnowledgeBaseSourceModal/AddKnowledgeBaseSourceItem';
import { CreateFAQPopup } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CreateFAQPopup/CreateFAQPopup';

const SOURCES = [
  {
    id: 0,
    type: 'faq',
    title: 'FAQ',
    description: 'Create FAQ from\n scratch.',
    icon: <FaqFileIcon className={'w-[15px]'} />,
    disabled: false,
  },
  {
    id: 1,
    type: 'file',
    title: 'File',
    description: 'Upload a file to import any\n text content.',
    icon: <NewFileIcon className={'w-[15px]'} />,
    disabled: false,
  },
  {
    id: 2,
    type: 'website',
    title: 'Website',
    description: 'Scrape your website\n content.',
    icon: <WebsiteFileIcon className={'w-[15px]'} />,
    disabled: true,
  },
];

const INTEGRATIONS = [
  {
    id: 0,
    type: 'integration',
    title: 'Zendesk',
    description: 'Sync Help Center articles,\n tickets and macros.',
    icon: <ZendeskIntegrationFolderIcon className={'w-[15px]'} />,
    disabled: true,
  },
  {
    id: 1,
    type: 'integration',
    title: 'Freshdesk',
    description: 'Sync your Freshdesk\n tickets.',
    icon: <FreshdeskIntegrationFolderIcon className={'w-[15px]'} />,
    disabled: true,
  },
  {
    id: 2,
    type: 'integration',
    title: 'Google Drive',
    description: 'Soon',
    icon: <GoogleIntegrationFolderIcon className={'w-[15px]'} />,
    disabled: true,
  },
];

export const AddKnowledgeBaseSourceModal = ({
  disabled = false,
  addKnowledgeBaseSource = () => {},
}) => {
  const [open, setOpen] = useState(false);

  const close = () => setOpen(false);
  const onClick = (source) => {
    addKnowledgeBaseSource(source);
    close();
  };

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        <Button
          variant="outline"
          type="button"
          className="min-w-24 shadow-md flex flex-fow gap-2 text-ebony_opacity_80 text-[13px]"
        >
          <span>Add Source</span>
          <AddSourceKnowledgeBaseIcon />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New source</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4">
          <div className="content flex flex-col gap-6">
            <div className={'flex flex-col gap-2.5'}>
              <div>
                <p className={'text-ebony_opacity_50 text-sm'}>
                  Select the type of source you wish to add.
                </p>
              </div>
              <div className={'grid grid-cols-3 justify-between gap-2.5'}>
                {SOURCES.map((source) => {
                  if (source.type === 'faq') {
                    return (
                      <CreateFAQPopup
                        key={`source-${source.id}`}
                        trigger={
                          <AddKnowledgeBaseSourceItem key={`source-${source.id}`} item={source} />
                        }
                        addKnowledgeBaseSource={(data) => addKnowledgeBaseSource(source, data)}
                        closeSourceModal={close}
                      />
                    );
                  }

                  return (
                    <AddKnowledgeBaseSourceItem
                      key={`source-${source.id}`}
                      item={source}
                      onClick={onClick}
                    />
                  );
                })}
              </div>
            </div>
            <div className={'flex flex-col gap-2.5'}>
              <div>
                <p className={'text-ebony_opacity_80 text-base font-medium'}>Integration</p>
                <p className={'text-ebony_opacity_50 text-sm'}>
                  Add sources from another platform.
                </p>
              </div>
              <div className={'grid grid-cols-3 justify-between gap-2.5'}>
                {INTEGRATIONS.map((source) => {
                  return (
                    <AddKnowledgeBaseSourceItem
                      key={`integrations-${source.id}`}
                      item={source}
                      onClick={onClick}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
