import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { baseApi } from '../services';
import { useDispatch, useSelector } from 'react-redux';

import { unauthorizedMiddleware } from './middleware/unauthorized-middleware';
import projectsSlices from './slices/projectsSlices';
import companySlice from './slices/companySlice';
import menuSlice from './slices/menuSlice';
import playgroundSlices from './slices/playgroundSlices';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { baseSsoApi } from 'src/services/base.sso.api';

const persistConfig = {
  key: 'company',
  storage,
};

const persistConfigMenu = {
  key: 'menu',
  storage,
};
const persistConfigPlayground = {
  key: 'playground',
  storage,
};

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [baseSsoApi.reducerPath]: baseSsoApi.reducer,
  projectsSlices,
  playgroundSlices: persistReducer(persistConfigPlayground, playgroundSlices),
  companySlices: persistReducer(persistConfig, companySlice),
  menuSlices: persistReducer(persistConfigMenu, menuSlice),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      unauthorizedMiddleware,
      baseApi.middleware,
      baseSsoApi.middleware,
    ]),
});

export const persistor = persistStore(store);
export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
