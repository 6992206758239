import './Menu.css';
import React, { useState } from 'react';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { GeneralItems } from './GeneralItems';
import { ProductsItems } from './ProductsItems';
import { MenuArrow } from 'src/assets/icons/MenuArrow';
import { clsx } from 'clsx';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/store';
import { setStorageMenuOpen } from 'src/store/slices/menuSlice';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from 'src/utils/route-paths';
import { firstMenuItemsGeneral, menuItemsGeneral } from 'src/components/Menu/menuItems';
import LogOutButton from 'src/components/LogOutButton/LogOutButton';

export const Menu = () => {
  const dispatch = useDispatch();
  const { storageMenuOpen } = useAppSelector((s) => s.menuSlices);
  const isLgDown = useMediaQuery('(max-width: 992px)');

  const handleOpenMenu = () => {
    dispatch(setStorageMenuOpen(!storageMenuOpen));
  };

  return (
    <div className="h-full relative">
      <MenuArrow
        onClick={handleOpenMenu}
        className={clsx(
          !storageMenuOpen && 'rotate-180',
          'absolute top-20 -right-3 z-40 drop-shadow hover:cursor-pointer hover:[&_rect]:fill-[#f5f5f5]',
        )}
      />
      <div
        className={clsx(
          storageMenuOpen ? 'w-[300px]' : 'w-[90px] min-w-[90px]',
          'desktop-menu-container transition-all duration-300 ease-in-out h-full min-h-screen overflow-y-auto overflow-x-hidden max-h-screen relative',
        )}
      >
        <Link to={ROUTE_PATHS.ACCOUNT}>
          <div className={clsx('desktop-menu-header px-5 py-6')}>
            <div className="mx-2">
              <svg
                width="33"
                height="20"
                viewBox="0 0 33 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99343 4.15288e-05C7.35999 4.16429e-05 4.77036 1.0536 2.90824 2.92896C1.04613 4.80431 -3.59335e-07 7.34784 -4.41153e-07 9.99999C-5.2297e-07 12.6521 1.04613 15.1957 2.90824 17.071C4.77036 18.9464 7.36 19.9999 9.99343 19.9999L9.99343 9.99999L9.99343 4.15288e-05Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22.7544 19.9999C28.2736 19.9999 32.7478 15.5228 32.7478 9.99995C32.7478 4.47713 28.2736 0 22.7544 0C17.2352 0 12.761 4.47713 12.761 9.99995C12.761 15.5228 17.2352 19.9999 22.7544 19.9999ZM22.7324 14.0861C24.9903 14.0861 26.8206 12.2545 26.8206 9.99517C26.8206 7.73584 24.9903 5.90428 22.7324 5.90428C20.4745 5.90428 18.6442 7.73584 18.6442 9.99517C18.6442 12.2545 20.4745 14.0861 22.7324 14.0861Z"
                  fill="white"
                />
              </svg>
            </div>
            <div
              className={clsx(
                storageMenuOpen ? 'opacity-100' : 'opacity-0',
                'transition-all absolute left-20 text-nowrap text-base text-white font-semibold duration-300',
              )}
            >
              <h4>CoSupport AI Hub</h4>
            </div>
          </div>
        </Link>
        <GeneralItems menuItems={firstMenuItemsGeneral} className="border-b" isMobile={isLgDown} />
        <ProductsItems className="border-b border-shark" isMobile={isLgDown} />
        <GeneralItems menuItems={menuItemsGeneral} isMobile={isLgDown} />
        <div className="menu-container border-shark border-t py-7.5 px-5 min-w-[90px]">
          <LogOutButton className="flex flex-row-reverse" />
        </div>
        <div
          className={clsx(
            !storageMenuOpen && '!opacity-0',
            'w-[230px] transition-all duration-300 font-medium text-xs text-black_opacity_40 opacity-50 bottom-2.5 flex left-0 right-0 mx-auto mt-auto mb-5',
          )}
        >
          <p>
            CoSupport AI © {new Date().getFullYear()}.{' '}
            <a
              className="underline"
              href="https://cosupport.ai/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            &{' '}
            <a
              className="underline"
              href="https://cosupport.ai/terms-of-services"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
