import './AfterRegistrationPage.css';
import React from 'react';
import authBg from 'src/assets/img/auth-bg.png';

const AfterRegistrationPage = () => {
  return (
    <div className="flex min-h-screen w-full justify-end lg:flex-row flex-col-reverse bg-[#F1F6F6]">
      <div className="lg:p-[min(5vw,80px)] lg:w-[45%] flex-1 w-full flex justify-center">
        <div className={'flex flex-col justify-center items-center w-full'}>
          <h1 className="font-semibold md:text-[1.75rem] text-lg text-black md:mb-5 mb-3 leading-[125%]">
            Please check your email ***@*** for a verification message.
          </h1>
          <p className="text-black text-xl md:pt-2.5 pt-1">
            Follow the instructions in the email to complete the verification process. If you don’t
            see it in your inbox, check your spam folder.
          </p>
        </div>
      </div>
      <img
        className="object-cover lg:w-[55%] w-full lg:max-h-full max-h-[70px]"
        alt="auth background"
        src={authBg}
      />
      <div
        className={
          'left-0 right-0 text-center m-auto w-[230px] absolute text-xs text-ebony_opacity_60 opacity-50 bottom-2.5 lg:left-auto lg:w-auto lg:right-6 lg:text-start'
        }
      >
        <p>
          CoSupport AI © {new Date().getFullYear()}.{' '}
          <a
            className="underline"
            href="https://cosupport.ai/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{' '}
          &{' '}
          <a
            className="underline"
            href="https://cosupport.ai/terms-of-services"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
        </p>
      </div>
    </div>
  );
};

export default AfterRegistrationPage;
