import './UsersList.css';
import React from 'react';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { StatusBadge } from '../StatusBadge/StatusBadge';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { UserSettingsPopover } from './UserSettingsPopover/UserSettingsPopover';
import { ProductsTypeBackPretty } from 'src/constants/products';
import { ROLES_PRETTY } from 'src/constants/roles';
import { UserProjectsToolTip } from 'src/components/UsersList/UserProjectsToolTip/UserProjectsToolTip';

const PRODUCT_COLORS = {
  coagent: '#75F3FB',
  cocustomer: '#A498FF',
  cobi: '#DFF800',
};

const ProductIcon = ({ product, ...rest }) => {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M0 3.46667C0 1.55208 1.55208 0 3.46667 0H22.5333C24.4479 0 26 1.55208 26 3.46667V22.5333C26 24.4479 24.4479 26 22.5333 26H3.46667C1.55208 26 0 24.4479 0 22.5333V3.46667Z"
        fill={`${PRODUCT_COLORS[product]}`}
      />
      <path
        d="M9.85559 8.06899C8.55651 8.06899 7.27903 8.58872 6.36043 9.51384C5.44184 10.439 4.92578 11.6937 4.92578 13.002C4.92578 14.3103 5.44184 15.5651 6.36043 16.4902C7.27903 17.4153 8.55651 17.9351 9.85559 17.9351L9.85559 13.002L9.85559 8.06899Z"
        fill="#05050D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1505 17.9352C18.8732 17.9352 21.0803 15.7266 21.0803 13.0021C21.0803 10.2777 18.8732 8.06909 16.1505 8.06909C13.4279 8.06909 11.2207 10.2777 11.2207 13.0021C11.2207 15.7266 13.4279 17.9352 16.1505 17.9352ZM16.138 15.0178C17.2518 15.0178 18.1547 14.1142 18.1547 12.9997C18.1547 11.8852 17.2518 10.9816 16.138 10.9816C15.0242 10.9816 14.1212 11.8852 14.1212 12.9997C14.1212 14.1142 15.0242 15.0178 16.138 15.0178Z"
        fill="#05050D"
      />
    </svg>
  );
};

export const UsersList = ({ users = [] }) => {
  const isMdDown = useMediaQuery('(max-width: 767px)');
  const isEmpty = !Boolean(users && users.length);
  return isEmpty ? (
    <div className="empty-message">There are currently no users in this account.</div>
  ) : (
    <section className="users-list">
      {isMdDown ? (
        <div className="gap-4 flex flex-col mt-[10px]">
          {users &&
            users.map((user) => {
              return (
                <div
                  key={user.id}
                  className="flex flex-col border-[rgba(184,193,198,0.4)] rounded-[5px] border"
                >
                  <div className="text-sm border-[rgba(184,193,198,0.4)] border-b-[1px] p-3.5">
                    <div className="col name md:text-base text-sm flex justify-between">
                      <div className="flex items-center gap-[8px]">
                        <UserAvatar user={user} />
                        <span className="truncate text-base	font-bold">{`${user.first_name} ${user.last_name}`}</span>
                      </div>
                      <UserSettingsPopover user={user} />
                    </div>
                  </div>
                  <div className="flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-3.5 gap-2.5">
                    <span className="text-grey_text font-medium	text-xs min-w-12">Email:</span>
                    <p className="truncate text-ebony_opacity_70 font-medium">{user.email}</p>
                  </div>
                  {Boolean(user.products.length) && (
                    <div className="flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] py-2.5 px-3.5 gap-2.5">
                      <span className="text-grey_text font-medium	text-xs min-w-12">Product:</span>
                      <p className="truncate text-ebony_opacity_70 font-medium flex flex-row gap-2.5">
                        {user.products.map((product, index) => {
                          return <ProductIcon key={`${product}-${index}`} product={product} />;
                        })}
                      </p>
                    </div>
                  )}
                  {Boolean(user.projects.length) && (
                    <div className="flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] py-2.5 px-3.5 gap-2.5">
                      <span className="text-grey_text font-medium	text-xs min-w-12">Projects:</span>
                      <p className="truncate text-ebony_opacity_70 font-medium">
                        {user.projects.map(({ name }, index) => {
                          return (
                            <span key={`${name}-${index}`}>
                              {name}
                              {index < user.projects.length - 1 && ', '}
                            </span>
                          );
                        })}
                      </p>
                    </div>
                  )}
                  <div className={'flex items-center text-xs p-3.5 gap-2.5'}>
                    <span className="text-grey_text font-medium	text-xs min-w-12">Role:</span>
                    <p className={'text-ebony_opacity_70 font-medium'}>{ROLES_PRETTY[user.role]}</p>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <>
          <Table wrapperClassName="pt-8 -mt-8">
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Projects</TableHead>
                <TableHead>Role:</TableHead>
                <TableHead>Status:</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {users &&
                users.map((user) => {
                  const isActive = user.is_active;
                  const projects = user.projects;

                  return (
                    <TableRow key={user.id}>
                      <TableCell className="font-medium table-fixed max-w-80">
                        <div className="col name md:text-base text-base">
                          <UserAvatar user={user} />
                          <div className={'flex flex-col gap-[3px] overflow-auto'}>
                            <span
                              title={`${user.first_name} ${user.last_name}`}
                              className={'text-ebony font-medium'}
                            >{`${user.first_name} ${user.last_name}`}</span>
                            <span className={'text-xs font-medium text-ebony_opacity_50'}>
                              {user.email}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="max-w-80">
                        <div className="col col-projects">
                          <div className={'flex flex-col gap-[3px]'}>
                            <span className={'text-base font-medium text-ebony'}>
                              {(projects && projects[0] && projects[0].name) || '---'}
                            </span>
                            <span className={'text-xs font-medium text-ebony_opacity_50'}>
                              {(projects &&
                                projects[0] &&
                                ProductsTypeBackPretty[projects[0].project_type]) ||
                                ''}
                            </span>
                          </div>
                          {projects && projects.length > 1 && (
                            <UserProjectsToolTip
                              projects={projects.filter((item, index) => index !== 0)}
                            />
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="col role flex-5 text-base font-medium text-ebony">
                          {ROLES_PRETTY[user.role]}
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="col status flex-5">
                          <StatusBadge isActive={isActive} />
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="col edit flex-5">
                          <UserSettingsPopover user={user} />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </>
      )}
    </section>
  );
};
