import React from 'react';
import { clsx } from 'clsx';
import PageContentWrapper from '../../components/PageContentWrapper/PageContentWrapper';
import CardContainer from '../../components/CardContainer/CardContainer';
import { PlaygroundChat } from 'src/pages/PlaygroundPage/PlaygroundChat/PlaygroundChat';

const PlaygroundPage = () => {
  return (
    <React.Fragment>
      <PageContentWrapper
        className={clsx('playground-page-container h-full')}
        isMobileMarginTop={false}
        projectBreadcrumbsContent={'Integrations'}
      >
        <div className="z-10 gap-[10px] flex flex-row h-full max-h-[85vh]">
          <CardContainer className="flex-1 min-w-1 h-full items-center relative !bg-white">
            <div className="card-body flex flex-auto w-full overflow-hidden !mt-0">
              <PlaygroundChat />
            </div>
          </CardContainer>
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default PlaygroundPage;
