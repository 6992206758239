import React from 'react';

export const HomeIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 16V5.33333L8 0L16 5.33333V16H10V10.5C10 9.39543 9.10457 8.5 8 8.5C6.89543 8.5 6 9.39543 6 10.5V16H0Z"
      fill="white"
      fillOpacity="0.6"
    />
  </svg>
);
