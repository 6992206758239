import './ConversationList.css';
import { ConversationListItem } from 'src/pages/ConversationsPage/ConversationList/ConversationListItem';
import React from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

export const ConversationList = ({
  loadMore,
  isLoading,
  isError,
  hasNextPage,
  chats = [],
  onSelect,
}) => {
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasNextPage,
    onLoadMore: loadMore,
    disabled: !!isError,
    rootMargin: '0px 0px 500px 0px',
  });

  const onClick = (selectedChat) => {
    chats.forEach((chat) => {
      chat.selected = selectedChat === chat;
    });

    onSelect(selectedChat);
  };

  return (
    <>
      <ul className={'overflow-auto h-full'}>
        {chats.map((chat, index) => {
          return (
            <ConversationListItem
              key={chat.id || chat._id || `list-el-${index}`}
              chat={chat}
              onClick={onClick}
            />
          );
        })}
        {(isLoading || hasNextPage) && (
          <li
            ref={sentryRef}
            className={
              'py-4 px-2.5 gap-2 flex flex-row items-center justify-center text-base font-medium text-ebony_opacity_50'
            }
          >
            <div className={'scroll-loader'}></div>
            <div>Loading...</div>
          </li>
        )}
      </ul>
    </>
  );
};
