import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const LinkRenderer = (props) => {
  return (
    <a
      className={'font-semibold text-ebony_opacity_80 underline cursor-pointer hover:text-ebony'}
      href={props.href}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
};

const ULRenderer = (props) => {
  return <ul className={'list-disc pl-6'}>{props.children}</ul>;
};

const OLRenderer = (props) => {
  return <ol className={'list-decimal pl-6'}>{props.children}</ol>;
};

const PRenderer = (props) => {
  return <p className={'break-words'}>{props.children}</p>;
};

const PreRenderer = ({ children }) => {
  return (
    <pre
      className={
        'bg-[#f4f4f4] border border-[#ddd] border-l-2 border-l-purple text-[#666] text-xs whitespace-normal max-w-full overflow-auto py-2 px-2 break-words'
      }
    >
      {children}
    </pre>
  );
};

const CodeRenderer = ({ children }) => {
  return (
    <code className={'break-words px-1 py-[1px] rounded bg-[#CAC3FF] border'}>{children}</code>
  );
};

export const MarkdownRenderer = ({ children, ...props }) => {
  return (
    <ReactMarkdown
      components={{
        p: PRenderer,
        a: LinkRenderer,
        ul: ULRenderer,
        ol: OLRenderer,
        pre: PreRenderer,
        code: CodeRenderer,
      }}
      {...props}
      rehypePlugins={[rehypeRaw]}
    >
      {children}
    </ReactMarkdown>
  );
};
