import { Checkbox } from 'src/components/ui/checkbox';
import React, { useState } from 'react';

export const SelectAllCheckboxKnowledgeBasePage = ({ onChange = () => {} }) => {
  const [checked, setChecked] = useState(false);

  return (
    <>
      <Checkbox
        className={'w-5 h-5'}
        onDoubleClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        checked={checked}
        onCheckedChange={(checked) => {
          setChecked(checked);
          onChange(checked);
        }}
      />
      {checked ? <span>Deselect all</span> : <span>Select all</span>}
    </>
  );
};
