export const getErrorMessage = (error) => {
  if (error?.data?.message) {
    if (Array.isArray(error.data.message)) {
      return error.data.message.join('. ');
    } else {
      return error.data.message;
    }
  } else if (error?.data?.detail) {
    if (Array.isArray(error?.data?.detail)) {
      if (Array.isArray(error.data.detail[0].msg)) {
        return error.data.detail[0].msg[0].msg;
      } else {
        return error.data.detail[0].msg;
      }
    } else {
      return error.data.detail?.msg;
    }
  } else if (error?.message) {
    return error.message;
  } else if (error?.error) {
    return error.error;
  } else {
    return 'Server unavailable';
  }
};
