import React from 'react';

export const ReplyPlaygroundButton = ({ disabled = false, onClick = () => {} }) => {
  return (
    <button
      disabled={disabled}
      className={
        'disabled:bg-[#424250] absolute bottom-4 right-2.5 bg-ebony shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.25)] disabled:hover:shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.25)] p-[7px] rounded-[5px] hover:bg-[#424250] hover:shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.45)]'
      }
      onClick={onClick}
    >
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.625 17.5V12.25L9.625 10.5L2.625 8.75V3.5L19.25 10.5L2.625 17.5Z" fill="white" />
      </svg>
    </button>
  );
};
