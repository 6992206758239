import { ChevronDown } from 'lucide-react';
import React, { useEffect } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { Button } from 'src/components/ui/button';
import { useParams } from 'react-router-dom';
import { useGetKnowledgeBaseByIdQuery } from 'src/services/knowledgeBaseService';
import { Separator } from 'src/components/ui/separator';
import { AddProjectsAiKnowledgeBaseModal } from 'src/pages/AIKnowledgeBasePage/AddProjectsAiKnowledgeBase/AddProjectsAiKnowledgeBaseModal';

export const AssignProjectToKnowledgeBase = () => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [assignedProjectsList, setAssignedProjectsList] = React.useState([]);
  const { data: currentKnowledgeBase } = useGetKnowledgeBaseByIdQuery({ id, load_projects: true });

  useEffect(() => {
    if (currentKnowledgeBase && currentKnowledgeBase.projects) {
      setAssignedProjectsList(currentKnowledgeBase.projects);
    }
  }, [currentKnowledgeBase]);

  return (
    <Popover modal={true} open={open} onOpenChange={(openState) => setOpen(openState)}>
      <PopoverTrigger asChild>
        <div className="flex max-w-[200px] max-h-[45px] h-screen items-center gap-1.5 py-2.5 px-2.5 border rounded border-ebony transition border-opacity-10 [&_p]:hover:opacity-100 hover:border-opacity-100 [&_svg]:hover:opacity-100">
          <p className="text-ebony font-medium opacity-50 text-[13px] transition max-w-40 overflow-hidden overflow-ellipsis text-nowrap">
            Assigned projects
          </p>
          <ChevronDown className="min-w-[12px] w-3" />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="min-w-[120px] shadow transition backdrop-blur rounded-lg w-max h-max px-0 flex flex-col "
      >
        {Boolean(assignedProjectsList && assignedProjectsList.length) && (
          <ul>
            {assignedProjectsList.map((project) => {
              return (
                <li key={project.id}>
                  <div className={'px-5 font-medium text-sm'}>{project.name || 'No Name'}</div>
                  <Separator className={'bg-[#E3E6E8] my-[15px]'} />
                </li>
              );
            })}
          </ul>
        )}
        {currentKnowledgeBase && (
          <div className={'flex items-center justify-center px-5'}>
            <AddProjectsAiKnowledgeBaseModal
              trigger={
                <Button
                  variant="outline"
                  type="button"
                  className="transition duration-300 min-w-24 max-w-48 shadow-md flex flex-fow gap-2 font-medium text-ebony_opacity_80 text-[13px] bg-light hover:shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.45)]"
                >
                  <span>Assign a project</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path d="M9 11H3V9H9V3H11V9H17V11H11V17H9V11Z" fill="#05050D" />
                  </svg>
                </Button>
              }
              knowledgeBase={currentKnowledgeBase}
            />
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};
