import { baseApi } from './base.api';
import { PATH_KNOWLEDGE_BASE_API } from '../constants/api-routes';

export const knowledgeBaseService = baseApi.injectEndpoints({
  endpoints: (build) => {
    return {
      getKnowledgeBase: build.query({
        query: (params) => ({
          url: `${PATH_KNOWLEDGE_BASE_API.ROOT}`,
          params,
        }),
        providesTags: ['KnowledgeBase'],
      }),
      getKnowledgeBaseById: build.query({
        query: ({ id, load_projects = '' }) => ({
          url: `${PATH_KNOWLEDGE_BASE_API.ROOT}/${id}`,
          params: {
            ...(load_projects !== '' && { load_projects }),
          },
        }),
        providesTags: ['KnowledgeBaseById'],
      }),
      getKnowledgeBaseFiles: build.query({
        query: ({ id, sync }) => ({
          url: `${PATH_KNOWLEDGE_BASE_API.ROOT}/${id}/files`,
          params: { sync: sync },
        }),
        providesTags: ['KnowledgeBaseFiles'],
      }),
      addKnowledgeBaseFile: build.mutation({
        query: ({ id, body }) => ({
          url: `${PATH_KNOWLEDGE_BASE_API.ROOT}/${id}/files/file`,
          method: 'POST',
          body: body,
          formData: true,
        }),
        async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
          try {
            const { data: file } = await queryFulfilled;

            dispatch(
              knowledgeBaseService.util.updateQueryData(
                'getKnowledgeBaseFiles',
                { id },
                (draft) => {
                  draft.files = draft.files || [];
                  draft.files.push(file);
                },
              ),
            );
          } catch (err) {
            console.error('Error add knowledge base files:', err);
          }
        },
        invalidatesTags: ['KnowledgeBaseFiles'],
      }),
      addKnowledgeBaseDocument: build.mutation({
        query: ({ id, body }) => ({
          url: `${PATH_KNOWLEDGE_BASE_API.ROOT}/${id}/files/document`,
          method: 'POST',
          body: body,
        }),
        async onQueryStarted({ id, body }, { dispatch, queryFulfilled }) {
          try {
            const { data: file } = await queryFulfilled;

            dispatch(
              knowledgeBaseService.util.updateQueryData(
                'getKnowledgeBaseFiles',
                { id },
                (draft) => {
                  draft.files = draft.files || [];
                  draft.files.push(file);
                },
              ),
            );
          } catch (err) {
            console.error('Error knowledge base document:', err);
          }
        },
        invalidatesTags: ['KnowledgeBaseFiles'],
      }),
      updateKnowledgeBaseFiles: build.mutation({
        query: ({ id, body }) => ({
          url: `${PATH_KNOWLEDGE_BASE_API.ROOT}/${id}/files`,
          method: 'PATCH',
          body: body,
        }),
        invalidatesTags: ['KnowledgeBaseFiles'],
      }),
      deleteKnowledgeBaseFiles: build.mutation({
        query: ({ id, body }) => ({
          url: `${PATH_KNOWLEDGE_BASE_API.ROOT}/${id}/files`,
          method: 'DELETE',
          body: body,
        }),
        async onQueryStarted({ id, body }, { dispatch, queryFulfilled }) {
          try {
            dispatch(
              knowledgeBaseService.util.updateQueryData(
                'getKnowledgeBaseFiles',
                { id },
                (draft) => {
                  draft.files = draft.files.filter((file) => !body.includes(file.id));
                },
              ),
            );
          } catch (err) {
            console.error('Error deleting file:', err);
          }
        },
        invalidatesTags: ['KnowledgeBaseFiles'],
      }),
      createKnowledgeBase: build.mutation({
        query: ({ name }) => ({
          url: `${PATH_KNOWLEDGE_BASE_API.ROOT}`,
          method: 'POST',
          body: {
            name,
          },
        }),
        invalidatesTags: () => [{ type: 'KnowledgeBase' }],
      }),
      deleteKnowledgeBase: build.mutation({
        query: ({ knowledge_base_id }) => ({
          url: `${PATH_KNOWLEDGE_BASE_API.ROOT}/${knowledge_base_id}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => [{ type: 'KnowledgeBase' }],
      }),
      editKnowledgeBaseName: build.mutation({
        query: ({ knowledge_base_id, name }) => ({
          url: `${PATH_KNOWLEDGE_BASE_API.ROOT}/${knowledge_base_id}`,
          method: 'PATCH',
          body: {
            name,
          },
        }),
        async onQueryStarted({ knowledge_base_id, name }, { dispatch, queryFulfilled }) {
          try {
            dispatch(
              knowledgeBaseService.util.updateQueryData('getKnowledgeBase', undefined, (draft) => {
                const currentBase = draft?.items?.find((base) => base.id === knowledge_base_id);
                currentBase.name = name;
              }),
            );
          } catch {}
        },
        invalidatesTags: () => [{ type: 'KnowledgeBase' }],
      }),
      updateKnowledgeBaseProjects: build.mutation({
        query: ({ knowledge_base_id, projects_ids }) => ({
          url: `${PATH_KNOWLEDGE_BASE_API.ROOT}/${knowledge_base_id}/projects`,
          method: 'PUT',
          body: {
            projects_ids,
          },
        }),
        invalidatesTags: () => [{ type: 'KnowledgeBase' }, { type: 'KnowledgeBaseById' }],
      }),
    };
  },
});

export const {
  useGetKnowledgeBaseQuery,
  useGetKnowledgeBaseByIdQuery,
  useCreateKnowledgeBaseMutation,
  useDeleteKnowledgeBaseMutation,
  useEditKnowledgeBaseNameMutation,
  useUpdateKnowledgeBaseProjectsMutation,
  useGetKnowledgeBaseFilesQuery,
  useAddKnowledgeBaseFileMutation,
  useUpdateKnowledgeBaseFilesMutation,
  useDeleteKnowledgeBaseFilesMutation,
  useAddKnowledgeBaseDocumentMutation,
} = knowledgeBaseService;
