export const MenuArrow = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="white" />
      <path
        d="M14 15.9999L10.0001 11.7999L14 8.00001"
        stroke="#05050D"
        strokeWidth="1.79997"
        className={'pointer-events-none'}
      />
    </svg>
  );
};
