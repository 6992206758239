import React, { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';

export const SearchInput = ({
  onChange = () => {},
  className = '',
  inputClassName = '',
  visibleClassName = 'w-full',
}) => {
  const [value, setValue] = useState('');
  const [hidden, setHidden] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
    let timeout;

    if (!hidden && inputRef.current) {
      timeout = setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    } else {
      setValue('');
      onChange('');
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [hidden]);

  const onKeyDown = (e) => {
    if (e.key === 'Escape' && document.activeElement === inputRef.current) {
      setHidden(true);
    }
  };

  const onInputChange = (e) => {
    setValue(e.currentTarget.value);
    onChange(e.currentTarget.value);
  };

  return (
    <div
      className={clsx(
        'shadow-md transition-all ease-in-out duration-300 rounded-[5px] p-2.5 block absolute right-[100px] top-0 bg-white border border-grey_border min-h-[36px]',
        hidden && 'w-8.5 cursor-pointer hover:bg-accent hover:text-accent-foreground',
        !hidden && visibleClassName,
        className,
      )}
      onClick={() => {
        hidden && setHidden(false);
      }}
    >
      <label htmlFor={'searchInput'}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          onClick={() => {
            hidden && setHidden(false);
          }}
          className={clsx('z-10 left-[10px] top-[10px] absolute', hidden && 'cursor-pointer')}
        >
          <path
            opacity="0.8"
            d="M10.2443 8.38143C10.7684 7.52087 11.0455 6.5327 11.0454 5.52513C11.0454 2.47283 8.57561 0.00121739 5.5227 0C2.47252 0.000913043 0 2.47283 0 5.52452C0 8.57348 2.47283 11.0451 5.52452 11.0451C6.57148 11.0451 7.54691 10.7493 8.38052 10.2437L12.1371 14L14 12.1362L10.2443 8.38143ZM5.52391 8.94174C3.63635 8.93717 2.11035 7.41178 2.107 5.52817C2.10829 4.62229 2.46868 3.75387 3.10918 3.11326C3.74968 2.47265 4.61803 2.1121 5.52391 2.11065C7.41056 2.11552 8.93717 3.64091 8.94113 5.52817C8.93717 7.40965 7.40996 8.93717 5.52391 8.94174Z"
            fill="#05050D"
          />
        </svg>
      </label>
      <input
        id="searchInput"
        ref={inputRef}
        value={value}
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        className={clsx(
          'py-1.5 px-2.5 absolute top-0 right-0 pl-[40px] border-0',
          hidden ? 'display-none invisible' : '',
          inputClassName,
        )}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        className={clsx(
          hidden ? 'display-none invisible' : 'block visible',
          'trasition-all absolute top-3 right-3 cursor-pointer group',
        )}
        onClick={() => {
          setHidden(true);
        }}
      >
        <path
          d="M10.275 1.75062C7.935 -0.583541 4.095 -0.583541 1.755 1.75062C-0.585 4.08479 -0.585 7.91521 1.755 10.2494C4.095 12.5835 7.875 12.5835 10.215 10.2494C12.555 7.91521 12.615 4.08479 10.275 1.75062ZM7.695 8.51372L6.015 6.8379L4.335 8.51372L3.495 7.67581L5.175 6L3.495 4.32419L4.335 3.48628L6.015 5.16209L7.695 3.48628L8.535 4.32419L6.855 6L8.535 7.67581L7.695 8.51372Z"
          fill="rgba(5,5,13,0.3)"
          className={'group-hover:fill-[rgba(5,5,13)] transition-all duration-300'}
        />
      </svg>
    </div>
  );
};
