import React, { useEffect } from 'react';
import { clsx } from 'clsx';
import { MenuItem } from 'src/components/Menu/MenuItem';
import './Menu.css';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { useAppSelector } from 'src/store';

export const GeneralItems = ({ isMobile, className, menuItems }) => {
  const { data: userInfo } = useGetUsersMeQuery();
  const { storageMenuOpen } = useAppSelector((s) => s.menuSlices);

  useEffect(() => {}, [isMobile]);
  return (
    <div
      className={clsx(
        className,
        'menu-container border-shark general-menu py-7.5 px-5 min-w-[90px]',
      )}
    >
      {menuItems.map((menuItem) => {
        const isAccess = !!menuItem?.access?.length && !menuItem?.access.includes(userInfo?.role);
        return (
          <div key={menuItem.id}>
            <MenuItem
              isMenuOpen={storageMenuOpen}
              text={menuItem.title}
              icon={menuItem.icon}
              navigateTo={menuItem.navigateTo}
              disabled={menuItem.disabled}
              isPermissionAccess={isAccess}
            />
          </div>
        );
      })}
    </div>
  );
};
