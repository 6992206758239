export const PlaygroundAIIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
    <rect width="44" height="44" rx="7" fill="#05050D" />
    <path
      d="M16.2627 14.0801C14.0642 14.0801 11.9023 14.9596 10.3478 16.5252C8.79325 18.0908 7.91992 20.2142 7.91992 22.4283C7.91992 24.6424 8.79325 26.7658 10.3478 28.3314C11.9023 29.8969 14.0642 30.7765 16.2627 30.7765L16.2627 22.4283L16.2627 14.0801Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.9156 30.7764C31.5232 30.7764 35.2584 27.0388 35.2584 22.4282C35.2584 17.8176 31.5232 14.08 26.9156 14.08C22.3081 14.08 18.5729 17.8176 18.5729 22.4282C18.5729 27.0388 22.3081 30.7764 26.9156 30.7764ZM26.8953 25.8394C28.7802 25.8394 30.3082 24.3104 30.3082 22.4243C30.3082 20.5381 28.7802 19.0091 26.8953 19.0091C25.0103 19.0091 23.4823 20.5381 23.4823 22.4243C23.4823 24.3104 25.0103 25.8394 26.8953 25.8394Z"
      fill="white"
    />
  </svg>
);
