import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';
import { useNotification } from 'src/hooks/useNotification';
import AddButtonIcon from '../../assets/img/add-button-icon.svg';
import Input from 'src/components/Input/Input';
import { useForm, Controller } from 'react-hook-form';
import { useLoader } from 'src/provider/LoaderProvider';
import { useGetUsersAllQuery, useGetUsersMeQuery } from 'src/services/usersService';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  emailScheme,
  stringScheme,
  passwordScheme,
  passwordConfirmScheme,
} from 'src/utils/validation-schemes';
import { object } from 'yup';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { baseApi, useAddProjectTeamMembersMutation, useGetAllProjectsQuery } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useCreateUserMutation } from 'src/services/usersService';
import { ProjectsMultiSelect } from 'src/components/ProjectsMultiSelect/ProjectsMultiSelect';
import { ROLES, ROLES_PRETTY } from 'src/constants/roles';
import { ProductsOrder, ProductsTypeBack } from 'src/constants/products';
import { useAppDispatch } from 'src/store';

export const AddUserPopup = ({ project, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const { data: users } = useGetUsersAllQuery();
  const [roles, setRoles] = useState([
    { label: ROLES_PRETTY.admin, value: ROLES.ADMIN },
    { label: ROLES_PRETTY.manager, value: ROLES.MANAGER },
    { label: ROLES_PRETTY.support_team_lead, value: ROLES.SUPPORT_TEAM_LEAD },
    { label: ROLES_PRETTY.support, value: ROLES.SUPPORT },
  ]);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [
    addTeamMember,
    {
      data: addTeamMemberRes,
      error: errorAddTeamMember,
      isError: isErrorAddTeamMember,
      isLoading: isLoadingAddTeamMember,
      isSuccess: isSuccessAddTeamMember,
    },
  ] = useAddProjectTeamMembersMutation();
  const [
    createUser,
    {
      data: createUserData,
      isSuccess: isSuccessCreateUser,
      error: errorCreateUser,
      isError: isErrorCreateUser,
      isLoading: isLoadingCreateUser,
    },
  ] = useCreateUserMutation();
  const { data: userInfo } = useGetUsersMeQuery();
  const { data: projects = [] } = useGetAllProjectsQuery();

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password2: '',
      role: '',
    },
    resolver: yupResolver(
      object({
        email: emailScheme({ required: true }).label('Email'),
        first_name: stringScheme({ required: true }).label('First name'),
        last_name: stringScheme().label('Last name'),
        password: passwordScheme({
          required: true,
          min: 3,
          max: 20,
          minMessage: 'Min. 3 characters',
        }).label('Password'),
        password2: passwordConfirmScheme({
          required: true,
          min: 3,
          max: 20,
          minMessage: 'Min. 3 characters',
        }).label('Confirm password'),
        role: object({
          label: stringScheme({ required: true }),
        }).typeError('Role is required'),
      }),
    ),
  });
  const loader = useLoader();

  useEffect(() => {
    if (users) {
      const unassignedUsers = users.filter((user) => {
        return !project?.team_members?.find((teamMember) => teamMember.user.id === user.id);
      });
    }
  }, [users]);

  useEffect(() => {
    if (projects && projects.length) {
      const clone = [...projects];

      clone.sort((a, b) => {
        return ProductsOrder[a.project_type] - ProductsOrder[b.project_type];
      });

      setSortedProjects(clone);
    }
  }, [projects]);

  useEffect(() => {
    if (isSuccessAddTeamMember) {
      notification.success(`Team Member ${addTeamMemberRes.user.first_name} was added.`);
    }
  }, [isSuccessAddTeamMember]);

  useEffect(() => {
    if (isLoadingAddTeamMember) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingAddTeamMember]);

  useEffect(() => {
    if (isErrorAddTeamMember) {
      notification.error(getErrorMessage(errorAddTeamMember));
    }
  }, [isErrorAddTeamMember]);

  useEffect(() => {
    if (isErrorCreateUser) {
      notification.error(getErrorMessage(errorCreateUser));
    }
  }, [isErrorCreateUser]);

  useEffect(() => {
    (async () => {
      if (isSuccessCreateUser) {
        if (selectedProjects.length) {
          for (let i = 0; i < selectedProjects.length; i++) {
            await addTeamMember({
              id: selectedProjects[i].id,
              user: createUserData.id,
              type: ProductsTypeBack[selectedProjects[i].project_type],
            });
          }
        }
      }

      dispatch(baseApi.util.invalidateTags(['UsersAll']));
      reset();
      setOpen(false);
    })();
  }, [isSuccessCreateUser]);

  useEffect(() => {
    if (isLoadingCreateUser) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingCreateUser]);

  useEffect(() => {
    if (userInfo?.role !== ROLES.COSSUPORT_MANAGER) {
      setRoles(roles.filter((role) => role.value !== ROLES.COSSUPORT_MANAGER));
    }
  }, [userInfo]);

  const submit = (data) => {
    const request = {
      email: data.email,
      password: data.password,
      password2: data.password2,
      first_name: data.first_name,
      last_name: data.last_name,
      role: data.role.value || ROLES.SUPPORT,
    };

    createUser(request);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(disabled && 'pointer-events-none opacity-50', 'cursor-pointer')}
      >
        <Button className="transparent">
          <span>Add user</span>
          <img src={AddButtonIcon} />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add user</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="content">
            <form id="add-user-form" onSubmit={handleSubmit(submit)}>
              <div className={'input-container'}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type={'email'}
                      label={'Email'}
                      placeholder={'email@email.com'}
                      invalidMessage={error?.message}
                      value={value}
                      isValid={!error?.message}
                      onChange={onChange}
                      onBlur={() => trigger('email')}
                    />
                  )}
                />
              </div>
              <div className={'input-container'}>
                <Controller
                  control={control}
                  name="first_name"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      autoFocus
                      type={'text'}
                      label={'First Name'}
                      placeholder={'Josh'}
                      invalidMessage={error?.message}
                      value={value}
                      isValid={!error?.message}
                      onChange={onChange}
                      onBlur={() => trigger('firstName')}
                    />
                  )}
                />
              </div>
              <div className={'input-container'}>
                <Controller
                  control={control}
                  name="last_name"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type={'text'}
                      label={'Last Name'}
                      placeholder={'Brawn'}
                      invalidMessage={error?.message}
                      value={value}
                      isValid={!error?.message}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className={'input-container'}>
                <ProjectsMultiSelect
                  label={'Project'}
                  projects={sortedProjects}
                  onChange={(selected) => {
                    setSelectedProjects(selected);
                  }}
                />
              </div>
              <div className={'input-container'}>
                <Controller
                  control={control}
                  name="password"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type={'password'}
                      label={'Password'}
                      placeholder={'••••••••••••••••••••••••••••'}
                      invalidMessage={error?.message}
                      value={value}
                      isValid={!error?.message}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className={'input-container'}>
                <Controller
                  control={control}
                  name="password2"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type={'password'}
                      label={'Confirm Password'}
                      placeholder={'••••••••••••••••••••••••••••'}
                      invalidMessage={error?.message}
                      value={value}
                      isValid={!error?.message}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className={'input-container'}>
                <Controller
                  control={control}
                  name="role"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type={'select'}
                      label={'Role'}
                      placeholder={'Admin'}
                      invalidMessage={error?.message}
                      value={value}
                      selectOptions={roles}
                      isValid={!error?.message}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className={'input-container buttons'}>
                <Button type="submit" form="add-user-form" className="blue">
                  Add User
                </Button>
              </div>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
