import React from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import Input from 'src/components/Input/Input';
import { Button } from 'src/components/ui/button';
import { getFileExtension } from 'src/utils/fileUtils';
import { KNOWLEDGE_BASE_FILE_EXTENSIONS } from 'src/shared/constants';

export const EditKnowledgeBaseItemForm = ({
  close = () => {},
  handleSingleItemEdit = () => {},
  item,
}) => {
  const itemExt = getFileExtension(item.name);
  const notification = useNotification();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: {
      name: item?.name.trim() || '',
    },
    resolver: yupResolver(
      object({
        name: stringScheme({
          required: true,
          minLength: 3,
        })
          .label('Name')
          .test('extension', `File Extension should be .${itemExt}`, (value) => {
            const ext = getFileExtension(value);

            return item.type === 'file' && itemExt ? itemExt === ext : true;
          }),
      }),
    ),
  });

  const submit = ({ name = '' }) => {
    name = name.trim();
    const ext = getFileExtension(name);

    if (item.type === 'folder') {
      handleSingleItemEdit(name, item);
      close();

      return;
    }

    if (ext) {
      const allowedExtension = KNOWLEDGE_BASE_FILE_EXTENSIONS.includes(`.${ext}`);

      name = allowedExtension ? name : name.replace(`.${ext}`, '.txt');
    } else {
      name = `${name}.txt`;
    }

    handleSingleItemEdit(name, item);
    close();
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div
        className="content"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              placeholder={'New Name'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              className={'!border-[#E3E6E8] focus:!border-ebony_opacity_30'}
            />
          )}
        />
      </div>
      <div className="flex justify-center mt-5">
        <div className="flex gap-2.5">
          <Button variant="outline" type="button" className="min-w-24 shadow-md" onClick={close}>
            Cancel
          </Button>
          <Button disabled={!isDirty} type="submit" className="min-w-24 shadow-md">
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};
