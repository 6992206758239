import React, { useEffect } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, number, object } from 'yup';
import { getErrorMessage } from 'src/utils/get-error-message';
import { Button } from 'src/components/ui/button';
import { useUpdateKnowledgeBaseProjectsMutation } from 'src/services/knowledgeBaseService';
import { Checkbox } from 'src/components/ui/checkbox';
import { useGetAllProjectsQuery } from 'src/services';
import { Separator } from 'src/components/ui/separator';

export const AddProjectsAiKnowledgeBaseForm = ({ close = () => {}, knowledgeBase }) => {
  const notification = useNotification();

  const { data: projects } = useGetAllProjectsQuery();

  const [
    updateKnowledgeBaseProjects,
    {
      isLoading: updateKnowledgeBaseProjectsIsLoading,
      isError: updateKnowledgeBaseProjectsIsError,
      error: updateKnowledgeBaseProjectsBaseError,
      isSuccess: updateKnowledgeBaseProjectsIsSuccess,
    },
  ] = useUpdateKnowledgeBaseProjectsMutation();

  useEffect(() => {
    if (updateKnowledgeBaseProjectsIsSuccess) {
      close();
      notification.success('Knowledge Base projects was successfully updated!');
    }
  }, [updateKnowledgeBaseProjectsIsLoading, updateKnowledgeBaseProjectsIsSuccess]);

  useEffect(() => {
    if (updateKnowledgeBaseProjectsIsError) {
      notification.error(getErrorMessage(updateKnowledgeBaseProjectsBaseError));
    }
  }, [updateKnowledgeBaseProjectsIsError]);

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
    watch,
  } = useForm({
    defaultValues: {
      project_items:
        knowledgeBase?.projects.map((item) => {
          return { project_id: item.id };
        }) || [],
    },
  });

  const submit = (data) => {
    updateKnowledgeBaseProjects({
      knowledge_base_id: knowledgeBase.id,
      projects_ids: data.project_items.map((item) => item.project_id),
    });
  };

  const activateSubscriptionCheckBoxHandler = (checked, onChange, value, project) => {
    checked
      ? onChange([
          ...value,
          {
            project_id: Number(project.id),
          },
        ])
      : onChange(value?.filter((value) => value.project_id !== project.id));
  };

  const projectsByType = projects?.reduce(
    (acc, project) => {
      if (!acc[project?.project_type]) {
        acc[project.project_type] = [];
      }
      acc[project?.project_type].push(project);
      return acc;
    },
    { agent: [], bi: [], customer: [] },
  );

  const checkedProjects = watch('project_items');

  return (
    <form
      id="add-subscription-plan-form"
      onSubmit={handleSubmit(submit)}
      className={'flex flex-col'}
    >
      <div className={'input-container flex flex-col relative max-h-[60vh] overflow-auto'}>
        <Controller
          control={control}
          name={'project_items'}
          render={({ field: { onChange, value } }) =>
            [...projectsByType.customer, ...projectsByType.bi].map((project, index, array) => {
              return (
                <div key={project.id} className="flex w-full flex-col">
                  <label
                    htmlFor={`sub-${project.id}`}
                    className="flex items-center space-x-2 py-[15px] cursor-pointer"
                  >
                    <Checkbox
                      id={`sub-${project.id}`}
                      checked={value?.some((el) => el.project_id === project.id)}
                      onCheckedChange={(checked) => {
                        activateSubscriptionCheckBoxHandler(checked, onChange, value, project);
                      }}
                    />
                    <span className="text-base text-ebony opacity-50 cursor-pointer font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                      {project?.name}
                    </span>
                  </label>
                  {index < array.length - 1 && <Separator />}
                </div>
              );
            })
          }
        />
        {errors?.project_id?.message && (
          <p className="text-sm font-semibold text-error absolute top-full mt-3">
            {errors?.project_id?.message}
          </p>
        )}
      </div>
      <div className="flex justify-center mt-5">
        <div className="flex gap-2.5">
          <Button variant="outline" type="button" className="min-w-24 shadow-md" onClick={close}>
            Cancel
          </Button>
          <Button
            disabled={!isDirty || updateKnowledgeBaseProjectsIsLoading}
            type="submit"
            className="min-w-24 shadow-md"
          >
            {updateKnowledgeBaseProjectsIsLoading
              ? 'Loading...'
              : !isDirty || updateKnowledgeBaseProjectsIsLoading
                ? 'Assign'
                : checkedProjects.length
                  ? 'Assign'
                  : 'Disconnect'}
          </Button>
        </div>
      </div>
    </form>
  );
};
