import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { AddKnowledgeBaseFolderForm } from './AddKnowledgeBaseFolderForm';
import { AddFolderKnowledgeBaseIcon } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseIcons/CurrentKnowledgeBaseIcons';
import { Button } from 'src/components/ui/button';

export const AddKnowledgeBaseFolderModal = ({ disabled = false, handleAddFolderItem, item }) => {
  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        <Button
          variant="outline"
          type="button"
          className="min-w-24 shadow-md flex flex-fow gap-2 text-ebony_opacity_80 text-[13px]"
        >
          <span>Add Folder</span>
          <AddFolderKnowledgeBaseIcon />
        </Button>
      </DialogTrigger>
      <DialogContent closeButtonHidden>
        <DialogHeader>
          <DialogTitle>{'New folder'}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <AddKnowledgeBaseFolderForm
            handleAddFolderItem={handleAddFolderItem}
            close={closePopup}
            item={item}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
