import { Separator } from 'src/components/ui/separator';
import { MARKDOWN_TO_URL_REGEX } from 'src/shared/constants';

const filterUnique = (sources) => {
  const object = {};

  sources.forEach((value) => {
    object[value.name] = value;
  });

  return Object.keys(object).map((value) => {
    return object[value];
  });
};

export const ConversationSourceList = ({ sources }) => {
  const uniqueSources = filterUnique(sources);

  return (
    <div className={'flex flex-col gap-[15px] pt-[15px]'}>
      <Separator className={'bg-[#0000000d]'} />
      <ul className={'flex flex-col gap-[5px]'}>
        {uniqueSources.map((source, index) => {
          let url = source.url;

          if (url) {
            url = url.replace(MARKDOWN_TO_URL_REGEX, '$2');
          }

          return (
            <li className={'list-none text-ebony_opacity_40'} key={`${source.name}-${index}`}>
              {url ? (
                <a
                  className={'hover:text-ebony underline'}
                  href={url}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  {source.name} →
                </a>
              ) : (
                <span>{source.name}</span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
