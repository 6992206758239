import './AIKnowledgeBasePage.css';
import React, { useEffect, useState } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import PageContentWrapper from 'src/components/PageContentWrapper/PageContentWrapper';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { clsx } from 'clsx';
import { getErrorMessage } from 'src/utils/get-error-message';
import { SearchFilesContainer } from 'src/components/FileManager/SearchFilesContainer/SearchFilesContainer';
import { KnowledgeBaseTableList } from 'src/pages/AIKnowledgeBasePage/KnowledgeBaseTableList';
import { useGetKnowledgeBaseQuery } from 'src/services/knowledgeBaseService';
import { CreateEditAiKnowledgeBaseModal } from 'src/pages/AIKnowledgeBasePage/CreateEditAiKnowledgeBase/CreateEditAiKnowledgeBaseModal';
import { useDebounce } from 'src/hooks/useDebounce';
import { PaginationComponent } from 'src/components/Pagination/PaginationComponent';
import { usePagination } from 'src/hooks/usePagination';

const AIKnowledgeBasePage = () => {
  const notification = useNotification();
  const loader = useLoader();
  const isLgDown = useMediaQuery('(max-width: 992px)');

  const [filteredValue, setFilteredValue] = useState('');
  const { onChangeRowsPerPage, onChangePage, pagination, onSearch } = usePagination();

  const searchNameDebounce = useDebounce(filteredValue, 500);

  const {
    data: knowledgeBaseData,
    error: errorKnowledgeBaseData,
    isError: isErrorKnowledgeBaseData,
    isLoading: isLoadingKnowledgeBaseData,
    isFetching: isFetchingKnowledgeBaseData,
  } = useGetKnowledgeBaseQuery(
    {
      load_projects: true,
      name_filter: searchNameDebounce,
      page: searchNameDebounce ? '1' : pagination.page,
      page_size: pagination.rowsPerPage,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (isErrorKnowledgeBaseData) {
      notification.error(getErrorMessage(errorKnowledgeBaseData));
    }
  }, [isErrorKnowledgeBaseData]);

  useEffect(() => {
    if (isLoadingKnowledgeBaseData) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingKnowledgeBaseData]);

  const onHandleSearch = (value) => {
    setFilteredValue(value);
  };

  return (
    <React.Fragment>
      <PageContentWrapper
        className={clsx('knowledge-base-page-container', !isLgDown && 'relative')}
        isMobileMarginTop={false}
        isProjectBreadcrumbs={true}
        projectBreadcrumbsContent={'Knowledge Base'}
      >
        <div className="z-10 gap-[10px] flex flex-col relative h-[100%]">
          <CardContainer className="gap-[16px]">
            <div className="flex w-full justify-end gap-2.5">
              <SearchFilesContainer onSearch={onHandleSearch} hideSort hideViewType />
              <CreateEditAiKnowledgeBaseModal />
            </div>
            <div
              className={clsx(
                (isLoadingKnowledgeBaseData || isFetchingKnowledgeBaseData) &&
                  'animate-pulse blur-sm pointer-events-none',
              )}
            >
              <KnowledgeBaseTableList
                isLoadingKnowledgeBaseData={isLoadingKnowledgeBaseData}
                knowledgeBaseData={knowledgeBaseData?.items}
                filteredValue={filteredValue}
              />
            </div>
            <div className="w-full flex justify-end items-center">
              {Boolean(knowledgeBaseData?.items?.length) && (
                <PaginationComponent
                  rowsPerPageOptions={[5, 10, 15]}
                  count={knowledgeBaseData?.page_info?.total_items}
                  rowsPerPage={pagination.rowsPerPage}
                  currentPage={pagination.page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />
              )}
            </div>
          </CardContainer>
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default AIKnowledgeBasePage;
