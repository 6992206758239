import { baseSsoApi } from 'src/services/base.sso.api';
import { SSO_REGISTRATION_CONFIRM } from '../constants/api-routes';

export const ssoService = baseSsoApi.injectEndpoints({
  endpoints: (build) => ({
    registrationConfirm: build.mutation({
      query: ({ token }) => ({
        url: `${SSO_REGISTRATION_CONFIRM}`,
        method: 'POST',
        params: { ...(token !== '' && { token }) },
      }),
    }),
  }),
});

export const { useRegistrationConfirmMutation } = ssoService;
