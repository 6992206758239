import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import {
  DeleteItemAttentionIcon,
  DeleteItemsKnowledgeBaseIcon,
} from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseIcons/CurrentKnowledgeBaseIcons';
import { Button } from 'src/components/ui/button';

export const DeleteKnowledgeBaseItemsModal = ({ disabled = false, handleItemsDelete }) => {
  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setOpen(false);
  };

  const onClick = () => {
    handleItemsDelete();
    closePopup();
  };

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        <Button
          variant="outline"
          type="button"
          className="border-0 px-3.5 py-[9px] gap-2 bg-delete hover:bg-[#d44141] text-white hover:text-white shadow-[0px_2px_2px_0px_rgba(162,_16,_16,_0.25)]"
        >
          Delete
          <DeleteItemsKnowledgeBaseIcon color={'#ffffff'} />
        </Button>
      </DialogTrigger>
      <DialogContent closeButtonHidden>
        <DialogHeader>
          <DialogTitle>Delete content</DialogTitle>
        </DialogHeader>
        <div className="flex flex-row gap-4 pt-4">
          <div className={'flex justify-center items-center'}>
            <DeleteItemAttentionIcon />
          </div>
          <div>
            <p className={'text-delete font-medium text-base'}>
              Are you sure you want to delete these sources irretrievably?
            </p>
          </div>
        </div>
        <div className="flex justify-center mt-5">
          <div className="flex gap-2.5">
            <Button
              variant="outline"
              type="button"
              className="min-w-24 shadow-md"
              onClick={closePopup}
            >
              Cancel
            </Button>
            <Button className="min-w-24 shadow-md" onClick={onClick}>
              Delete
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
