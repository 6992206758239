export const PASSWORD_RESET_DONE_URL = '/v1/users/password-reset-done';
export const REGISTRATION_URL = 'v1/auth/registration';
export const LOGIN_URL = 'v1/auth/token';
export const LOGIN_BY_GOOGLE_URL = 'v1/auth/google';
export const LOGOUT_URL = 'v1/auth/logout';
export const REFRESH_TOKEN_URL = 'v1/auth/token/refresh';
export const USERS_ME_URL = 'v1/users/me';
export const USERS_ALL_URL = 'v1/users/all';
export const USERS_CREATE_URL = 'v1/users/create';
export const USER_UPDATE_URL = 'v1/users/{user_id}/update';
export const INTEGRATION_SETTING_POPUP_ID = 'INTEGRATION_SETTING_POPUP_ID';
export const LATEST_VIEWED_PROJECT_ID = 'LATEST_VIEWED_PROJECT_ID';
export const EMAIL_REGEX = /^([a-zA-Z0-9._%-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
export const KNOWLEDGE_BASE_FILE_EXTENSIONS = ['.txt', '.pdf', '.md'];
export const ERROR_MESSAGE_FRIENDLY =
  'Yikes! It seems our server is taking a coffee break. Please refresh the page.';
export const ERROR_MESSAGE_CONTACT =
  'Something went wrong. Please contact our system administrator.';
export const URL_REGEX =
  /('https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)')/g;
export const MARKDOWN_TO_URL_REGEX = /\[(.*?)\]\((.*?)\)/gim;
