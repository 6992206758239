import React, { Fragment, useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import PageContentWrapper from 'src/components/PageContentWrapper/PageContentWrapper';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { useParams } from 'react-router-dom';
import {
  useAddKnowledgeBaseDocumentMutation,
  useAddKnowledgeBaseFileMutation,
  useDeleteKnowledgeBaseFilesMutation,
  useGetKnowledgeBaseByIdQuery,
  useGetKnowledgeBaseFilesQuery,
  useUpdateKnowledgeBaseFilesMutation,
} from 'src/services/knowledgeBaseService';
import { getErrorMessage } from 'src/utils/get-error-message';
import {
  addFolderToEmptyFolders,
  createFile,
  createFolder,
  filterBranchByName,
  findActiveBranch,
  getCheckedFiles,
  getFoldersStructure,
  getRootFolder,
  mergeWithEmptyFolders,
  removeEmptyFolders,
  setCheckedChildren,
  sortByDate,
  sortByName,
  sortByType,
  updateBranchInTree,
  updateCheckedPaths,
} from 'src/utils/fileUtils';
import { getKnowledgeBaseConfig, setKnowledgeBaseConfig } from 'src/utils/pageStorageConfig';
import { KNOWLEDGE_BASE_FILE_EXTENSIONS } from 'src/shared/constants';
import { CurrentKnowledgeBaseTableList } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseTableList/CurrentKnowledgeBaseTableList';
import { Button } from 'src/components/ui/button';
import {
  DeleteItemsKnowledgeBaseIcon,
  MoveItemIcon,
  MoveItemsKnowledgeBaseIcon,
} from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseIcons/CurrentKnowledgeBaseIcons';
import { SearchInput } from 'src/components/SearchInput/SearchInput';
import { AddKnowledgeBaseSourceModal } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/AddKnowledgeBaseSourceModal/AddKnowledgeBaseSourceModal';
import { AddKnowledgeBaseFolderModal } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/AddKnowledgeBaseFolderModal/AddKnowledgeBaseFolderModal';
import { FiltersKnowledgeBaseModal } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/FiltersKnowledgeBaseModal/FiltersKnowledgeBaseModal';
import { DeleteKnowledgeBaseItemsModal } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/DeleteKnowledgeBaseItemsModal/DeleteKnowledgeBaseItemsModal';
import { MoveKnowledgeBaseItemModal } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/MoveKnowledgeBaseItemModal/MoveKnowledgeBaseItemModal';
import { CurrentKnowledgeBaseBreadcrumb } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseBreadcrumb/CurrentKnowledgeBaseBreadcrumb';
import { SelectAllCheckboxKnowledgeBasePage } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseTableList/SelectAllCheckboxKnowledgeBase';

const getInitialTree = () => {
  return {
    name: 'Knowledge Base',
    type: 'folder', // can be 'folder', 'file'
    path: '/',
    root: true,
    parent: false,
    children: [],
  };
};

export const CurrentKnowledgeBasePage = () => {
  const initialTree = getInitialTree();
  const notification = useNotification();
  const loader = useLoader();
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const { id: knowledgeBaseId } = useParams();
  const [dragStarted, setDragStarted] = useState(false);
  const [sync, setSync] = useState(false);
  const [files, setFiles] = useState(false);
  const [tree, setTree] = useState(initialTree);
  const [knowledgeBaseName, setKnowledgeBaseName] = useState('Knowledge Base');
  const [activeBranch, setActiveBranch] = useState(initialTree);
  const [selectedItem, setSelectedItem] = useState(activeBranch);
  const inputFile = useRef(null);
  const [filteredValue, setFilteredValue] = useState('');
  const [refetchState, setRefetchState] = useState(false);
  const [moveContentMode, setMoveContentMode] = useState(false);
  const [deleteContentMode, setDeleteContentMode] = useState(false);
  const [checkedItemsCount, setCheckedItemsCount] = useState(0);
  const [checkedFilesCount, setCheckedFilesCount] = useState(0);

  const {
    data: currentKnowledgeBase,
    isError: isErrorKnowledgeBase,
    error: errorKnowledgeBase,
  } = useGetKnowledgeBaseByIdQuery({ id: knowledgeBaseId, load_projects: true });
  const {
    data: filesResponse,
    error: errorFiles,
    isError: isErrorFiles,
    isLoading: isLoadingFiles,
    isSuccess: isSuccessFiles,
    refetch: refetchFiles,
  } = useGetKnowledgeBaseFilesQuery(
    {
      id: knowledgeBaseId,
      sync,
    },
    { skip: !knowledgeBaseId },
  );
  const [
    addKnowledgeBaseFile,
    {
      error: errorAddKnowledgeBaseFile,
      isError: isErrorAddKnowledgeBaseFile,
      isSuccess: isSuccessAddKnowledgeBaseFile,
    },
  ] = useAddKnowledgeBaseFileMutation();
  const [
    addKnowledgeBaseDocument,
    {
      error: errorAddKnowledgeBaseDocument,
      isError: isErrorAddKnowledgeBaseDocument,
      isSuccess: isSuccessAddKnowledgeBaseDocument,
    },
  ] = useAddKnowledgeBaseDocumentMutation();
  const [
    deleteKnowledgeBaseFiles,
    { error: errorDeleteKnowledgeBaseFiles, isError: isErrorDeleteKnowledgeBaseFiles },
  ] = useDeleteKnowledgeBaseFilesMutation();
  const [
    updateKnowledgeBaseFiles,
    {
      error: errorUpdateKnowledgeBaseFiles,
      isError: isErrorUpdateKnowledgeBaseFiles,
      isSuccess: isSuccessUpdateKnowledgeBaseFiles,
    },
  ] = useUpdateKnowledgeBaseFilesMutation();

  useEffect(() => {
    if (currentKnowledgeBase) {
      setKnowledgeBaseName(currentKnowledgeBase.name);
      tree.name = currentKnowledgeBase.name;
    }
  }, [currentKnowledgeBase]);

  useEffect(() => {
    if (filesResponse) {
      setFiles(filesResponse);
    }
  }, [filesResponse]);

  useEffect(() => {
    let timeout;

    if (files) {
      const deletingExist = files.some((file) => file.state === 'deleting');
      const uploadingExist = files.some((file) => file.state === 'uploading');

      if (deletingExist || uploadingExist) {
        setRefetchState(true);
        timeout = setTimeout(async () => {
          const { isError, error } = await refetchFiles();

          if (isError) {
            notification.error(getErrorMessage(error));
          }

          setRefetchState(false);
        }, 1500);
      }
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [files, refetchState]);

  useEffect(() => {
    if (isSuccessAddKnowledgeBaseFile) {
      notification.success('New Files Added Successfully.');
    }
  }, [isSuccessAddKnowledgeBaseFile]);

  useEffect(() => {
    if (isSuccessAddKnowledgeBaseDocument) {
      notification.success('New Document Added Successfully.');
    }
  }, [isSuccessAddKnowledgeBaseDocument]);

  useEffect(() => {
    if (isSuccessUpdateKnowledgeBaseFiles) {
      notification.success('Updated Successfully.');
    }
  }, [isSuccessUpdateKnowledgeBaseFiles]);

  useEffect(() => {
    if (isErrorKnowledgeBase) {
      notification.error(getErrorMessage(errorKnowledgeBase));
    }
  }, [isErrorKnowledgeBase]);

  useEffect(() => {
    if (isErrorAddKnowledgeBaseFile) {
      notification.error(getErrorMessage(errorAddKnowledgeBaseFile));
    }
  }, [isErrorAddKnowledgeBaseFile]);

  useEffect(() => {
    if (isErrorAddKnowledgeBaseDocument) {
      notification.error(getErrorMessage(errorAddKnowledgeBaseDocument));
    }
  }, [isErrorAddKnowledgeBaseDocument]);

  useEffect(() => {
    if (isErrorFiles) {
      notification.error(getErrorMessage(errorFiles));
    }
  }, [isErrorFiles]);

  useEffect(() => {
    if (isErrorDeleteKnowledgeBaseFiles) {
      notification.error(getErrorMessage(errorDeleteKnowledgeBaseFiles));
    }
  }, [isErrorDeleteKnowledgeBaseFiles]);

  useEffect(() => {
    if (isErrorUpdateKnowledgeBaseFiles) {
      notification.error(getErrorMessage(errorUpdateKnowledgeBaseFiles));
    }
  }, [isErrorDeleteKnowledgeBaseFiles]);

  useEffect(() => {
    if (isLoadingFiles) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingFiles]);

  useEffect(() => {
    if (!files) {
      return;
    }

    if (files.length) {
      const foldersStructure = getFoldersStructure(files);
      const branch = findActiveBranch(foldersStructure);

      setTree(foldersStructure);
      updateActiveBranch(branch || foldersStructure);
    } else {
      const emptyTree = getInitialTree();

      setTree(emptyTree);
      updateActiveBranch(emptyTree);
    }
  }, [files]);

  useEffect(() => {
    if (!activeBranch.isTemporary && isSuccessFiles) {
      const config = getKnowledgeBaseConfig();

      config.activeBranchPath = activeBranch.path;
      setKnowledgeBaseConfig(config);
    }
  }, [activeBranch]);

  const updateActiveBranch = (branch) => {
    loader.show();
    mergeWithEmptyFolders(branch);

    const { sortBy } = getKnowledgeBaseConfig();

    switch (sortBy) {
      case 'type':
        sortByType(branch.children, 'folder');
        break;
      case 'name':
        sortByName(branch.children);
        break;
      case 'date':
        sortByDate(branch.children);
        break;
      default:
        sortByType(branch.children, 'folder');
        break;
    }

    setActiveBranch(branch);
    setSelectedItem(branch);
    loader.hide();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newFiles = uploadFiles(event.dataTransfer.files);

    addFilesToBranch(newFiles, activeBranch);
    setDragStarted(false);
  };

  const addFilesToBranch = (files, branch) => {
    const newBranch = {
      ...branch,
    };

    files.forEach((file) => {
      const fileItem = createFile(file, newBranch);

      newBranch.children.push(fileItem);
    });

    sortByType(newBranch.children, 'folder');
    updateBranchInTree(tree, branch, newBranch);

    if (branch === activeBranch) {
      updateActiveBranch(newBranch);
    }
  };

  const onFileChange = async (event) => {
    if (!event.target.files.length) {
      return;
    }

    const newFiles = uploadFiles(event.target.files);

    addFilesToBranch(newFiles, activeBranch);
  };

  const uploadFiles = (files) => {
    const newFiles = [];

    loader.show({ progress: true });

    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        const request = new FormData();
        const filePath = activeBranch.path || '';

        request.append('file', file, file.name);
        request.append('file_path', filePath);

        const response = addKnowledgeBaseFile({
          id: knowledgeBaseId,
          body: request,
        });

        loader.updateProgress(((i + 1) / files.length) * 100);

        newFiles.push({
          name: file.name,
          path: filePath,
          state: 'uploading',
        });

        response.then(({ error, data }) => {
          if (error) {
            const children = activeBranch.children.filter(
              (child) => !(child.name === file.name && child.state === 'uploading'),
            );
            const newActiveBranch = { ...activeBranch, children };

            updateBranchInTree(tree, activeBranch, newActiveBranch);
            updateActiveBranch(newActiveBranch);

            return;
          }

          const children = activeBranch.children.map((child) => {
            const childPath = child.path.replace(/\/\//, '/');
            const path = `${data.path || ''}/${data.name}`.replace(/\/\//, '/');

            if (child.name === file.name && childPath === path) {
              return {
                ...child,
                ...data,
                path,
              };
            }

            return child;
          });
          const newActiveBranch = { ...activeBranch, children };

          updateBranchInTree(tree, activeBranch, newActiveBranch);
          updateActiveBranch(newActiveBranch);
        });
      }
    }

    loader.hide();

    return newFiles;
  };

  const handleAddFolderItem = (folderName) => {
    const folder = createFolder(folderName, activeBranch);
    const newActiveBranch = {
      ...activeBranch,
    };

    newActiveBranch.children.push(folder);
    updateBranchInTree(tree, activeBranch, newActiveBranch);
    updateActiveBranch(newActiveBranch);
  };

  const addKnowledgeBaseSource = (source, data) => {
    switch (source.type) {
      case 'file':
        inputFile.current.click();
        break;
      case 'faq':
        handleAddKnowledgeBaseDocument(data);
        break;
    }
  };

  const handleAddKnowledgeBaseDocument = async (data) => {
    const newFiles = [];

    const response = await addKnowledgeBaseDocument({
      id: knowledgeBaseId,
      body: {
        name: data.question,
        text: data.answer,
        path: activeBranch.path,
      },
    });

    if (!response.data.status) {
      newFiles.push(response.data);
    }

    newFiles.length && addFilesToBranch(newFiles, activeBranch);
  };

  const handleItemsDelete = async (files) => {
    const tree = getRootFolder(activeBranch);
    const filesToDelete = files || getCheckedFiles(tree);
    const request = filesToDelete.filter((file) => file.type !== 'folder').map((file) => file.id);

    if (filesToDelete.length && !request.length) {
      const newActiveBranch = { ...activeBranch };

      newActiveBranch.children = newActiveBranch.children.filter((child) => {
        return !child.checked;
      });

      removeEmptyFolders(filesToDelete);
      updateActiveBranch(newActiveBranch);
      updateBranchInTree(tree, activeBranch, newActiveBranch);
      resetCheckedItems(tree);
      setDeleteContentMode(false);
      return;
    }

    if (request.length) {
      loader.show();
      await deleteKnowledgeBaseFiles({
        id: knowledgeBaseId,
        body: request,
      });
      loader.hide();
    }
    cancelCheckboxMode();
  };

  const handleMoveItems = async (moveToFolder) => {
    const tree = getRootFolder(activeBranch);
    const checkedFiles = getCheckedFiles(tree);
    const filesWithUpdatedPaths = updateCheckedPaths(checkedFiles, moveToFolder.path);
    const request = filesWithUpdatedPaths
      .filter((file) => file.type !== 'folder')
      .map((file) => {
        return {
          id: file.id,
          path: file.newPath.replace(`/${file.name}`, ''),
          name: file.name,
        };
      });

    filesWithUpdatedPaths.forEach((file) => {
      addFolderToEmptyFolders(file);
    });

    if (request.length) {
      await updateKnowledgeBaseFiles({ id: knowledgeBaseId, body: request });
    } else {
      const newActiveBranch = { ...activeBranch };

      newActiveBranch.children = newActiveBranch.children.filter((child) => {
        return !child.checked;
      });
      updateBranchInTree(tree, activeBranch, newActiveBranch);
      updateActiveBranch(newActiveBranch);
      notification.success('Updated Successfully.');
    }

    cancelCheckboxMode();
  };

  const onFileSearch = (value) => {
    if (value) {
      let newActiveBranch = filterBranchByName(value, tree);
      newActiveBranch.isTemporary = true;

      updateActiveBranch(newActiveBranch);
    } else {
      if (files && files.length) {
        const foldersStructure = getFoldersStructure(files);
        const branch = findActiveBranch(foldersStructure);

        setTree(foldersStructure);
        branch && updateActiveBranch(branch || foldersStructure);
      }
    }

    setFilteredValue(value);
  };

  const resetCheckedItems = (treeArg) => {
    const branch = treeArg || tree;
    setCheckedChildren(branch, false);

    const activeBranch = findActiveBranch(branch);
    setCheckedFilesCount(0);
    setCheckedItemsCount(0);
    activeBranch && updateActiveBranch({ ...activeBranch });
  };

  const cancelCheckboxMode = () => {
    setMoveContentMode(false);
    setDeleteContentMode(false);
    resetCheckedItems();
  };

  const handleSelectAllChange = (checked) => {
    const newBranch = { ...activeBranch };

    if (newBranch) {
      setCheckedChildren(newBranch, checked);
      updateBranchInTree(tree, activeBranch, newBranch);
      updateActiveBranch(newBranch);
      onCheckedChange();
    }
  };

  const onCheckedChange = () => {
    if (moveContentMode || deleteContentMode) {
      const checkedItems = getCheckedFiles(tree);

      setCheckedItemsCount(checkedItems.length);

      const checkedFiles = checkedItems.filter((item) => item.type !== 'folder');

      setCheckedFilesCount(checkedFiles.length);
    }
  };

  return (
    <Fragment>
      <PageContentWrapper
        className={clsx('knowledge-base-page-container', !isLgDown && 'relative')}
        isMobileMarginTop={false}
        isProjectBreadcrumbs={true}
        projectBreadcrumbsContent={knowledgeBaseName}
      >
        <div className="z-10 gap-[10px] flex flex-col relative h-[100%]">
          <div className={'flex flex-row justify-between'}>
            <CurrentKnowledgeBaseBreadcrumb
              file={selectedItem}
              updateActiveBranch={updateActiveBranch}
              knowledgeBaseName={knowledgeBaseName}
            />
            <div>
              <FiltersKnowledgeBaseModal disabled={true} />
            </div>
          </div>
          <CardContainer className="gap-[15px] h-[100%] min-w-[500px]">
            <div className={'flex flex-row justify-between gap-32'}>
              <div className={'flex flex-row gap-2.5'}>
                <AddKnowledgeBaseFolderModal
                  handleAddFolderItem={handleAddFolderItem}
                  disabled={moveContentMode || deleteContentMode}
                />
                <AddKnowledgeBaseSourceModal
                  addKnowledgeBaseSource={addKnowledgeBaseSource}
                  disabled={moveContentMode || deleteContentMode}
                />
                {(moveContentMode || deleteContentMode) && (
                  <div className={'flex flex-row gap-2.5'}>
                    <div
                      className={
                        'text-xs font-medium text-ebony_opacity_30 flex flex-row gap-2.5 items-center justify-center'
                      }
                    >
                      <div
                        className={
                          'px-1.5 py-0.5 bg-ebony text-white rounded-[2px] min-w-6 flex items-center justify-center'
                        }
                      >
                        {checkedFilesCount}
                      </div>
                      <div>Selected</div>
                    </div>
                    <div
                      className={
                        'text-xs font-medium text-ebony_opacity_30 flex flex-row gap-2.5 items-center justify-center'
                      }
                    >
                      <SelectAllCheckboxKnowledgeBasePage onChange={handleSelectAllChange} />
                    </div>
                  </div>
                )}
              </div>
              <div className={'flex flex-1 flex-row gap-2.5 relative justify-end'}>
                <SearchInput
                  className={clsx(
                    '!min-h-[34px] right-[88px]',
                    (moveContentMode || deleteContentMode) && 'hidden',
                  )}
                  inputClassName={'!max-h-[34px]'}
                  onChange={onFileSearch}
                  visibleClassName={' w-[calc(100%-100px)]'}
                />
                {moveContentMode && (
                  <Button
                    variant="outline"
                    type="button"
                    className="min-w-24 shadow-md flex flex-fow gap-2 text-ebony_opacity_80 text-[13px]"
                    onClick={cancelCheckboxMode}
                  >
                    <span>Cancel</span>
                  </Button>
                )}
                {moveContentMode ? (
                  <MoveKnowledgeBaseItemModal
                    trigger={
                      <Button className="min-w-24 shadow-md gap-2">
                        Move <MoveItemIcon color={'#FFFFFF'} />
                      </Button>
                    }
                    item={activeBranch}
                    handleMoveFiles={handleMoveItems}
                    disabled={!checkedItemsCount}
                  />
                ) : (
                  <Button
                    variant="outline"
                    type="button"
                    className={clsx('shadow-md px-1 w-8.5', deleteContentMode && 'hidden')}
                    onClick={() => {
                      setMoveContentMode(true);
                    }}
                    disabled={deleteContentMode}
                  >
                    <MoveItemsKnowledgeBaseIcon />
                  </Button>
                )}

                {deleteContentMode && (
                  <Button
                    variant="outline"
                    type="button"
                    className="min-w-24 shadow-md flex flex-fow gap-2 text-ebony_opacity_80 text-[13px]"
                    onClick={cancelCheckboxMode}
                  >
                    <span>Cancel</span>
                  </Button>
                )}
                {deleteContentMode ? (
                  <DeleteKnowledgeBaseItemsModal
                    handleItemsDelete={handleItemsDelete}
                    disabled={!checkedItemsCount}
                  />
                ) : (
                  <Button
                    variant="outline"
                    type="button"
                    className={clsx('shadow-md px-2 w-8.5', moveContentMode && 'hidden')}
                    onClick={() => {
                      setDeleteContentMode(true);
                    }}
                    disabled={moveContentMode}
                  >
                    <DeleteItemsKnowledgeBaseIcon />
                  </Button>
                )}
              </div>
            </div>

            <section
              className={clsx(dragStarted && 'drag-active', 'overflow-hidden')}
              onDrop={() => {}}
              onDragEnter={(event) => {
                event.preventDefault();
                setDragStarted(true);
              }}
              onDragOver={(event) => {
                event.preventDefault();
              }}
              onDragLeave={(event) => {
                event.preventDefault();
                setDragStarted(false);
              }}
            >
              <input
                type="file"
                ref={inputFile}
                className={'hidden'}
                onChange={onFileChange}
                multiple={true}
                accept={KNOWLEDGE_BASE_FILE_EXTENSIONS.join(', ')}
              />
              {activeBranch && (
                <CurrentKnowledgeBaseTableList
                  className={clsx('h-[60vh] overflow-auto view-type-list')}
                  activeBranch={activeBranch}
                  setSelectedItem={setSelectedItem}
                  onCheckedChange={onCheckedChange}
                  updateActiveBranch={updateActiveBranch}
                  handleItemsDelete={handleItemsDelete}
                  handleMoveItems={handleMoveItems}
                  isLoadingFiles={isLoadingFiles}
                  filteredValue={filteredValue}
                  deleteContentMode={deleteContentMode}
                  moveContentMode={moveContentMode}
                />
              )}
            </section>
          </CardContainer>
        </div>
      </PageContentWrapper>
    </Fragment>
  );
};
