import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { AddProjectsAiKnowledgeBaseForm } from './AddProjectsAiKnowledgeBaseForm';

export const AddProjectsAiKnowledgeBaseModal = ({ trigger, disabled = false, knowledgeBase }) => {
  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        {trigger || (
          <button
            className={clsx(
              disabled && 'pointer-events-none opacity-10',
              'flex gap-2 opacity-30 hover:opacity-100 transition',
            )}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.8"
                d="M10 1.875C5.52 1.875 1.875 5.52 1.875 10C1.875 14.48 5.52 18.125 10 18.125C14.48 18.125 18.125 14.48 18.125 10C18.125 5.52 14.48 1.875 10 1.875ZM10 3.125C13.8044 3.125 16.875 6.19563 16.875 10C16.875 13.8044 13.8044 16.875 10 16.875C6.19563 16.875 3.125 13.8044 3.125 10C3.125 6.19563 6.19563 3.125 10 3.125ZM9.375 6.25V9.375H6.25V10.625H9.375V13.75H10.625V10.625H13.75V9.375H10.625V6.25H9.375Z"
                fill="#05050D"
                stroke="#05050D"
                strokeWidth="0.3"
              />
            </svg>
          </button>
        )}
      </DialogTrigger>
      <DialogContent closeButtonHidden className={'overflow-hidden'}>
        <DialogHeader>
          <DialogTitle>Assign new project</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <AddProjectsAiKnowledgeBaseForm close={closePopup} knowledgeBase={knowledgeBase} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
