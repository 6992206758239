import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import Input from 'src/components/Input/Input';
import { Button } from 'src/components/ui/button';

export const AddKnowledgeBaseFolderForm = ({
  close = () => {},
  handleAddFolderItem = () => {},
  item,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: {
      name: item?.name || '',
    },
    resolver: yupResolver(
      object({
        name: stringScheme({ required: true }).label('Folder Name'),
      }),
    ),
  });

  const submit = ({ name }) => {
    handleAddFolderItem(name);
    close();
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="content">
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              placeholder={'New Folder Name'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
              className={'!border-[#E3E6E8] focus:!border-ebony_opacity_30'}
            />
          )}
        />
      </div>
      <div className="flex justify-center mt-5">
        <div className="flex gap-2.5">
          <Button variant="outline" type="button" className="min-w-24 shadow-md" onClick={close}>
            Cancel
          </Button>
          <Button disabled={!isDirty || !isValid} type="submit" className="min-w-24 shadow-md">
            Done
          </Button>
        </div>
      </div>
    </form>
  );
};
