import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { Button } from 'src/components/ui/button';
import { getChildrenFiles, getFoldersArrayFromTree, getRootFolder } from 'src/utils/fileUtils';
import Input from 'src/components/Input/Input';
import { MoveItemIcon } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseIcons/CurrentKnowledgeBaseIcons';
import { Separator } from 'src/components/ui/separator';
import { SearchInputIcon } from 'src/assets/icons/SearchInputIcon';

const highlightMatch = (text, query) => {
  if (!query) return text;

  const regex = new RegExp(`(${query})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <mark key={index} className="bg-[#C9F4D3] ">
        {part}
      </mark>
    ) : (
      part
    ),
  );
};

export const MoveKnowledgeBaseItemModal = ({
  trigger = null,
  disabled = false,
  handleMoveFiles = () => {},
  item,
  activeBranch,
}) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [folders, setFolders] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredValue, setFilteredValue] = useState('');
  const tree = getRootFolder(item);
  const childFolders = item.type === 'folder' ? getFoldersArrayFromTree(item) : [];
  const foldersArray = getFoldersArrayFromTree(tree).filter(
    (folder) => !childFolders.some((child) => child.path === folder.path),
  );

  const onChange = (value) => {
    value = value.trim();
    setSearchValue(value);
    setFilteredValue(value);

    if (value) {
      const newFolders = foldersArray.filter((folder) =>
        folder.name.toLowerCase().includes(value.toLowerCase()),
      );

      setFolders(newFolders);
    } else {
      setFolders(foldersArray);
    }
  };
  const submit = (event) => {
    event.preventDefault();
    handleMoveFiles(checked, item);
    onOpenChange(false);
  };

  useEffect(() => {
    const tree = getRootFolder(item);
    const foldersArray = getFoldersArrayFromTree(tree).filter(
      (folder) => !childFolders.some((child) => child.path === folder.path),
    );

    setFolders(foldersArray);
  }, [tree, activeBranch]);

  const onOpenChange = (isOpen) => {
    setOpen(isOpen);

    if (!isOpen) {
      setFilteredValue('');
      setFolders(foldersArray);
    }
  };

  return (
    <Dialog onOpenChange={onOpenChange} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        {trigger || (
          <button
            className={clsx(
              disabled && 'pointer-events-none opacity-50',
              'flex gap-2 opacity-30 hover:opacity-100 transition',
            )}
          >
            <MoveItemIcon />
          </button>
        )}
      </DialogTrigger>
      <DialogContent closeButtonHidden>
        <DialogHeader>
          <DialogTitle>Select new folder</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="content">
            <form
              id="move-to-folder"
              onSubmit={submit}
              className={'flex flex-col gap-[20px] max-h-[500px]'}
            >
              <div className={'input-container relative'}>
                <Input
                  className={'!border-[#E3E6E8] focus:!border-ebony_opacity_30 low-height'}
                  type={'text'}
                  label={''}
                  placeholder={'Search'}
                  isValid={true}
                  value={searchValue}
                  onChange={(e) => onChange(e.currentTarget.value)}
                  icon={<SearchInputIcon className={'w-3.5 h-3.5'} />}
                  iconAlign={'left'}
                />
                {searchValue && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    className={'trasition-all absolute top-4 right-3 cursor-pointer group'}
                    onClick={() => onChange('')}
                  >
                    <path
                      d="M10.275 1.75062C7.935 -0.583541 4.095 -0.583541 1.755 1.75062C-0.585 4.08479 -0.585 7.91521 1.755 10.2494C4.095 12.5835 7.875 12.5835 10.215 10.2494C12.555 7.91521 12.615 4.08479 10.275 1.75062ZM7.695 8.51372L6.015 6.8379L4.335 8.51372L3.495 7.67581L5.175 6L3.495 4.32419L4.335 3.48628L6.015 5.16209L7.695 3.48628L8.535 4.32419L6.855 6L8.535 7.67581L7.695 8.51372Z"
                      fill="rgba(5,5,13,0.3)"
                      className={'group-hover:fill-[rgba(5,5,13)] transition-all duration-300'}
                    />
                  </svg>
                )}
              </div>
              <ul className="flex flex-col gap-2.5 overflow-auto h-[40vh]">
                {folders.map((folder) => {
                  const id = folder.path || 'knowledge-base';
                  const name = folder.root
                    ? folder.name
                    : folder.path.replace('/', `${tree.name}/`);

                  return (
                    <li key={folder.path || 'knowledge-base'} className={'filter-list-item w-full'}>
                      <label
                        htmlFor={id}
                        className={
                          'flex flex-row items-center w-full gap-2.5 p-2.5 cursor-pointer hover:bg-[#E3E6E8] rounded-[5px]'
                        }
                      >
                        <input
                          name={'task-complexity'}
                          value={folder.path}
                          id={id}
                          type="radio"
                          checked={folder === checked}
                          onChange={() => setChecked(folder)}
                          className="accent-ebony w-4 h-4"
                        />
                        <p className={'text-sm'}>{highlightMatch(name, filteredValue)}</p>
                      </label>
                    </li>
                  );
                })}
              </ul>
              <Separator className={'bg-[#E3E6E8]'} />
              <div className="flex justify-center">
                <div className="flex gap-2.5">
                  <Button
                    variant="outline"
                    type="button"
                    className="min-w-24 shadow-md"
                    onClick={() => onOpenChange(false)}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="min-w-24 shadow-md">
                    Move
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
