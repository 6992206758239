import { PlaygroundUserMessage } from 'src/pages/PlaygroundPage/PlaygroundChat/PlaygroundUserMessage/PlaygroundUserMessage';
import { PlaygroundAIMessage } from 'src/pages/PlaygroundPage/PlaygroundChat/PlaygroundAIMessage/PlaygroundAIMessage';
import { clsx } from 'clsx';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { ReplyPlaygroundButton } from 'src/pages/PlaygroundPage/PlaygroundChat/ReplyPlaygroundButton/ReplyPlaygroundButton';
import { sendSSEMessage } from 'src/utils/sse.utils';
import {
  appendPlaygroundMessages,
  clearPlayground,
  setPlayground,
  updateLastPlaygroundMessage,
} from 'src/store/slices/playgroundSlices';
import { PATH_PLAYGROUND_API } from 'src/constants/api-routes';
import { environment } from 'src/env/env';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useCreatePlaygroundChatMutation, useLazyGetPlaygroundChatQuery } from 'src/services';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useLoader } from 'src/provider/LoaderProvider';
import { useNotification } from 'src/hooks/useNotification';
import { uuid } from 'src/utils/uuid';
import moment from 'moment/moment';
import { RefreshPlaygroundButton } from 'src/pages/PlaygroundPage/PlaygroundChat/RefreshPlaygroundButton/RefreshPlaygroundButton';
import { useParams } from 'react-router-dom';
import { PlaygroundDateMessage } from 'src/pages/PlaygroundPage/PlaygroundChat/PlaygroundDateMessage/PlaygroundDateMessage';

const INITIAL_MESSAGE = `Hi there 👋 \n\n How can I help you today?`;

export const PlaygroundChat = ({ className = '' }) => {
  const { id } = useParams();
  const [isLastMessageLoading, setIsLastMessageLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [chat, setChat] = useState(null);
  const chatContainerRef = useRef(null);
  const loader = useLoader();
  const notification = useNotification();
  const { playground } = useAppSelector((s) => s.playgroundSlices);
  const dispatch = useAppDispatch();
  const [
    createPlaygroundChat,
    {
      data: createPlaygroundChatData,
      error: errorCreatePlaygroundChat,
      isError: isErrorCreatePlaygroundChat,
      isLoading: isLoadingCreatePlaygroundChat,
    },
  ] = useCreatePlaygroundChatMutation();
  const [
    fetchPlaygroundChat,
    {
      data: getPlaygroundChatData,
      error: errorGetPlaygroundChat,
      isError: isErrorGetPlaygroundChat,
      isLoading: isLoadingGetPlaygroundChat,
    },
  ] = useLazyGetPlaygroundChatQuery();

  const sendMessage = useCallback(
    (message) => {
      const url = `${environment.BASE_URL}/${PATH_PLAYGROUND_API.ROOT}/${id}${PATH_PLAYGROUND_API.CHAT}/${chat._id}${PATH_PLAYGROUND_API.MESSAGES}`;

      sendSSEMessage('POST', url, message, (response) => {
        const { error, message } = response;

        if (error) {
          notification.error(error);
        } else {
          dispatch(updateLastPlaygroundMessage({ projectId: id, message }));
        }
      });
    },
    [id, chat],
  );

  useEffect(() => {
    const chatData = playground && playground[`project${id}`];

    if (chatData) {
      fetchPlaygroundChat({ projectId: id, chatId: chatData._id });
    } else {
      createPlaygroundChat({ projectId: id });
    }
  }, []);

  useEffect(() => {
    const chatData = playground[`project${id}`];
    const chat = [];
    const date = {
      today: moment(),
      yesterday: moment().add(-1, 'days'),
      current: null,
    };

    if (!chatData) {
      return;
    }

    if (chatData && !chatData?.messages?.length) {
      const message = createMessage(INITIAL_MESSAGE, 'ai', chatData);

      dispatch(appendPlaygroundMessages({ projectId: id, messages: [message] }));
    }

    chatData.messages?.forEach((message) => {
      const createdAt = moment(message.created_at);
      if (!date.current || !createdAt.isSame(moment(date.current), 'day')) {
        date.current = moment(message.created_at);
        let value;

        if (date.today.isSame(date.current, 'day')) {
          value = 'Today';
        } else if (date.yesterday.isSame(date.current, 'day')) {
          value = 'Yesterday';
        } else {
          value = date.current.format('ll');
        }

        const dateMessage = {
          type: 'date',
          value,
        };

        chat.push(dateMessage);
      }

      chat.push(message);
    });

    setChat({ ...chatData, messages: chat });
  }, [playground]);

  useEffect(() => {
    const isLoading = chat?.messages && chat?.messages[chat.messages.length - 1].loading;
    scrollToBottom();
    setIsLastMessageLoading(Boolean(isLoading));
  }, [chat]);

  useEffect(() => {
    if (createPlaygroundChatData) {
      dispatch(setPlayground({ projectId: id, playground: createPlaygroundChatData }));
    }
  }, [createPlaygroundChatData]);

  useEffect(() => {
    if (getPlaygroundChatData) {
      const message = createMessage(INITIAL_MESSAGE, 'ai', getPlaygroundChatData);
      const messages = getPlaygroundChatData.messages || [];
      const payload = {
        projectId: id,
        playground: { ...getPlaygroundChatData, messages: [message, ...messages] },
      };

      dispatch(setPlayground(payload));
    }
  }, [getPlaygroundChatData]);

  useEffect(() => {
    if (isLoadingGetPlaygroundChat) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingGetPlaygroundChat]);

  useEffect(() => {
    if (isLoadingCreatePlaygroundChat) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingCreatePlaygroundChat]);

  useEffect(() => {
    if (isErrorGetPlaygroundChat) {
      notification.error(getErrorMessage(errorGetPlaygroundChat));
    }
  }, [isErrorGetPlaygroundChat]);

  useEffect(() => {
    if (isErrorCreatePlaygroundChat) {
      notification.error(getErrorMessage(errorCreatePlaygroundChat));
    }
  }, [isErrorCreatePlaygroundChat]);

  const createMessage = (text, owner, chatData, loading = false) => {
    const isInitialMessage = text === INITIAL_MESSAGE;
    const date = isInitialMessage
      ? (chatData.messages && chatData.messages[0]?.created_at) || undefined
      : new Date();

    return {
      id: uuid(),
      project_id: id,
      body: text,
      created_at: moment.utc(date).format('YYYY-MM-DDTHH:mm:ss'),
      loading,
      owner,
      chat_id: chatData._id,
    };
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      return;
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      Boolean(inputValue) && applyMessages();
    }
  };

  const onButtonClick = () => {
    Boolean(inputValue) && applyMessages();
  };

  const applyMessages = () => {
    const message = {
      user: createMessage(inputValue, 'user', chat._id),
      ai: createMessage('', 'ai', chat._id, true),
    };

    setInputValue('');
    dispatch(appendPlaygroundMessages({ projectId: id, messages: [message.user, message.ai] }));
    sendMessage(inputValue);
  };

  const scrollToBottom = () => {
    const scrollHeight = chatContainerRef.current.scrollHeight;
    const height = chatContainerRef.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    chatContainerRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  const onRefresh = () => {
    dispatch(clearPlayground({ projectId: id }));
    createPlaygroundChat({ projectId: id });
    notification.success(`Playground chat refreshed.`);
  };

  return (
    <div
      className={clsx(
        'flex flex-auto flex-col items-center w-full overflow-hidden gap-5',
        className,
      )}
    >
      <RefreshPlaygroundButton onClick={onRefresh} className={'self-end'} />
      <div
        ref={chatContainerRef}
        className={
          'flex flex-auto w-full h-full overflow-auto justify-center relative scroll-smooth'
        }
      >
        <ul className="flex flex-col gap-[5px] p-1 w-full lg:w-2/3">
          {chat?.messages?.map((message, index) => {
            if (message.type === 'date') {
              return (
                <PlaygroundDateMessage date={message.value} key={`${message.value}-${index}`} />
              );
            }

            return message.owner === 'user' ? (
              <PlaygroundUserMessage key={`${message.created_at}-${index}`} message={message} />
            ) : (
              <PlaygroundAIMessage key={`${message.created_at}-${index}`} message={message} />
            );
          })}
        </ul>
      </div>
      <div className="flex-initial w-full lg:w-2/3 relative">
        <textarea
          placeholder="Write a reply..."
          disabled={isLastMessageLoading || chat?.status !== 'open'}
          rows={3}
          value={inputValue}
          onChange={(e) => setInputValue(e.currentTarget.value)}
          className={
            'w-full border border-[#E3E6E8] outline-[1px] outline-ebony_opacity_10 resize-none rounded-md-10 p-5 pr-11 shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.1)] placeholder:text-[#E3E6E8] text-base'
          }
          onKeyDown={onKeyDown}
        />
        <ReplyPlaygroundButton
          onClick={onButtonClick}
          disabled={chat?.status !== 'open' || !inputValue || isLastMessageLoading}
        />
      </div>
    </div>
  );
};
