import './SearchFilesContainer.css';
import React from 'react';
import { ViewTypesTabs } from 'src/components/FileManager/SearchFilesContainer/ViewTypesTabs/ViewTypesTabs';
import { SortFilesDropdown } from 'src/components/FileManager/SearchFilesContainer/SortFilesDropdown/SortFilesDropdown';
import { clsx } from 'clsx';
import { SearchInput } from 'src/components/SearchInput/SearchInput';

export const SearchFilesContainer = ({
  hideSearch = false,
  hideSort = false,
  hideViewType = false,
  onSearch,
  onSortFilesChange,
  onViewTypeChange,
}) => {
  const handleFileSearch = (value) => {
    onSearch && onSearch(value.length >= 1 ? value : '');
  };

  return (
    <div className="search-files-container flex flex-row justify-end gap-[10px] flex-1 relative">
      {!hideSearch && (
        <SearchInput
          className={clsx('!min-h-[34px] !right-0')}
          inputClassName={'!max-h-[34px]'}
          onChange={handleFileSearch}
          offsetLeft={false}
        />
      )}
      {!hideSort && <SortFilesDropdown onSortFilesChange={onSortFilesChange} />}
      {!hideViewType && <ViewTypesTabs onViewTypeChange={onViewTypeChange} />}
    </div>
  );
};
