import moment from 'moment/moment';
import { Separator } from 'src/components/ui/separator';

export const ConversationEscalatedMessage = ({ date }) => {
  return (
    <li
      className={
        'list-none flex ext-sm font-medium flex-row w-full justify-center items-center relative mt-4'
      }
    >
      <div
        className={
          'py-2 px-4 rounded-3xl border border-[#C11A45] text-xs text-[#C11A45] bg-white z-10'
        }
      >
        Escalated – {moment(date).format('lll')}
      </div>
      <Separator className={'!bg-[#C11A45] w-full absolute top-[50%]'} />
    </li>
  );
};
