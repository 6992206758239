import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import React, { useState } from 'react';
import { clsx } from 'clsx';

export const AiKnowledgeBaseToolTip = ({
  trigger,
  content,
  side = 'left',
  triggerClassName,
  contentClassName,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <TooltipProvider>
      <Tooltip open={open} onOpenChange={setOpen}>
        <TooltipTrigger
          onClick={() => setOpen(true)}
          asChild
          className={clsx('cursor-pointer', triggerClassName)}
        >
          {trigger}
        </TooltipTrigger>
        <TooltipContent side={side} className={clsx(contentClassName, 'max-w-2xl select-none')}>
          {content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
