import './ProjectPage.css';
import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { CurrentProjectScreen } from './ProjectSreens/CurrentProjectScreen';
import { useGetAllProjectsQuery } from 'src/services';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTheme } from 'src/provider/ThemeProvider';
import { ProductsType } from 'src/constants/products';
import { useGetUsersMeQuery } from 'src/services/usersService';

const ProjectPage = () => {
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const splideRef = useRef(null);
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const isCustomer = searchParams.get('type') === ProductsType.customer;
  const isBi = searchParams.get('type') === ProductsType.bi;
  const isAgent = searchParams.get('type') === ProductsType.agent;
  const { data: projectsData } = useGetAllProjectsQuery();
  const { setTheme } = useTheme();
  const { data: userInfo } = useGetUsersMeQuery();
  const role = userInfo?.role;

  useEffect(() => {
    if (projectsData && splideRef.current?.splide.index !== 1) {
      splideRef.current?.splide.go(1);
    }
  }, [projectsData, id]);

  useEffect(() => {
    if (splideRef.current) {
      splideRef.current?.splide.on('move', function () {
        window.scrollTo(0, 0);
      });
    }
  }, [splideRef]);

  useEffect(() => {
    setTheme('blue');
  }, []);

  return (
    <React.Fragment>
      <CurrentProjectScreen />
    </React.Fragment>
  );
};

export default ProjectPage;
