import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  playground: {
    //   `playground${projectId}`: data
  },
};

export const playgroundSlices = createSlice({
  name: 'playgroundSlices',
  initialState,
  reducers: {
    setPlayground(state, action) {
      const { projectId, playground } = action.payload;

      state.playground[`project${projectId}`] = playground;
    },
    clearPlayground: (state, action) => {
      const { projectId } = action.payload;

      state.playground[`project${projectId}`] = null;
    },
    appendPlaygroundMessages(state, action) {
      const { projectId, messages } = action.payload;
      const projectPlayground = state.playground[`project${projectId}`];

      projectPlayground.messages = projectPlayground.messages || [];

      state.playground[`project${projectId}`] = {
        ...projectPlayground,
        messages: [...projectPlayground.messages, ...messages],
      };
    },
    updateLastPlaygroundMessage(state, action) {
      const { projectId, message } = action.payload;
      const projectPlayground = state.playground[`project${projectId}`];
      const messages = projectPlayground.messages || [];

      messages[projectPlayground.messages.length - 1] = message;
      state.playground[`project${projectId}`] = {
        ...projectPlayground,
        messages,
      };
    },
  },
});

export const {
  setPlayground,
  appendPlaygroundMessages,
  updateLastPlaygroundMessage,
  clearPlayground,
} = playgroundSlices.actions;

export default playgroundSlices.reducer;
