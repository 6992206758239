import React, { forwardRef } from 'react';
import { clsx } from 'clsx';

export const AddKnowledgeBaseSourceItem = forwardRef(({ item, onClick }, ref) => {
  return (
    <div
      onClick={() => onClick(item)}
      className={clsx(
        'bg-white border border-[#E3E6E8] rounded-sm-5 shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.25)] hover:shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.45)] hover:cursor-pointer py-[15px] px-2.5 gap-5 flex flex-col',
        item.disabled && 'pointer-events-none opacity-30',
      )}
    >
      <div className="flex flex-row gap-1.5 items-center">
        {item.icon}
        <p className={'text-ebony text-sm font-semibold'}>{item.title}</p>
      </div>
      <div>
        <p className={'text-ebony_opacity_50 text-xs font-medium'}>{item.description}</p>
      </div>
    </div>
  );
});
