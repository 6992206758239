import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import { KnowledgeBaseFiltersIcon } from 'src/pages/AIKnowledgeBasePage/CurrentKnowledgeBasePage/CurrentKnowledgeBaseIcons/CurrentKnowledgeBaseIcons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, object, number } from 'yup';
import { Checkbox } from 'src/components/ui/checkbox';

const FILTERS = [
  {
    id: 0,
    type: 'integration',
    title: 'Integrations',
  },
  {
    id: 1,
    type: 'file',
    title: 'Files',
  },
  {
    id: 2,
    type: 'faq',
    title: 'FAQ',
  },
  {
    id: 3,
    type: 'website',
    title: 'Websites',
  },
];

export const FiltersKnowledgeBaseModal = ({
  disabled = false,
  filteredItems = [],
  filterItems = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      filters_id:
        filteredItems && filteredItems.length
          ? filteredItems.map((product) => ({ id: product.id }))
          : FILTERS,
    },
    resolver: yupResolver(
      object({
        filters_id: array(object({ id: number() }))
          .required()
          .label('Filter'),
      }),
    ),
  });

  const checkedItems = watch('filters_id');

  const submit = (formValues) => {
    const filteredFilters = FILTERS.filter((item) =>
      formValues.filters_id.some((el) => el.id === item.id),
    );
    filterItems(filteredFilters);
  };

  const activateFiltersCheckBoxHandler = (checked, onChange, value, product) => {
    checked
      ? onChange([...value, { id: Number(product.id) }])
      : onChange(value.filter((val) => val.id !== product.id));
  };

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        <Button
          variant="outline"
          type="button"
          className="px-3.5 gap-2 bg-transparent border-[#E3E6E8] hover:border-ebony_opacity_30"
        >
          <span>Filters</span>
          <KnowledgeBaseFiltersIcon />
        </Button>
      </DialogTrigger>
      <DialogContent closeButtonHidden>
        <DialogHeader>
          <DialogTitle>Filters</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="content">
            <form
              id="filter-items"
              onSubmit={handleSubmit(submit)}
              className={'flex flex-col gap-[20px] max-h-[500px]'}
            >
              <div className="flex flex-col relative">
                <Controller
                  control={control}
                  name="filters_id"
                  render={({ field: { onChange, value } }) =>
                    FILTERS.map((filter) => (
                      <div key={filter.id} className="flex items-center space-x-5 border-b  w-full">
                        <label
                          htmlFor={`sub-${filter.id}`}
                          className="py-6 w-full text-base flex items-center gap-2.5 text-ebony cursor-pointer font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          <Checkbox
                            id={`sub-${filter.id}`}
                            checked={value?.some((val) => val.id === filter.id)}
                            onCheckedChange={(checked) => {
                              activateFiltersCheckBoxHandler(checked, onChange, value, filter);
                            }}
                          />

                          {filter?.title}
                        </label>
                      </div>
                    ))
                  }
                />
                {errors?.filters_id?.message && (
                  <p className="text-sm font-semibold text-error absolute top-full mt-3">
                    {errors.filters_id.message}
                  </p>
                )}
              </div>
              <div className="flex justify-center">
                <div className="flex gap-2.5">
                  <Button
                    variant="outline"
                    type="button"
                    className="min-w-24 shadow-md"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!isDirty || !checkedItems.length}
                    type="submit"
                    className="min-w-24 shadow-md"
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
