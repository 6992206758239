import React, { useEffect } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { getErrorMessage } from 'src/utils/get-error-message';
import Input from 'src/components/Input/Input';
import { Button } from 'src/components/ui/button';
import {
  useCreateKnowledgeBaseMutation,
  useEditKnowledgeBaseNameMutation,
} from 'src/services/knowledgeBaseService';

export const CreateEditAiKnowledgeBaseForm = ({ close = () => {}, knowledgeBase }) => {
  const notification = useNotification();

  const [
    createKnowledgeBase,
    { isLoading, isError: createKnowledgeBaseIsError, error: createKnowledgeBaseError, isSuccess },
  ] = useCreateKnowledgeBaseMutation();

  const [
    editKnowledgeBase,
    {
      isLoading: editKnowledgeBaseIsLoading,
      isError: editKnowledgeBaseIsError,
      error: editKnowledgeBaseError,
      isSuccess: editKnowledgeBaseIsSuccess,
    },
  ] = useEditKnowledgeBaseNameMutation();

  useEffect(() => {
    if (isSuccess || editKnowledgeBaseIsSuccess) {
      close();
      notification.success(
        knowledgeBase?.id
          ? 'Knowledge Base was successfully updated!'
          : 'Knowledge Base was successfully created!',
      );
    }
  }, [isLoading, isSuccess, editKnowledgeBaseIsLoading, editKnowledgeBaseIsSuccess]);

  useEffect(() => {
    if (createKnowledgeBaseIsError) {
      notification.error(getErrorMessage(createKnowledgeBaseError));
    }
  }, [createKnowledgeBaseIsError]);

  useEffect(() => {
    if (editKnowledgeBaseIsError) {
      notification.error(getErrorMessage(editKnowledgeBaseError));
    }
  }, [editKnowledgeBaseIsError]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      name: knowledgeBase?.name || '',
    },
    resolver: yupResolver(
      object({
        name: stringScheme({ required: true }).label('Name'),
      }),
    ),
  });

  const submit = (data) => {
    if (knowledgeBase?.id) {
      editKnowledgeBase({
        name: data.name,
        knowledge_base_id: knowledgeBase?.id,
      }).then(() => reset());
    } else {
      createKnowledgeBase({
        name: data.name,
      }).then(() => reset());
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="content">
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={knowledgeBase?.id ? 'New name' : 'Name'}
              placeholder={'AI Knowledge Base'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="flex justify-center mt-5">
        <div className="flex gap-2.5">
          <Button variant="outline" type="button" className="min-w-24 shadow-md" onClick={close}>
            Cancel
          </Button>
          <Button disabled={!isDirty || isLoading} type="submit" className="min-w-24 shadow-md">
            {isLoading ? 'Loading...' : knowledgeBase?.id ? 'Save' : 'Create'}
          </Button>
        </div>
      </div>
    </form>
  );
};
