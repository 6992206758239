import { baseApi } from './base.api';
import { PATH_PLAYGROUND_API } from '../constants/api-routes';

export const playgroundService = baseApi.injectEndpoints({
  endpoints: (build) => {
    return {
      getPlaygroundChat: build.query({
        query: ({ projectId, chatId }) => ({
          url: `${PATH_PLAYGROUND_API.ROOT}/${projectId}${PATH_PLAYGROUND_API.CHAT}/${chatId}`,
        }),
        invalidatesTags: (result, error, { id }) => [{ type: 'PlaygroundChat', id }],
        providesTags: ['PlaygroundChat'],
      }),
      createPlaygroundChat: build.mutation({
        query: ({ projectId }) => ({
          url: `${PATH_PLAYGROUND_API.ROOT}/${projectId}${PATH_PLAYGROUND_API.CHAT}`,
          method: 'POST',
        }),
      }),
    };
  },
});

export const { useLazyGetPlaygroundChatQuery, useCreatePlaygroundChatMutation } = playgroundService;
