import moment from 'moment';
import { clsx } from 'clsx';
import { MarkdownRenderer } from 'src/components/MarkdownRenderer/MarkdownRenderer';
import { PlaygroundAIIcon } from 'src/pages/PlaygroundPage/PlaygroundChat/PlaygroundAIMessage/PlaygroundAIIcon';
import { PlaygroundMessageLoader } from 'src/pages/PlaygroundPage/PlaygroundChat/PlaygroundMessageLoader/PlaygroundMessageLoader';

export const PlaygroundAIMessage = ({ className, message, ...props }) => {
  return (
    <li
      className={clsx(
        'md:max-w-[90%] lg:max-w-[85%] xl:max-w-[70%] flex flex-row items-start gap-[10px]',
        className,
      )}
      {...props}
    >
      <div>
        <PlaygroundAIIcon />
      </div>
      <div className={'flex flex-col gap-[5px]'}>
        <div
          className={'bg-[#EEECFD] p-4 rounded-md-10 text-ebony_opacity_70 text-base font-normal'}
        >
          {message.loading ? (
            <PlaygroundMessageLoader />
          ) : (
            <MarkdownRenderer>{message.body}</MarkdownRenderer>
          )}
        </div>
        <div className="flex flex-row justify-end w-full">
          <span className={'text-ebony_opacity_30 text-[0.688rem] font-medium'}>
            {moment(moment.utc(message.created_at).toDate()).format('hh:mm A')}
          </span>
        </div>
      </div>
    </li>
  );
};
