import './AnalyticsAgent.css';
import React, { useEffect, useState } from 'react';
import { useFetchCoAgentAnalyticsMutation } from 'src/services/coAgentAnalyticsService';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import constants from '../constants';
import PageContentWrapper from 'src/components/PageContentWrapper/PageContentWrapper';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { ProjectSelectFilterPopup } from 'src/components/ProjectSelectFilterPopup/ProjectSelectFilterPopup';
import { useTheme } from 'src/provider/ThemeProvider';
import { clsx } from 'clsx';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useGetProjectTeamMembersQuery } from 'src/services';
import { useParams } from 'react-router-dom';
import { AnalyticsTabs } from './AnalyticsTabs/AnalyticsTabs';
import { AnalyticsDate } from './AnalyticsDate/AnalyticsDate';
import { DateItems } from 'src/pages/AnalyticsPage/AnalyticsCustomer/DateItems';
import { AnalyticsTeamMultiSelect } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsTeamMultiSelect/AnalyticsTeamMultiSelect';

const TIME_FORMAT = 'YYYY-MM-DD';

const AnalyticsAgent = () => {
  const teamOption = {
    label: 'Team',
    full_name: 'Team',
    color: '#75F3FB',
  };
  const [dateRange, setDateRage] = useState(DateItems[0]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([teamOption]);
  const [analyticsData, setAnalyticsData] = useState(null);
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const { setTheme } = useTheme();
  const { id } = useParams();
  const [request, setRequest] = useState({
    ...constants.initialRequest,
    date_range: DateItems[0].dateRange,
    time_axis: DateItems[0].timeAxis,
    start_date: DateItems[0].getStartAgentTime(TIME_FORMAT),
    end_date: DateItems[0].getEndAgentTime(TIME_FORMAT),
    project_id: +id,
  });
  const notification = useNotification();
  const loader = useLoader();
  const [
    fetchAnalytics,
    {
      data: fetchAnalyticsRes,
      isLoading,
      isError: isFetchAnalyticsError,
      error: fetchAnalyticsError,
    },
  ] = useFetchCoAgentAnalyticsMutation();
  const {
    data: projectTeamMembersResponse,
    error: errorGetProjectTeamMembersFiles,
    isError: isErrorGetProjectTeamMembers,
    isFetching: isFetchingGetProjectTeamMembers,
  } = useGetProjectTeamMembersQuery(
    {
      id,
    },
    { skip: !id },
  );

  useEffect(() => {
    if (!projectTeamMembersResponse) {
      return;
    }

    const crmUsers = projectTeamMembersResponse.filter((user) => {
      const crmData = user.user.crm_data || [];
      const currentProjectCrmData = crmData.find((data) => data.project_id === +id);

      return Boolean(currentProjectCrmData && currentProjectCrmData.crm_user_id);
    });

    const members = [
      teamOption,
      ...crmUsers.map(({ user }, index) => {
        return {
          ...user,
          full_name: `${user.first_name} ${user.last_name}`,
          label: `${user.first_name} ${user.last_name}`,
          color: constants.colors[index],
        };
      }),
    ];

    members.forEach((member) => {
      members.colors = members.colors || {};
      members.colors[member.full_name] = member.color;
    });

    setTeamMembers(members);
  }, [id, projectTeamMembersResponse]);

  useEffect(() => {
    const isEmptyAgent = !Boolean(request.agents && request.agents.length);

    if (isEmptyAgent) {
      return clearPage();
    }

    fetchAnalytics(request);
  }, [request]);

  useEffect(() => {
    isLoading || isFetchingGetProjectTeamMembers ? loader.show() : loader.hide();
  }, [isLoading, isFetchingGetProjectTeamMembers]);

  useEffect(() => {
    if (isFetchAnalyticsError) {
      notification.error(getErrorMessage(fetchAnalyticsError));
    }
  }, [isFetchAnalyticsError]);

  useEffect(() => {
    if (isErrorGetProjectTeamMembers) {
      notification.error(getErrorMessage(errorGetProjectTeamMembersFiles));
    }
  }, [isErrorGetProjectTeamMembers]);

  useEffect(() => {
    if (!dateRange) {
      return;
    }
    const isCustom = dateRange.label === 'Custom';
    const item = DateItems.find((dateItem) => dateItem.label === dateRange.label);

    setRequest({
      ...request,
      date_range: item.dateRange,
      time_axis: item.timeAxis,
      start_date: isCustom ? dateRange.start_time : item.getStartAgentTime(TIME_FORMAT),
      end_date: isCustom ? dateRange.end_time : item.getEndAgentTime(TIME_FORMAT),
    });
  }, [dateRange]);

  useEffect(() => {
    if (!fetchAnalyticsRes) {
      return;
    }

    let result = fetchAnalyticsRes.parsedResponse;

    if (fetchAnalyticsRes?.parsedResponse?.isByUser) {
      const data = fetchAnalyticsRes.parsedResponse.data.map((item) => {
        return Object.keys(item).reduce((accumulator, currentValue) => {
          if (currentValue === 'date') {
            return {
              ...accumulator,
              [currentValue]: item[currentValue],
            };
          }

          const user = teamMembers.find((teamMember) =>
            teamMember?.crm_data?.find((crmData) => crmData.crm_user_id === currentValue),
          );

          return {
            ...accumulator,
            [user.full_name]: item[currentValue],
          };
        }, {});
      }, []);

      result = {
        ...fetchAnalyticsRes.parsedResponse,
        data,
        colors: teamMembers.colors,
      };
    } else {
      result = {
        ...fetchAnalyticsRes.parsedResponse,
        colors: {
          [teamOption.full_name]: teamOption.color,
        },
      };
    }

    setAnalyticsData(result);
  }, [fetchAnalyticsRes]);

  useEffect(() => {
    setTheme('blue');
  }, []);

  const getAgents = (teamMembers) => {
    return teamMembers
      .map((user) => {
        if (!user.crm_data) {
          return false;
        }

        const currentProjectCrmData = user.crm_data.find((data) => data.project_id === +id);

        return currentProjectCrmData.crm_user_id;
      })
      .filter(Boolean);
  };

  const onTeamSelectClose = () => {
    if (!selectedTeam.length) {
      return;
    }

    let agents, metrics;
    if (selectedTeam[0].label === teamOption.label) {
      agents = getAgents(teamMembers);
      metrics = constants.subdomain_metrics;
    } else {
      agents = getAgents(selectedTeam);
      metrics = constants.personal_metrics;
    }
    setRequest({
      ...request,
      metrics,
      agents,
    });
  };

  const onCustomizeChange = (filteredData) => {
    setRequest({
      ...request,
      ...filteredData,
    });
  };

  const clearPage = () => {};

  return (
    <React.Fragment>
      <PageContentWrapper
        className={clsx('coagent-analytics-page-container', !isLgDown && 'relative')}
        showFilter
        projectBreadcrumbsContent={'Analytics'}
        mobileFilterModal={<ProjectSelectFilterPopup id={id} />}
      >
        <div className="z-10 gap-[10px] flex flex-col relative">
          <CardContainer className="dashboard z-10 overflow-hidden">
            <div className="card-body md:flex-row flex-col md:gap-[10px] gap-[6px] size-full">
              <div className="md:flex-row flex flex-col row size-full md:gap-[10px] gap-[6px] md:justify-end">
                {teamMembers && Boolean(teamMembers.length) && (
                  <AnalyticsTeamMultiSelect
                    onClose={onTeamSelectClose}
                    options={teamMembers}
                    values={selectedTeam}
                    onChange={(selectedOptions) => {
                      setSelectedTeam(selectedOptions);
                    }}
                  />
                )}
                <AnalyticsDate
                  onDateChange={(selectedOption) => setDateRage(selectedOption)}
                  selectOptions={DateItems.map((item) => {
                    return { label: item.label, disabled: item.disabled };
                  })}
                />
              </div>
            </div>
            <div className="card-body flex md:flex-row flex-col md:gap-[10px] gap-[6px] size-full rounded-[5px] md:rounded-[7px] p-[10px]">
              <AnalyticsTabs
                onCustomizeChange={onCustomizeChange}
                data={analyticsData}
                teamMembers={teamMembers}
              ></AnalyticsTabs>
            </div>
          </CardContainer>
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default AnalyticsAgent;
