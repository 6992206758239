import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { AiKnowledgeBaseToolTip } from 'src/pages/AIKnowledgeBasePage/AiKnowledgeBaseToolTip';
import { CreateEditAiKnowledgeBaseModal } from 'src/pages/AIKnowledgeBasePage/CreateEditAiKnowledgeBase/CreateEditAiKnowledgeBaseModal';
import { Separator } from 'src/components/ui/separator';
import { AddProjectsAiKnowledgeBaseModal } from 'src/pages/AIKnowledgeBasePage/AddProjectsAiKnowledgeBase/AddProjectsAiKnowledgeBaseModal';
import { DeleteAiKnowledgeBaseModal } from 'src/pages/AIKnowledgeBasePage/DeleteAiKnowledgeBase/DeleteAiKnowledgeBaseModal';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { useNavigate } from 'react-router-dom';

const highlightMatch = (text, query) => {
  if (!query) return text;

  const regex = new RegExp(`(${query})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <mark key={index} className="bg-[#CCF4D8] ">
        {part}
      </mark>
    ) : (
      part
    ),
  );
};

export const KnowledgeBaseTableList = ({
  knowledgeBaseData,
  isLoadingKnowledgeBaseData,
  filteredValue,
}) => {
  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate(`${id}`);
  };
  return (
    <Table wrapperClassName="border-sides-none">
      <TableHeader>
        <TableRow>
          <TableHead className="text-grey_text">Name</TableHead>
          <TableHead className="flex items-center gap-1 text-grey_text">
            Assigned projects
          </TableHead>
          <TableHead className="text-grey_text"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody className="">
        {!knowledgeBaseData?.length && (
          <TableRow>
            <TableCell colSpan={3}>
              <LoaderWrapper
                loader={<Skeleton className="w-full h-6" />}
                isLoading={isLoadingKnowledgeBaseData}
              >
                <p className="opacity-70 font-semibold text-base text-ebony">
                  {filteredValue
                    ? 'There are no knowledge bases with such a name.'
                    : 'No knowledge base data.'}
                </p>
              </LoaderWrapper>
            </TableCell>
          </TableRow>
        )}
        {!!knowledgeBaseData?.length &&
          knowledgeBaseData.map((item) => (
            <TableRow
              onClick={() => handleRowClick(item.id)}
              key={item.id}
              className="transition hover:bg-[#F4FFF7] cursor-pointer"
            >
              <TableCell className="table-fixed max-w-80">
                <p
                  title={item.name}
                  className="font-medium whitespace-nowrap text-ebony hyphens-auto  text-base truncate"
                >
                  {highlightMatch(item.name, filteredValue)}
                </p>
              </TableCell>
              <TableCell className="table-fixed max-w-80">
                {!!item?.projects.length && (
                  <div className="gap-3.5 flex items-center">
                    <p className="font-medium whitespace-nowrap opacity-50 text-ebony hyphens-auto  text-base truncate">
                      {item?.projects.map(({ name }) => name).join(', ')}
                    </p>
                    {item?.projects.length > 1 && (
                      <div onClick={(e) => e.stopPropagation()}>
                        <AiKnowledgeBaseToolTip
                          contentClassName="px-0 py-5"
                          trigger={
                            <div
                              className={
                                'transition bg-[#C9F4D3] hover:bg-[#9EF7B9] p-1 text-xs font-jamjuree font-bold rounded-sm text-ebony_opacity_50 hover:text-ebony'
                              }
                            >
                              <span className={'select-none'}>+{item?.projects.length}</span>
                            </div>
                          }
                          content={item?.projects.map(({ name }, index) => {
                            return (
                              <div
                                key={`${name}-${index}`}
                                className={'flex flex-col font-medium min-w-56 h-full'}
                              >
                                <span className={'text-base text-ebony px-5'}>{name}</span>
                                {index < item?.projects?.length - 1 && (
                                  <Separator className={'my-[15px]'} />
                                )}
                              </div>
                            );
                          })}
                        />
                      </div>
                    )}
                  </div>
                )}
              </TableCell>
              <TableCell className="max-w-80">
                <div
                  className="flex items-center justify-center gap-7.5"
                  onClick={(e) => e.stopPropagation()}
                >
                  <AiKnowledgeBaseToolTip
                    side={'top'}
                    trigger={
                      <div>
                        <CreateEditAiKnowledgeBaseModal knowledgeBase={item} />
                      </div>
                    }
                    content={<span className={'text-xs opacity-50 text-ebony'}>Rename</span>}
                  />
                  <AiKnowledgeBaseToolTip
                    side={'top'}
                    trigger={
                      <div>
                        <AddProjectsAiKnowledgeBaseModal knowledgeBase={item} />
                      </div>
                    }
                    content={
                      <span className={'text-xs opacity-50 text-ebony'}>Assign project</span>
                    }
                  />
                  <AiKnowledgeBaseToolTip
                    side={'top'}
                    trigger={
                      <div>
                        <DeleteAiKnowledgeBaseModal knowledgeBase={item} />
                      </div>
                    }
                    content={<span className={'text-xs opacity-50 text-ebony'}>Delete</span>}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
