import React, { useState, useEffect } from 'react';
import { clsx } from 'clsx';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useLoader } from 'src/provider/LoaderProvider';
import PageContentWrapper from '../../components/PageContentWrapper/PageContentWrapper';
import CardContainer from '../../components/CardContainer/CardContainer';
import { SearchInput } from 'src/components/SearchInput/SearchInput';
import { ConversationFilter } from 'src/pages/ConversationsPage/ConversationFilter/ConversationFilter';
import { useLazyGetProjectChatsQuery } from 'src/services';
import { ConversationChips } from 'src/pages/ConversationsPage/ConversationChips/ConversationChips';
import { ConversationList } from 'src/pages/ConversationsPage/ConversationList/ConversationList';
import { ConversationChat } from 'src/pages/ConversationsPage/ConversationChat/ConversationChat';
import { ConversationSubject } from 'src/pages/ConversationsPage/ConversationSubject/ConversationSubject';
import { EmptyChatIcon } from 'src/pages/ConversationsPage/ConversationChat/EmptyChatIcon';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useNotification } from 'src/hooks/useNotification';

const Counter = ({ items, children }) => (
  <div
    className={'text-tiny text-ebony font-semibold font-jamjuree py-[3px] px-[7px] bg-[#EEECFD]'}
  >
    {items ? items.length : children}
  </div>
);

const initialRequest = {
  project_id: null,
  service: 'customer',
  created_at_start: '',
  created_at_end: '',
  updated_at_start: '',
  updated_at_end: '',
  page: 1,
  page_size: 20,
  chat_status: ['open', 'escalated', 'closed', 'auto_closed'],
  account: '',
  order_by: ['-created_at'],
};

const compare = (object1, object2, omitProps = []) => {
  for (let property in object2) {
    if (!omitProps.includes(property) && object1.hasOwnProperty(property)) {
      if (JSON.stringify(object1[property]) !== JSON.stringify(object2[property])) {
        return false;
      }
    }
  }
  return true;
};

const ConversationsPage = () => {
  const { id } = useParams();
  const loader = useLoader();
  const notification = useNotification();
  const [request, setRequest] = useState({
    ...initialRequest,
    project_id: id,
  });
  const [selectedChat, setSelectedChat] = useState(null);
  const [chats, setChats] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [fetchChats, { data: chatsData, isLoading, isFetching, isError, error }] =
    useLazyGetProjectChatsQuery();

  useEffect(() => {
    if (isError) {
      notification.error(getErrorMessage(error));
      loader.hide();
    }
  }, [isError]);

  useEffect(() => {
    fetchChats(request);
  }, [request]);

  useEffect(() => {
    if (chatsData) {
      const newPaginatedChats = chatsData.results.map((chat) => {
        return {
          ...chat,
          selected: false,
        };
      });

      setChats([...chats, ...newPaginatedChats]);

      if (
        !chatsData.results.length ||
        !chatsData.pagination.total_pages ||
        chatsData.pagination.current_page === chatsData.pagination.total_pages
      ) {
        setHasNextPage(false);
      }
    }
  }, [chatsData]);

  const onFilterChange = (data) => {
    const isEqual = compare(request, data);
    setChats([]);
    setSelectedChat(null);

    !isEqual &&
      setRequest({
        ...request,
        ...data,
        page: 1,
      });
  };

  const onChipsChange = (data) => {
    setChats([]);
    setSelectedChat(null);

    return setRequest({
      ...request,
      ...data,
    });
  };

  const loadMore = () => {
    setRequest({
      ...request,
      page: request.page + 1,
    });
  };

  return (
    <React.Fragment>
      <PageContentWrapper
        className={clsx('h-[calc(100%-70px)]')}
        isMobileMarginTop={false}
        projectBreadcrumbsContent={'Integrations'}
      >
        <div className="z-10 gap-[10px] flex flex-row relative h-full">
          <CardContainer className="flex-1 min-w-0.5">
            <div className="card-header !min-h-[auto] relative">
              <div className={'flex flex-row gap-[0.625rem] items-center'}>
                <Counter>{chatsData?.pagination?.total_items || 0}</Counter>
                <span className={'text-base-sm text-ebony font-bold'}>Conversations</span>
              </div>
              <div className={'flex flex-row gap-10'}>
                <div className={'w-8.5 h-8.5'}>{/*<SearchInput />*/}</div>
              </div>
              <ConversationFilter request={request} onChange={onFilterChange} />
            </div>
            <ConversationChips request={request} onChange={onChipsChange} />
            <div className="card-body overflow-hidden">
              <ConversationList
                isLoading={isLoading}
                isError={isError}
                loadMore={loadMore}
                chats={chats}
                hasNextPage={hasNextPage}
                onSelect={setSelectedChat}
              />
            </div>
          </CardContainer>
          <CardContainer className="flex-1 min-w-1">
            <div className="card-header !flex-col">
              <div className={'flex flex-row justify-between items-center w-full'}>
                <ConversationSubject chat={selectedChat} />
              </div>
              {selectedChat && (
                <div className={'flex flex-row items-center w-full gap-2.5 mt-2.5'}>
                  <p className={'text-xs font-medium text-ebony_opacity_50'}>
                    <span className={'font-semibold'}>Date Created: </span>
                    <span>{moment(selectedChat?.created_at).format('lll')}</span>
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="4"
                    viewBox="0 0 4 4"
                    fill="none"
                  >
                    <circle cx="2" cy="2" r="2" fill="#05050D" fillOpacity="0.5" />
                  </svg>
                  <p className={'text-xs font-medium text-ebony_opacity_50'}>
                    <span className={'font-semibold'}>Last Updated: </span>
                    <span>{moment(selectedChat?.updated_at).format('lll')}</span>
                  </p>
                </div>
              )}
            </div>
            <div
              className={clsx(
                'card-body max-h-[636px] overflow-hidden',
                !selectedChat && 'flex h-full max-h-full items-center justify-center',
              )}
            >
              {selectedChat ? <ConversationChat chat={selectedChat} /> : <EmptyChatIcon />}
            </div>
          </CardContainer>
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default ConversationsPage;
