import './DashboardPage.css';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, Outlet, useParams } from 'react-router-dom';
import { useLoader } from 'src/provider/LoaderProvider';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { clsx } from 'clsx';
import { Menu } from 'src/components/Menu/Menu';
import { CompanySelectComponent } from 'src/components/CompanySelectComponent/CompanySelectComponent';
import { useTheme } from 'src/provider/ThemeProvider';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { useGetAllProjectsQuery } from 'src/services';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useNotification } from 'src/hooks/useNotification';
import { ChangePasswordModal } from 'src/components/ChangePasswordModal/ChangePasswordModal';
import { ROLES } from 'src/constants/roles';
import { ProductsTypeColor } from 'src/constants/products';
import { useAppSelector } from 'src/store';
import { AssignProjectToKnowledgeBase } from 'src/components/AssignProjectToKnowledgeBase/AssignProjectToKnowledgeBase';

const DashboardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const loader = useLoader();
  const notification = useNotification();
  const { id } = useParams();
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const { config, setTheme } = useTheme();
  const searchParams = new URLSearchParams(location.search);
  const searchProjectType = searchParams.get('type');

  const { projectType } = useAppSelector((s) => s.projectsSlices);

  const projectTypeVar = projectType || searchProjectType;

  useEffect(() => {
    if (searchProjectType) {
      setTheme(ProductsTypeColor[projectTypeVar]);
    }
  }, [searchProjectType, location]);

  const {
    data: userInfo,
    isFetching: isFetchingUserInfo,
    isError: userInfoIsError,
    error: userInfoError,
  } = useGetUsersMeQuery();

  const {
    data: projects = [],
    isSuccess: isSuccessProjects,
    isLoading: isLoadingProjects,
    isError: isErrorProjects,
    error: errorProjects,
  } = useGetAllProjectsQuery();

  const isDashboard = location.pathname === PATH_DASHBOARD.ROOT;

  useEffect(() => {
    if (isLoadingProjects) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingProjects]);

  useEffect(() => {
    if (!isLoadingProjects && !projects.length) {
      isDashboard && navigate(PATH_DASHBOARD.PROJECTS_NEW);
    }
    if (isSuccessProjects && projects.length) {
      isDashboard &&
        navigate(`${PATH_DASHBOARD.PROJECTS}/${projects[0].id}?type=${projects[0].project_type}`);
    }
  }, [projects, isSuccessProjects, isLoadingProjects]);

  useEffect(() => {
    if (isErrorProjects) {
      notification.error(getErrorMessage(errorProjects));
    }
    if (userInfoIsError) {
      notification.error(getErrorMessage(userInfoError));
    }
  }, [isErrorProjects, userInfoIsError]);

  const isProjectLocation = location.pathname.includes('projects');
  const isAccountLocation = location.pathname.includes('account');
  const isKnowledgeBaseLocation = location.pathname.includes('ai-knowledge-base');
  const isWorkspaceAllowedByRole = [ROLES.COSSUPORT_MANAGER, ROLES.ADMIN, ROLES.MANAGER].includes(
    userInfo?.role,
  );

  return (
    <React.Fragment>
      <div className={`dashboard-page-container ${config.className}`}>
        <Menu />
        <div className="dashboard-container max-h-screen overflow-auto">
          <div className="dashboard-header w-full min-h-[70px] mx-auto relative border-b border-[#E3E6E8]">
            <div className="absolute right-5 top-5">
              <div
                className={clsx(
                  'flex flex-col gap-[14px] relative z-40',
                  isProjectLocation && 'gap-8',
                )}
              >
                <div
                  className={clsx(
                    'breadcrumbs-container flex gap-2.5 h-9 items-center justify-between',
                  )}
                >
                  <div className="flex gap-2.5">
                    {isAccountLocation && (
                      <div className="breadcrumbs">
                        <ChangePasswordModal />
                      </div>
                    )}
                    {isKnowledgeBaseLocation && id && <AssignProjectToKnowledgeBase />}
                    {isWorkspaceAllowedByRole && Boolean(userInfo?.managed_accounts?.length) && (
                      <div className="breadcrumbs">
                        <CompanySelectComponent />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;
