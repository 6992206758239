import React, { useEffect } from 'react';
import { clsx } from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { useGetAllProjectsQuery } from 'src/services';
import './Menu.css';
import { menuItemsProducts } from 'src/components/Menu/menuItems';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';
import { AddIcon } from 'src/assets/img/add-icon';
import { useAppDispatch, useAppSelector } from 'src/store';
import { LATEST_VIEWED_PROJECT_ID } from 'src/shared/constants';
import { setProducts, setProjectId } from 'src/store/slices/projectsSlices';
import { Drawer } from 'vaul';
import { ProductCreateModal } from 'src/components/Menu/ProductCreateModal';

export const ProductsItems = ({ isMobile, className }) => {
  const { data: projects = [], isError, isFetching } = useGetAllProjectsQuery();
  const { id } = useParams();
  const { storageMenuOpen } = useAppSelector((s) => s.menuSlices);

  const dispatch = useAppDispatch();

  const changeProject = (projectId, projectType) => {
    localStorage.setItem(LATEST_VIEWED_PROJECT_ID, projectId);
    dispatch(setProjectId({ projectId, projectType }));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const addProjectHandler = (projectType) => {
    dispatch(setProjectId({ projectType }));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const projectsByType = projects?.reduce(
    (acc, project) => {
      if (!acc[project?.project_type]) {
        acc[project.project_type] = [];
      }
      acc[project?.project_type].push(project);
      return acc;
    },
    { agent: [], bi: [], customer: [] },
  );

  const { projectType, products } = useAppSelector((s) => s.projectsSlices);

  const menuItemsProductsFilterByProjects = menuItemsProducts.filter(
    (item) => projectsByType[item.type].length,
  );

  const isAddProductButtonVisible =
    menuItemsProductsFilterByProjects.length !== menuItemsProducts.length;

  useEffect(() => {
    if (!isError && !isFetching) {
      dispatch(setProducts({ products: menuItemsProductsFilterByProjects }));
    }
  }, [projects]);

  return (
    <div className={clsx('px-5 py-10 min-w-[90px]', className)}>
      <div className="flex flex-col gap-2.5">
        {products?.map((menuItem, index) => {
          const isActiveTab = projectsByType[menuItem.type].find((el) => {
            return el.id === +id;
          });
          return (
            <Accordion
              disabled={menuItem.disabled}
              key={`${menuItem.id}-${index}`}
              type="single"
              collapsible
              className="w-full"
              defaultValue={
                (isActiveTab && menuItem.title) || (projectType === menuItem.type && menuItem.title)
              }
            >
              <AccordionItem value={menuItem.title} className="border-b-0">
                <AccordionTrigger
                  className={clsx(
                    Boolean(isActiveTab) ? '[&_p]:opacity-100' : '[&_p]:opacity-60',
                    !menuItem.disabled
                      ? '[&_p]:hover:opacity-100 [&_svg:last-child]:hover:opacity-100'
                      : 'opacity-40',
                    'px-2 py-2.5 rounded duration-300 [&_p]:transition-all [&_svg]:transition-all [&[data-state=open]_p]:opacity-100 [&[data-state=open]_svg]:opacity-100 [&_svg:last-child]:opacity-50',
                    !storageMenuOpen && '[&_.chevron]:!opacity-0',
                  )}
                >
                  <div className="flex items-center gap-2.5">
                    {menuItem.icon}
                    <p
                      className={clsx(
                        !storageMenuOpen && '!opacity-0',
                        'transition-all absolute left-[70px] duration-300 text-white text-[0.938rem] font-medium text-ellipsis overflow-hidden text-nowrap',
                      )}
                    >
                      {menuItem.title}
                    </p>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="mt-2 flex flex-col gap-1.5 relative">
                    {projectsByType[menuItem.type] &&
                      projectsByType[menuItem.type]?.map((el) => {
                        const activeSlide = +id === el.id;
                        const cutName =
                          el.name.trim().split(/\s+/).length > 1
                            ? el.name
                                .trim()
                                .split(/\s+/)
                                .map((word) => word[0])
                                .slice(0, 2)
                                .join('')
                            : el.name.trim().slice(0, 2);
                        return (
                          <Link
                            onClick={() => changeProject(el.id, el?.project_type)}
                            key={el.id}
                            className={clsx(
                              storageMenuOpen && 'pl-11 pr-2.5',
                              activeSlide && 'bg-woodsmoke',
                              'p-2.5 rounded-[5px] overflow-hidden transition-all duration-300 [&_span]:hover:opacity-100 [&_span]:transition-all relative',
                            )}
                            to={`${PATH_DASHBOARD.PROJECTS}/${el.id}?type=${el.project_type}`}
                          >
                            <div
                              className={clsx(
                                activeSlide ? '[&_span]:opacity-100' : '[&_span]:opacity-60',
                                ' transition-all duration-300',
                              )}
                            >
                              <button
                                className={clsx(
                                  !storageMenuOpen && 'bg-white bg-opacity-10',
                                  storageMenuOpen && 'truncate',
                                  'p-1.5 text-left max-w-full rounded min-w-[30px]',
                                )}
                                title={el.name}
                              >
                                <span
                                  className={clsx(
                                    activeSlide ? menuItem.color : 'text-white',
                                    activeSlide && 'font-bold',
                                    'font-medium text-[0.938rem]',
                                  )}
                                >
                                  {storageMenuOpen ? el.name : cutName}
                                </span>
                              </button>
                            </div>
                          </Link>
                        );
                      })}
                    <Link
                      onClick={() => addProjectHandler(menuItem.type)}
                      to={`${PATH_DASHBOARD.PROJECTS_NEW}?type=${menuItem.type}`}
                      className={clsx(
                        storageMenuOpen && 'pl-11 pr-2.5',
                        'p-2.5 w-full opacity-60 hover:opacity-100 transition-all duration-300',
                      )}
                    >
                      <button
                        className={clsx(
                          !storageMenuOpen && 'bg-white bg-opacity-10',
                          'flex min-w-[30px] min-h-[30px] items-center justify-center p-1.5 gap-2 rounded transition-all duration-300',
                        )}
                      >
                        <AddIcon strokeColor={'#FFFFFF'} />
                        <span
                          className={clsx(
                            !storageMenuOpen && '!opacity-0',
                            'transition-all absolute left-[75px] duration-300 text-white text-[0.938rem] font-medium',
                          )}
                        >
                          Add Project
                        </span>
                      </button>
                    </Link>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          );
        })}
        {isAddProductButtonVisible && <ProductCreateModal projectsByType={projectsByType} />}
      </div>
    </div>
  );
};
