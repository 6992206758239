import { clsx } from 'clsx';
import { Button } from 'src/components/ui/button';
import React from 'react';

export const RefreshPlaygroundButton = ({ onClick = () => {}, className = '' }) => {
  return (
    <Button
      variant="outline"
      size="sm"
      className={clsx('shadow-search gap-2 p-2.5', className)}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
      >
        <path
          opacity="0.8"
          d="M7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7H15L12.36 9.68667L9.66667 7H11.6667C11.6667 5.76232 11.175 4.57534 10.2998 3.70017C9.42466 2.825 8.23768 2.33333 7 2.33333C5.76232 2.33333 4.57534 2.825 3.70017 3.70017C2.825 4.57534 2.33333 5.76232 2.33333 7C2.33333 8.23768 2.825 9.42466 3.70017 10.2998C4.57534 11.175 5.76232 11.6667 7 11.6667C8.28667 11.6667 9.45333 11.14 10.2933 10.2933L11.24 11.24C10.6856 11.8003 10.0251 12.2445 9.29712 12.5467C8.56912 12.8489 7.78821 13.003 7 13C5.4087 13 3.88258 12.3679 2.75736 11.2426C1.63214 10.1174 1 8.5913 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1Z"
          fill="#05050D"
          stroke="black"
          strokeWidth="0.5"
        />
      </svg>
    </Button>
  );
};
