import React from 'react';
import Button from 'src/components/Button/Button';
import { Dialog, DialogContent, DialogTitle } from 'src/components/ui/dialog';

const SuccessContent = ({ onPopupClose }) => {
  return (
    <div className="grid gap-5 text-base text-center">
      <div>
        <p className={'font-semibold text-ebony'}>
          You email address has been verified successfully.
        </p>
        <p className={'text-ebony_opacity_70'}>You can access CoSupport AI Hub now.</p>
      </div>
      <div className="w-[130px] m-auto">
        <Button className="green !mt-0" onClick={onPopupClose}>
          Ok
        </Button>
      </div>
    </div>
  );
};

const ErrorContent = ({ onPopupClose }) => {
  return (
    <div className="grid gap-5 text-base text-center">
      <div>
        <p className={'text-error'}>Sorry, we’re facing a verification issue.</p>
        <p className={'font-semibold text-error'}>Please try again later.</p>
      </div>
      <div className="w-[130px] m-auto">
        <Button className="google !mt-0" onClick={onPopupClose}>
          Ok
        </Button>
      </div>
    </div>
  );
};

export const EmailVerificationPopup = ({
  open,
  setOpen,
  onClose = () => {},
  isSuccess,
  isError,
}) => {
  const onPopupClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent closeButtonHidden>
        <DialogTitle className={'hidden'}></DialogTitle>
        {isSuccess && <SuccessContent onPopupClose={onPopupClose} />}
        {isError && <ErrorContent onPopupClose={onPopupClose} />}
      </DialogContent>
    </Dialog>
  );
};
